import React, { useState } from "react";
import "@carbon/react";
import {
  Select,
  SelectItem,
  Accordion,
  AccordionItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Button,
  SelectableTile,
  TextInput,
  Layer,
  ExpandableTile,
  TileAboveTheFoldContent,
  TileBelowTheFoldContent,
  Checkbox,
} from "@carbon/react";
/* eslint-disable import/no-internal-modules */
import {
  TextBold,
  TextItalic,
  TextUnderline,
  TextAlignLeft,
  TextAlignCenter,
  TextAlignRight,
  BorderFull,
  BorderBottom,
  BorderTop,
  BorderLeft,
  BorderRight,
  CrossTab,
  ChartStacked,
} from "@carbon/react/icons";
/* eslint-enable import/no-internal-modules */
import { GetAvailableAnalysesOutput } from "../../../../../../../core/model/api/analysis";
import { ScrollWrapper } from "@/components";

interface TableEditorProps {
  qualifierLabel: string;
  onQualifierLabelChange: (label: any) => void;
  // Global grid styles
  gridGlobalBackgroundColor: string;
  onGridGlobalBackgroundColorChange: (color: string) => void;
  globalFontFamily: string;
  onGlobalFontFamilyChange: (fontFamily: string) => void;
  globalFontSize: string;
  onGlobalFontSizeChange: (fontSize: string) => void;
  globalTextStyle: string;
  onGlobalTextStyleChange: (textStyle: string) => void;

  globalBorder: string;
  onGlobalBorderChange: (border: string) => void;
  globalBorderColor: string;
  onGlobalBorderColorChange: (color: string) => void;
  oddRowBackgroundColor: string;
  onOddRowBackgroundColorChange: (color: string) => void;

  // Column label
  columnLabelTextDecoration: any;
  onColumnLabelTextDecorationChange: (textDecoration: string) => void;
  headerColumnSeparatorDisplay: string;
  onHeaderColumnSeparatorDisplayChange: (display: string) => void;
  columnLabelFontSize: any;

  columnLabelColor: string;
  onColumnLabelColorChange: (color: string) => void;
  columnLabelTextStyle: string;
  onColumnLabelTextStyleChange: (textStyle: string) => void;
  columnLabelTextAlignment: string;
  onColumnLabelTextAlignmentChange: (textAlignment: string) => void;
  columnLabelBackgroundColor: string;
  onColumnLabelBackgroundColorChange: (color: string) => void;

  //Table Header
  tableHeader: string;
  onTableHeaderChange: (header: string) => void;
  headerTextColor: string;
  onHeaderTextColorChange: (color: string) => void;
  borderRadius: string;
  onBorderRadiusChange: (borderRadius: string) => void;
  backgroundColor: string;
  onBackgroundColorChange: (color: string) => void;
  dataColor: string;
  onDataColorChange: (color: string) => void;
  rowHoverColor: string;
  onRowHoverColorChange: (color: string) => void;
  columnHoverColor: string;
  onColumnHoverColorChange: (color: string) => void;
  rangeSelectionBorderColor: string;
  onRangeSelectionBorderColorChange: (color: string) => void;
  rangeSelectionBorderStyle: string;
  onRangeSelectionBorderStyleChange: (style: string) => void;
  rangeSelectionBackgroundColor: string;
  onRangeSelectionBackgroundColorChange: (color: string) => void;

  bordersSecondary: string;
  onBordersSecondaryChange: (borders: string) => void;
  secondaryBorderColor: string;
  onSecondaryBorderColorChange: (color: string) => void;

  cellHorizontalBorder: string;
  onCellHorizontalBorderChange: (border: string) => void;
  gridSize: string;
  onGridSizeChange: (size: string) => void;
  rowHeight: any;
  onRowHeightChange: (height: string) => void;
  headerHeight: any;
  onHeaderHeightChange: (height: any) => void;

  fontFamily: string;
  onFontFamilyChange: (fontFamily: string) => void;
  fontSize: string;
  onFontSizeChange: (fontSize: string) => void;
  headerFontFamily: string;
  onHeaderFontFamilyChange: (fontFamily: string) => void;
  headerFontSize: string;
  onHeaderFontSizeChange: (fontSize: string) => void;
  headerTextStyle: string;
  onHeaderTextStyleChange: (textStyle: string) => void;
  headerTextAlignment: string;
  headerBorder: string;
  onHeaderBorderChange: (border: string) => void;
  headerBorderColor: string;
  onHeaderBorderColorChange: (color: string) => void;
  onHeaderTextAlignmentChange: (textAlignment: string) => void;
  headerBackgroundColor: string;
  onHeaderBackgroundColorChange: (color: string) => void;
  onTableToggle: (
    category: string,
    subCategory: string,
    qualifier: string,
    subQualifiers: string[],
  ) => void;
  onChartToggle: (
    category: string,
    subCategory: string,
    qualifier: string,
    subQualifiers: string[],
  ) => void;
  analyses: GetAvailableAnalysesOutput;

  cellFormat: string;
  onCellFormatChange: (format: string) => void;
  cellFormatTextStyle: string;
  onCellFormatTextStyleChange: (textStyle: string) => void;
  cellFormatTextAlignment: string;
  onCellFormatTextAlignmentChange: (textAlignment: string) => void;
  cellFormatBorder: string;
  onCellFormatBorderChange: (border: string) => void;
  cellFormatBorderColor: string;
  onCellFormatBorderColorChange: (color: string) => void;
  cellFormatBackgroundColor: string;
  onCellFormatBackgroundColorChange: (color: string) => void;

  cellFormatTextColor: string;
  onCellFormatTextColorChange: (color: string) => void;

  //Total & Sum Rows
  totalRowTextStyle: string;
  onTotalRowTextStyleChange: (textStyle: string) => void;
  totalRowTextAlignment: string;
  onTotalRowTextAlignmentChange: (textAlignment: string) => void;
  totalRowBackgroundColor: string;
  onTotalRowBackgroundColorChange: (color: string) => void;
  totalRowFontFamily: string;
  onTotalRowFontFamilyChange: (fontFamily: string) => void;
  totalRowFontSize: string;
  onTotalRowFontSizeChange: (fontSize: string) => void;
  totalRowColor: string;
  onTotalRowColorChange: (color: string) => void;
  totalRowTextDecoration: string;
  onTotalRowTextDecorationChange: (textDecoration: string) => void;
  subTotalRowTextStyle: string;
  onSubTotalRowTextStyleChange: (textStyle: string) => void;
  subTotalRowTextAlignment: string;
  onSubTotalRowTextAlignmentChange: (textAlignment: string) => void;
  subTotalRowBorder: string;
  onSubTotalRowBorderChange: (border: string) => void;
  subTotalRowBorderTop: string;
  onSubTotalRowBorderTopChange: (border: string) => void;
  subTotalRowBorderBottom: string;
  onSubTotalRowBorderBottomChange: (border: string) => void;
  subTotalRowBorderColor: string;
  onSubTotalRowBorderColorChange: (color: string) => void;
  subTotalRowBackgroundColor: string;
  onSubTotalRowBackgroundColorChange: (color: string) => void;
  subTotalRowFontSize: any;
  onSubTotalRowFontSizeChange: (fontSize: string) => void;
  subTotalRowColor: string;
  onSubTotalRowColorChange: (color: string) => void;
  subTotalRowTextDecoration: string;
  onSubTotalRowTextDecorationChange: (textDecoration: string) => void;

  onColumnLabelFontSizeChange: (fontSize: string) => void;
}

export const TableEditor: React.FC<TableEditorProps> = ({
  analyses,

  //Global grid styles
  gridGlobalBackgroundColor,
  onGridGlobalBackgroundColorChange,
  globalFontFamily,
  onGlobalFontFamilyChange,
  globalFontSize,
  onGlobalFontSizeChange,
  globalTextStyle,
  onGlobalTextStyleChange,

  globalBorder,
  onGlobalBorderChange,
  globalBorderColor,
  onGlobalBorderColorChange,
  oddRowBackgroundColor,
  onOddRowBackgroundColorChange,

  //Column labels

  columnLabelTextDecoration,
  onColumnLabelTextDecorationChange,
  columnLabelTextStyle,
  onColumnLabelTextStyleChange,
  columnLabelTextAlignment,
  onColumnLabelTextAlignmentChange,
  columnLabelColor,
  onColumnLabelColorChange,
  columnLabelBackgroundColor,
  onColumnLabelBackgroundColorChange,

  //header
  tableHeader,
  onTableHeaderChange,
  headerTextColor,
  onHeaderTextColorChange,
  headerFontSize,
  onHeaderFontSizeChange,
  headerTextStyle,
  onHeaderTextStyleChange,
  headerTextAlignment,
  onHeaderTextAlignmentChange,
  headerBorder,
  onHeaderBorderChange,
  headerBorderColor,
  onHeaderBorderColorChange,
  headerBackgroundColor,
  onHeaderBackgroundColorChange,

  cellFormatTextStyle,
  onCellFormatTextStyleChange,
  cellFormatTextAlignment,
  onCellFormatTextAlignmentChange,
  cellFormatBorder,
  onCellFormatBorderChange,
  cellFormatBorderColor,
  onCellFormatBorderColorChange,
  cellFormatBackgroundColor,
  onCellFormatBackgroundColorChange,

  cellFormatTextColor,
  onCellFormatTextColorChange,

  //Total & Sum Rows
  totalRowTextStyle,
  onTotalRowTextStyleChange,
  totalRowTextAlignment,
  onTotalRowTextAlignmentChange,
  totalRowBackgroundColor,
  onTotalRowBackgroundColorChange,

  totalRowFontSize,
  onTotalRowFontSizeChange,
  totalRowColor,
  onTotalRowColorChange,
  subTotalRowTextStyle,
  onSubTotalRowTextStyleChange,
  subTotalRowTextAlignment,
  onSubTotalRowTextAlignmentChange,
  subTotalRowBorder,
  onSubTotalRowBorderChange,
  subTotalRowBorderColor,
  subTotalRowBorderTop,
  onSubTotalRowBorderTopChange,
  subTotalRowBorderBottom,
  onSubTotalRowBorderBottomChange,
  onSubTotalRowBorderColorChange,
  subTotalRowBackgroundColor,
  onSubTotalRowBackgroundColorChange,

  subTotalRowFontSize,
  onSubTotalRowFontSizeChange,
  subTotalRowColor,
  onSubTotalRowColorChange,
  subTotalRowTextDecoration,
  onSubTotalRowTextDecorationChange,

  onTableToggle,
  onChartToggle,
  headerFontFamily,
  onHeaderFontFamilyChange,
  rowHeight,
  onRowHeightChange,
  headerHeight,
  onHeaderHeightChange,
  qualifierLabel,
  onQualifierLabelChange,
}) => {
  const [selectedFormat, setSelectedFormat] = useState("Default");

  const handleSelectedFormatChange = (format: string) => {
    setSelectedFormat(format);
  };

  const [showPicker, setShowPicker] = useState(false);
  const [openSection, setOpenSection] = useState<string>("Global Table Style");

  const handleOpenSection = (sectionName: string) => {
    if (openSection !== sectionName) {
      setOpenSection(sectionName);
    }
  };

  const [fetchingAnalysis, setFetchingAnalysis] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [category, setCategory] = useState<string | undefined>();
  const [subCategory, setSubCategory] = useState<string | undefined>();

  const [qualifier, setQualifier] = useState<string | undefined>();
  // eslint-disable-next-line no-unused-vars
  const [subQualifiers, setSubQualifiers] = useState<string[]>([]);
  const [element, setElement] = useState<"chart" | "table">("table");

  const handleAddingElement = (element: "chart" | "table") => {
    setElement(element);
    setFetchingAnalysis(true);
  };

  return (
    <div className="flex flex-col flex-1 shadow-sm">
      <div className="w-full pt-5 pb-1 pl-3 pr-3 bg-white border-b border-[#8d8d8d]">
        {fetchingAnalysis ? (
          <>
            <div className="mt-1">
              <div
                className="text-sm font-semibold flex items-center pb-5"
                style={{ borderBottom: "1px solid #e8e8e8" }}
              >
                Analysis Selection
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="flex justify-between items-center pb-5"
              style={{ borderBottom: "1px solid #e8e8e8" }}
            >
              <div className="text-sm font-semibold">Add</div>

              <div className="flex justify-between gap-2 pr-1">
                <Button
                  kind="ghost"
                  renderIcon={CrossTab}
                  hasIconOnly
                  size="sm"
                  style={{ border: "1px solid #6B7280", borderRadius: "2px" }}
                  onClick={() => {
                    handleAddingElement("table");
                  }}
                ></Button>
                <Button
                  kind="ghost"
                  renderIcon={ChartStacked}
                  hasIconOnly
                  size="sm"
                  style={{ border: "1px solid ", borderRadius: "2px" }}
                  onClick={() => {
                    handleAddingElement("chart");
                  }}
                ></Button>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="flex flex-col flex-1 w-full border bg-Grey0 text-Black100 shadow-sm overflow-hidden">
        {!fetchingAnalysis && (
          <Accordion isFlush={true} className="flex flex-col overflow-hidden">
            <AccordionItem
              title={<span className="text-xs">Global Table Style</span>}
              open={openSection === "Global Table Style"}
              onClick={() => handleOpenSection("Global Table Style")}
              className="flex flex-col"
            >
              <div className="mb-3 flex justify-between items-center">
                <div className="text-xs font-normal">Background</div>
                <div className="flex items-center justify-between">
                  <div
                    className="h-5 w-5 rounded-full overflow-hidden cursor-pointer relative"
                    style={{
                      backgroundColor: gridGlobalBackgroundColor,
                      border: "1px solid #dde1e6",
                    }}
                    onClick={(e) => {
                      const input = e.currentTarget.firstElementChild;
                      if (input instanceof HTMLElement) {
                        if (showPicker) {
                          setShowPicker(false);
                        } else {
                          input.click();
                          setShowPicker(true);
                        }
                      }
                    }}
                  >
                    <input
                      style={{
                        opacity: 0,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                        transform: "scale(0.5)",
                        transformOrigin: "top left",
                      }}
                      type="color"
                      id="globalBackgroundColor"
                      value={gridGlobalBackgroundColor}
                      onChange={(e) =>
                        onGridGlobalBackgroundColorChange(e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="pr-0 mb-3 w-full">
                <div className="w-full border border-solid border-[#dde1e6cc] rounded-[2px]">
                  <Select
                    className="custom-select border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-5"
                    id="header-font"
                    inline
                    size="sm"
                    value={globalFontFamily}
                    onChange={(e) => onGlobalFontFamilyChange(e.target.value)}
                    noLabel
                  >
                    <SelectItem value="Arial" text="Arial" />
                    <SelectItem value="Garamond" text="Garamond" />
                    <SelectItem value="Arial Black" text="Arial Black" />
                    <SelectItem value="Verdana" text="Verdana" />
                    <SelectItem value="Tahoma" text="Tahoma" />
                    <SelectItem value="Trebuchet MS" text="Trebuchet MS" />
                    <SelectItem value="Impact" text="Impact" />
                    <SelectItem
                      value="Times New Roman"
                      text="Times New Roman"
                    />
                    <SelectItem value="Didot" text="Didot" />
                    <SelectItem value="Georgia" text="Georgia" />
                    <SelectItem
                      value="American Typewriter"
                      text="American Typewriter"
                    />
                    <SelectItem value="Andalé Mono" text="Andalé Mono" />
                    <SelectItem value="Courier" text="Courier" />
                    <SelectItem value="Lucida Console" text="Lucida Console" />
                    <SelectItem value="Monaco" text="Monaco" />
                    <SelectItem value="Bradley Hand" text="Bradley Hand" />
                    <SelectItem
                      value="Brush Script MT"
                      text="Brush Script MT"
                    />
                    <SelectItem value="Luminari" text="Luminari" />
                    <SelectItem value="Comic Sans MS" text="Comic Sans MS" />
                  </Select>
                </div>
              </div>
              <div className="mb-3 w-full">
                <div className="flex items-center justify-between">
                  <div className="w-25  border border-solid border-[#dde1e6cc] rounded-[2px]">
                    <Select
                      className="w-full border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-5"
                      id="headerFontSize"
                      value={globalFontSize}
                      onChange={(e) => onGlobalFontSizeChange(e.target.value)}
                      noLabel
                    >
                      <SelectItem value="8px" text="8" />
                      <SelectItem value="9px" text="9" />
                      <SelectItem value="10px" text="10" />
                      <SelectItem value="11px" text="11" />
                      <SelectItem value="12px" text="12" />
                      <SelectItem value="14px" text="14" />
                      <SelectItem value="16px" text="16" />
                      <SelectItem value="18px" text="18" />
                      <SelectItem value="20px" text="20" />
                      <SelectItem value="22px" text="22" />
                      <SelectItem value="24px" text="24" />
                      <SelectItem value="26px" text="26" />
                      <SelectItem value="28px" text="28" />
                      <SelectItem value="36px" text="36" />
                      <SelectItem value="48px" text="48" />
                      <SelectItem value="72px" text="72" />
                    </Select>
                  </div>
                  <div className="flex items-center border rounded h-5">
                    <button
                      className={`px-2 h-full ${globalTextStyle === "bold" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() =>
                        onGlobalTextStyleChange(
                          globalTextStyle === "bold" ? "regular" : "bold",
                        )
                      }
                    >
                      <TextBold size={20} />
                    </button>
                    <button
                      className={`px-2 h-full ${globalTextStyle === "italic" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() =>
                        onGlobalTextStyleChange(
                          globalTextStyle === "italic" ? "regular" : "italic",
                        )
                      }
                    >
                      <TextItalic size={20} />
                    </button>
                    <button
                      className={`px-2 h-full ${globalTextStyle === "underline" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() =>
                        onGlobalTextStyleChange(
                          globalTextStyle === "underline"
                            ? "regular"
                            : "underline",
                        )
                      }
                    >
                      <TextUnderline size={20} />
                    </button>
                  </div>
                </div>
              </div>

              <div className="mb-3 flex justify-between items-center">
                <div className="text-xs font-normal">Border</div>
                <div className="flex items-center border rounded h-5">
                  <BorderFull
                    size={20}
                    className={`ml-2 mr-4 ${globalBorder === "solid 1px" ? "text-blue-500" : "text-gray-500"}`}
                    onClick={() =>
                      onGlobalBorderChange(
                        globalBorder === "solid 1px" ? "none" : "solid 1px",
                      )
                    }
                  />
                  <div
                    className="h-5 w-5 rounded-full overflow-hidden border appearance-none cursor-pointer relative"
                    style={{
                      backgroundColor: globalBorderColor,
                      border: "1px solid #dde1e6",
                    }}
                    onClick={(e) => {
                      const input = e.currentTarget.firstElementChild;
                      if (input instanceof HTMLElement) {
                        if (showPicker) {
                          setShowPicker(false);
                        } else {
                          input.click();
                          setShowPicker(true);
                        }
                      }
                    }}
                  >
                    <input
                      style={{
                        opacity: 0,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                        transform: "scale(0.5)",
                        transformOrigin: "top left",
                      }}
                      type="color"
                      id="globalBorderColor"
                      value={globalBorderColor}
                      onChange={(e) =>
                        onGlobalBorderColorChange(e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3 flex justify-between items-center">
                <div className="text-xs font-normal">Odd Row Shading</div>
                <div
                  className="h-5 w-5 rounded-full overflow-hidden cursor-pointer relative"
                  style={{
                    backgroundColor: oddRowBackgroundColor,
                    border: "1px solid #dde1e6",
                  }}
                  onClick={(e) => {
                    const input = e.currentTarget.firstElementChild;
                    if (input instanceof HTMLElement) {
                      if (showPicker) {
                        setShowPicker(false);
                      } else {
                        input.click();
                        setShowPicker(true);
                      }
                    }
                  }}
                >
                  <input
                    style={{
                      opacity: 0,
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                      transform: "scale(0.5)",
                      transformOrigin: "top left",
                    }}
                    type="color"
                    id="oddRowBackgroundColor"
                    value={oddRowBackgroundColor}
                    onChange={(e) =>
                      onOddRowBackgroundColorChange(e.target.value)
                    }
                  />
                </div>
              </div>
            </AccordionItem>
            <AccordionItem
              title={<span className="text-xs">Header</span>}
              open={openSection === "Header"}
              onClick={() => handleOpenSection("Header")}
            >
              <div className="flex-grow overflow-auto">
                <div className="mb-3 flex justify-between items-center w-full">
                  <Layer className="w-full">
                    <TextInput
                      type="text"
                      className="full-width-input"
                      size="sm"
                      labelText=""
                      id="text-input-1"
                      value={tableHeader}
                      onChange={(e) => onTableHeaderChange(e.target.value)}
                    />
                  </Layer>
                </div>

                <div className="mb-3 flex justify-between items-center">
                  <div className="text-xs font-normal">Background</div>
                  <div className="flex items-center justify-between">
                    <div
                      className="h-5 w-5 rounded-full overflow-hidden cursor-pointer relative"
                      style={{
                        backgroundColor: headerBackgroundColor,
                        border: "1px solid #dde1e6",
                      }}
                      onClick={(e) => {
                        const input = e.currentTarget.firstElementChild;
                        if (input instanceof HTMLElement) {
                          if (showPicker) {
                            setShowPicker(false);
                          } else {
                            input.click();
                            setShowPicker(true);
                          }
                        }
                      }}
                    >
                      <input
                        style={{
                          opacity: 0,
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          transform: "scale(0.5)",
                          transformOrigin: "top left",
                        }}
                        type="color"
                        id="headerBackgroundColor"
                        value={headerBackgroundColor}
                        onChange={(e) =>
                          onHeaderBackgroundColorChange(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3 w-full">
                  <div className="w-full border border-solid border-[#dde1e6cc] rounded-[2px]">
                    <Select
                      className="custom-select border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-5"
                      id="header-font"
                      value={headerFontFamily}
                      onChange={(e) => onHeaderFontFamilyChange(e.target.value)}
                      noLabel
                    >
                      <SelectItem value="IBM Plex Sans" text="IBM Plex Sans" />
                      {/* Add more font options */}
                    </Select>
                  </div>
                </div>
                <div className="mb-3 w-full">
                  <div className="flex items-center justify-between">
                    <div
                      className="h-5 w-5 rounded-full overflow-hidden cursor-pointer relative"
                      style={{
                        backgroundColor: headerTextColor,
                        border: "1px solid #dde1e6",
                      }}
                      onClick={(e) => {
                        const input = e.currentTarget.firstElementChild;
                        if (input instanceof HTMLElement) {
                          if (showPicker) {
                            setShowPicker(false);
                          } else {
                            input.click();
                            setShowPicker(true);
                          }
                        }
                      }}
                    >
                      <input
                        style={{
                          opacity: 0,
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          transform: "scale(0.5)",
                          transformOrigin: "top left",
                        }}
                        type="color"
                        id="headerTextColor"
                        value={headerTextColor}
                        onChange={(e) =>
                          onHeaderTextColorChange(e.target.value)
                        }
                      />
                    </div>
                    <div className=" w-25 border border-solid border-[#dde1e6cc] rounded-[2px]">
                      <Select
                        className="w-full border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-5"
                        id="headerFontSize"
                        value={headerFontSize}
                        onChange={(e) => onHeaderFontSizeChange(e.target.value)}
                        noLabel
                      >
                        <SelectItem value="8px" text="8" />
                        <SelectItem value="9px" text="9" />
                        <SelectItem value="10px" text="10" />
                        <SelectItem value="11px" text="11" />
                        <SelectItem value="12px" text="12" />
                        <SelectItem value="14px" text="14" />
                        <SelectItem value="16px" text="16" />
                        <SelectItem value="18px" text="18" />
                        <SelectItem value="20px" text="20" />
                        <SelectItem value="22px" text="22" />
                        <SelectItem value="24px" text="24" />
                        <SelectItem value="26px" text="26" />
                        <SelectItem value="28px" text="28" />
                        <SelectItem value="36px" text="36" />
                        <SelectItem value="48px" text="48" />
                        <SelectItem value="72px" text="72" />
                      </Select>
                    </div>
                    <div className="flex items-center border rounded h-5">
                      <button
                        className={`px-2 h-full ${headerTextStyle === "bold" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                        onClick={() =>
                          onHeaderTextStyleChange(
                            headerTextStyle === "bold" ? "regular" : "bold",
                          )
                        }
                      >
                        <TextBold size={20} />
                      </button>
                      <button
                        className={`px-2 h-full ${headerTextStyle === "italic" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                        onClick={() =>
                          onHeaderTextStyleChange(
                            headerTextStyle === "italic" ? "regular" : "italic",
                          )
                        }
                      >
                        <TextItalic size={20} />
                      </button>
                      <button
                        className={`px-2 h-full ${headerTextStyle === "underline" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                        onClick={() =>
                          onHeaderTextStyleChange(
                            headerTextStyle === "underline"
                              ? "regular"
                              : "underline",
                          )
                        }
                      >
                        <TextUnderline size={20} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mb-3 flex justify-between items-center">
                  <div className="text-xs font-normal">Alignment</div>
                  <div className="flex items-center border rounded h-5">
                    <TextAlignLeft
                      size={20}
                      className={`ml-2 mr-4 ${headerTextAlignment === "left" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onHeaderTextAlignmentChange("left")}
                    />
                    <TextAlignCenter
                      size={20}
                      className={`mr-4 ${headerTextAlignment === "center" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onHeaderTextAlignmentChange("center")}
                    />
                    <TextAlignRight
                      size={20}
                      className={`mr-2 ${headerTextAlignment === "right" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onHeaderTextAlignmentChange("right")}
                    />
                  </div>
                </div>

                <div className="mb-3 flex justify-between items-center">
                  <div className="text-xs font-normal">Border</div>
                  <div className="flex items-center border rounded h-5">
                    <BorderFull
                      size={20}
                      className={`ml-2 mr-4 ${headerBorder === "full" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onHeaderBorderChange("full")}
                    />
                    <BorderBottom
                      size={20}
                      className={`mr-4 ${headerBorder === "bottom" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onHeaderBorderChange("bottom")}
                    />
                    <BorderTop
                      size={20}
                      className={`mr-4 ${headerBorder === "top" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onHeaderBorderChange("top")}
                    />
                    <BorderLeft
                      size={20}
                      className={`mr-4 ${headerBorder === "left" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onHeaderBorderChange("left")}
                    />
                    <BorderRight
                      size={20}
                      className={`mr-2 ${headerBorder === "right" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onHeaderBorderChange("right")}
                    />
                  </div>
                </div>
                <div className="mb-3 flex justify-between items-center">
                  <div className="text-xs font-normal">Border Color</div>
                  <div
                    className="h-5 w-5 rounded-full overflow-hidden cursor-pointer relative"
                    style={{
                      backgroundColor: headerBorderColor,
                      border: "1px solid #dde1e6",
                    }}
                    onClick={(e) => {
                      const input = e.currentTarget.firstElementChild;
                      if (input instanceof HTMLElement) {
                        if (showPicker) {
                          setShowPicker(false);
                        } else {
                          input.click();
                          setShowPicker(true);
                        }
                      }
                    }}
                  >
                    <input
                      style={{
                        opacity: 0,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      type="color"
                      id="headerBorderColor"
                      value={headerBorderColor}
                      onChange={(e) =>
                        onHeaderBorderColorChange(e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </AccordionItem>

            <AccordionItem
              title={<span className="text-xs">Column Labels</span>}
              open={openSection === "Column Labels"}
              onClick={() => handleOpenSection("Column Labels")}
            >
              <div className="flex-grow overflow-auto">
                <div className="mb-3 flex justify-between items-center">
                  <div className="text-xs font-normal">Background</div>
                  <div className="flex items-center justify-between">
                    <div
                      className="h-5 w-5 rounded-full overflow-hidden cursor-pointer relative"
                      style={{
                        backgroundColor: columnLabelBackgroundColor,
                        border: "1px solid #dde1e6",
                      }}
                      onClick={(e) => {
                        const input = e.currentTarget.firstElementChild;
                        if (input instanceof HTMLElement) {
                          if (showPicker) {
                            setShowPicker(false);
                          } else {
                            input.click();
                            setShowPicker(true);
                          }
                        }
                      }}
                    >
                      <input
                        style={{
                          opacity: 0,
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        type="color"
                        id="columnLabelBackgroundColor"
                        value={columnLabelBackgroundColor}
                        onChange={(e) =>
                          onColumnLabelBackgroundColorChange(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className=" mb-3 w-full">
                  <div className="w-full border border-solid border-[#dde1e6cc] rounded-[2px]">
                    <Select
                      className="custom-select border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-5"
                      id="label-font"
                      value="Inter"
                      noLabel
                    >
                      <SelectItem value="Inter" text="Inter" />
                      {/* Add more font options */}
                    </Select>
                  </div>
                </div>
                <div className="mb-3 w-full">
                  <div className="flex items-center justify-between">
                    <div
                      className="h-5 w-5 rounded-full overflow-hidden cursor-pointer relative"
                      style={{
                        backgroundColor: columnLabelColor,
                        border: "1px solid #dde1e6",
                      }}
                      onClick={(e) => {
                        const input = e.currentTarget.firstElementChild;
                        if (input instanceof HTMLElement) {
                          if (showPicker) {
                            setShowPicker(false);
                          } else {
                            input.click();
                            setShowPicker(true);
                          }
                        }
                      }}
                    >
                      <input
                        style={{
                          opacity: 0,
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          transform: "scale(0.5)",
                          transformOrigin: "top left",
                        }}
                        type="color"
                        id="columnLabelColor"
                        value={columnLabelColor}
                        onChange={(e) =>
                          onColumnLabelColorChange(e.target.value)
                        }
                      />
                    </div>
                    <div className="w-25 border border-solid border-[#dde1e6cc] rounded-[2px]">
                      <Select
                        className="custom-select border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-5"
                        id="labelFontSize"
                        value="12px"
                        noLabel
                      >
                        <SelectItem value="8px" text="8" />
                        <SelectItem value="9px" text="9" />
                        <SelectItem value="10px" text="10" />
                        <SelectItem value="11px" text="11" />
                        <SelectItem value="12px" text="12" />
                        <SelectItem value="14px" text="14" />
                        <SelectItem value="16px" text="16" />
                        <SelectItem value="18px" text="18" />
                        <SelectItem value="20px" text="20" />
                        <SelectItem value="22px" text="22" />
                        <SelectItem value="24px" text="24" />
                        <SelectItem value="26px" text="26" />
                        <SelectItem value="28px" text="28" />
                        <SelectItem value="36px" text="36" />
                        <SelectItem value="48px" text="48" />
                        <SelectItem value="72px" text="72" />
                      </Select>
                    </div>
                    <div className="flex items-center border rounded h-5">
                      <button
                        className={`px-2 h-full ${columnLabelTextStyle === "bold" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                        onClick={() =>
                          onColumnLabelTextStyleChange(
                            columnLabelTextStyle === "bold"
                              ? "regular"
                              : "bold",
                          )
                        }
                      >
                        <TextBold size={20} />
                      </button>
                      <button
                        className={`px-2 h-full ${columnLabelTextStyle === "italic" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                        onClick={() =>
                          onColumnLabelTextStyleChange(
                            columnLabelTextStyle === "italic"
                              ? "regular"
                              : "italic",
                          )
                        }
                      >
                        <TextItalic size={20} />
                      </button>
                      <button
                        className={`px-2 h-full ${columnLabelTextDecoration === "underline" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                        onClick={() =>
                          onColumnLabelTextDecorationChange(
                            columnLabelTextDecoration === "underline"
                              ? "regular"
                              : "underline",
                          )
                        }
                      >
                        <TextUnderline size={20} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mb-3 flex justify-between items-center">
                  <div className="text-xs font-normal">Alignment</div>
                  <div className="flex items-center border rounded h-5">
                    <TextAlignLeft
                      size={20}
                      className={`ml-2 mr-4 ${columnLabelTextAlignment === "left" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onColumnLabelTextAlignmentChange("left")}
                    />
                    <TextAlignCenter
                      size={20}
                      className={`mr-4 ${columnLabelTextAlignment === "center" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onColumnLabelTextAlignmentChange("center")}
                    />
                    <TextAlignRight
                      size={20}
                      className={`mr-2 ${columnLabelTextAlignment === "right" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onColumnLabelTextAlignmentChange("right")}
                    />
                  </div>
                </div>
                <div className="mb-3 flex justify-between items-center">
                  <div className="text-xs font-normal text-left">
                    Label Row Height
                  </div>
                  <div className="w-25 border border-solid border-[#dde1e6cc] rounded-[2px] ml-auto">
                    <Select
                      className="custom-select border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-5"
                      id="headerRowSize"
                      value={headerHeight}
                      onChange={(e) => onHeaderHeightChange(e.target.value)}
                      noLabel
                    >
                      <SelectItem value="8px" text="8" />
                      <SelectItem value="9px" text="9" />
                      <SelectItem value="10px" text="10" />
                      <SelectItem value="11px" text="11" />
                      <SelectItem value="12px" text="12" />
                      <SelectItem value="13px" text="13" />
                      <SelectItem value="14px" text="14" />
                      <SelectItem value="15px" text="15" />
                      <SelectItem value="16px" text="16" />
                      <SelectItem value="17px" text="17" />
                      <SelectItem value="18px" text="18" />
                      <SelectItem value="19px" text="19" />
                      <SelectItem value="20px" text="20" />
                      <SelectItem value="21px" text="21" />
                      <SelectItem value="22px" text="22" />
                      <SelectItem value="23px" text="23" />
                      <SelectItem value="24px" text="24" />
                      <SelectItem value="25px" text="25" />
                      <SelectItem value="26px" text="26" />
                      <SelectItem value="27px" text="27" />
                      <SelectItem value="28px" text="28" />
                      <SelectItem value="29px" text="29" />
                      <SelectItem value="30px" text="30" />
                      <SelectItem value="31px" text="31" />
                      <SelectItem value="32px" text="32" />
                    </Select>
                  </div>
                </div>
              </div>
            </AccordionItem>
            <AccordionItem
              title={<span className="text-xs">Cell Format</span>}
              open={openSection === "Cell Format"}
              onClick={() => handleOpenSection("Cell Format")}
            >
              <div className="overflow-auto">
                <div className="mb-3 flex justify-between items-center">
                  <div className="text-xs font-normal text-left">
                    Row Height
                  </div>
                  <div className="w-25 border border-solid border-[#dde1e6cc] rounded-[2px] ml-auto">
                    <Select
                      className="custom-select border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-5"
                      id="headerRowSize"
                      value={rowHeight}
                      onChange={(e) => onRowHeightChange(e.target.value)}
                      noLabel
                    >
                      <SelectItem value="8px" text="8" />
                      <SelectItem value="9px" text="9" />
                      <SelectItem value="10px" text="10" />
                      <SelectItem value="11px" text="11" />
                      <SelectItem value="12px" text="12" />
                      <SelectItem value="13px" text="13" />
                      <SelectItem value="14px" text="14" />
                      <SelectItem value="15px" text="15" />
                      <SelectItem value="16px" text="16" />
                      <SelectItem value="17px" text="17" />
                      <SelectItem value="18px" text="18" />
                      <SelectItem value="19px" text="19" />
                      <SelectItem value="20px" text="20" />
                      <SelectItem value="21px" text="21" />
                      <SelectItem value="22px" text="22" />
                      <SelectItem value="23px" text="23" />
                      <SelectItem value="24px" text="24" />
                      <SelectItem value="25px" text="25" />
                      <SelectItem value="26px" text="26" />
                      <SelectItem value="27px" text="27" />
                      <SelectItem value="28px" text="28" />
                      <SelectItem value="29px" text="29" />
                      <SelectItem value="30px" text="30" />
                      <SelectItem value="31px" text="31" />
                      <SelectItem value="32px" text="32" />
                    </Select>
                  </div>
                </div>
              </div>

              <div className="flex-grow overflow-auto">
                <div className="mb-3 flex justify-between items-center">
                  <div className="text-xs font-normal">Background</div>
                  <div className="flex items-center justify-between">
                    <div
                      className="h-5 w-5 rounded-full overflow-hidden cursor-pointer relative"
                      style={{
                        backgroundColor: cellFormatBackgroundColor,
                        border: "1px solid #dde1e6",
                      }}
                      onClick={(e) => {
                        const input = e.currentTarget.firstElementChild;
                        if (input instanceof HTMLElement) {
                          if (showPicker) {
                            setShowPicker(false);
                          } else {
                            input.click();
                            setShowPicker(true);
                          }
                        }
                      }}
                    >
                      <input
                        style={{
                          opacity: 0,
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        type="color"
                        id="cellFormatBackgroundColor"
                        value={cellFormatBackgroundColor}
                        onChange={(e) =>
                          onCellFormatBackgroundColorChange(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <Select
                  className="w-full border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-5"
                  id="cell-format"
                  value={selectedFormat}
                  onChange={(e) => handleSelectedFormatChange(e.target.value)}
                  noLabel
                >
                  <SelectItem value="Default" text="Default" />
                  <SelectItem value="Number" text="Number" />
                  <SelectItem value="USD" text="USD - All Rows" />
                  <SelectItem
                    value="USD_FirstLast"
                    text="USD - First & Last Rows"
                  />
                  <SelectItem value="GBP" text="GBP - All Rows" />
                  <SelectItem
                    value="GBP_FirstLast"
                    text="GBP - First & Last Rows"
                  />
                  <SelectItem value="EUR" text="EUR - All Rows" />
                  <SelectItem
                    value="EUR_FirstLast"
                    text="EUR - First & Last Rows"
                  />
                </Select>

                <div className="mb-3 w-full">
                  <div className="flex items-center justify-between">
                    <div
                      className="h-5 w-5 rounded-full overflow-hidden border appearance-none cursor-pointer relative"
                      style={{ color: cellFormatTextColor }}
                      onClick={(e) => {
                        const input = e.currentTarget.firstElementChild;
                        if (input instanceof HTMLElement) input.click();
                      }}
                    >
                      <input
                        style={{
                          opacity: 0,
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        type="color"
                        id="cellFormatTextColor"
                        value={cellFormatTextColor}
                        onChange={(e) =>
                          onCellFormatTextColorChange(e.target.value)
                        }
                      />
                    </div>

                    <div className="flex items-center border rounded h-5">
                      <button
                        className={`px-2 h-full ${cellFormatTextStyle === "bold" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                        onClick={() =>
                          onCellFormatTextStyleChange(
                            cellFormatTextStyle === "bold" ? "regular" : "bold",
                          )
                        }
                      >
                        <TextBold size={20} />
                      </button>
                      <button
                        className={`px-2 h-full ${cellFormatTextStyle === "italic" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                        onClick={() =>
                          onCellFormatTextStyleChange(
                            cellFormatTextStyle === "italic"
                              ? "regular"
                              : "italic",
                          )
                        }
                      >
                        <TextItalic size={20} />
                      </button>
                      <button
                        className={`px-2 h-full ${cellFormatTextStyle === "underline" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                        onClick={() =>
                          onCellFormatTextStyleChange(
                            cellFormatTextStyle === "underline"
                              ? "regular"
                              : "underline",
                          )
                        }
                      >
                        <TextUnderline size={20} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mb-3 flex justify-between items-center">
                  <div className="text-xs font-normal">Alignment</div>
                  <div className="flex items-center border rounded h-5">
                    <TextAlignLeft
                      size={20}
                      className={`ml-2 mr-4 ${cellFormatTextAlignment === "left" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onCellFormatTextAlignmentChange("left")}
                    />
                    <TextAlignCenter
                      size={20}
                      className={`mr-4 ${cellFormatTextAlignment === "center" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onCellFormatTextAlignmentChange("center")}
                    />
                    <TextAlignRight
                      size={20}
                      className={`mr-2 ${cellFormatTextAlignment === "right" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onCellFormatTextAlignmentChange("right")}
                    />
                  </div>
                </div>

                <div className="mb-3 flex justify-between items-center">
                  <div className="text-xs font-normal">Border</div>
                  <div className="flex items-center border rounded h-5">
                    <BorderFull
                      size={20}
                      className={`ml-2 mr-4 ${cellFormatBorder === "full" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onCellFormatBorderChange("full")}
                    />
                    <BorderBottom
                      size={20}
                      className={`mr-4 ${cellFormatBorder === "bottom" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onCellFormatBorderChange("bottom")}
                    />
                    <BorderTop
                      size={20}
                      className={`mr-4 ${cellFormatBorder === "top" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onCellFormatBorderChange("top")}
                    />
                    <BorderLeft
                      size={20}
                      className={`mr-4 ${cellFormatBorder === "left" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onCellFormatBorderChange("left")}
                    />
                    <BorderRight
                      size={20}
                      className={`mr-2 ${cellFormatBorder === "right" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onCellFormatBorderChange("right")}
                    />
                  </div>
                </div>
                <div className="mb-3 flex justify-between items-center">
                  <div className="text-xs font-normal">Border Color</div>
                  <div
                    className="h-5 w-5 rounded-full overflow-hidden border appearance-none cursor-pointer relative"
                    style={{
                      backgroundColor: cellFormatBorderColor,
                      border: "1px solid #dde1e6",
                    }}
                    onClick={(e) => {
                      const input = e.currentTarget.firstElementChild;
                      if (input instanceof HTMLElement) {
                        if (showPicker) {
                          setShowPicker(false);
                        } else {
                          input.click();
                          setShowPicker(true);
                        }
                      }
                    }}
                  >
                    <input
                      style={{
                        opacity: 0,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      type="color"
                      id="cellFormatBorderColor"
                      value={cellFormatBorderColor}
                      onChange={(e) =>
                        onCellFormatBorderColorChange(e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </AccordionItem>
            <AccordionItem
              title={<span className="text-xs">Total & Sum Rows</span>}
              open={openSection === "Total & Sum Rows"}
              onClick={() => handleOpenSection("Total & Sum Rows")}
            >
              <div className="flex-grow overflow-auto">
                <div className="mb-3 flex justify-between items-center">
                  <div className="text-xs font-normal">Total Background</div>
                  <div className="flex items-center justify-between">
                    <div
                      className="h-5 w-5 rounded-full overflow-hidden border appearance-none cursor-pointer relative"
                      style={{
                        backgroundColor: totalRowBackgroundColor,
                        border: "1px solid #dde1e6",
                      }}
                      onClick={(e) => {
                        const input = e.currentTarget.firstElementChild;
                        if (input instanceof HTMLElement) {
                          if (showPicker) {
                            setShowPicker(false);
                          } else {
                            input.click();
                            setShowPicker(true);
                          }
                        }
                      }}
                    >
                      <input
                        style={{
                          opacity: 0,
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          transform: "scale(0.5)",
                          transformOrigin: "top left",
                        }}
                        type="color"
                        id="totalRowBackgroundColor"
                        value={totalRowBackgroundColor}
                        onChange={(e) =>
                          onTotalRowBackgroundColorChange(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3 w-full border border-solid border-[#dde1e6cc] rounded-[2px] ml-auto">
                  <Select
                    className="w-full border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-5"
                    id="cell-format"
                    value={selectedFormat}
                    onChange={(e) => handleSelectedFormatChange(e.target.value)}
                    noLabel
                  >
                    <SelectItem value="Default" text="Default" />
                    <SelectItem value="Number" text="Number" />
                    <SelectItem value="USD" text="USD - All Rows" />
                    <SelectItem
                      value="USD_FirstLast"
                      text="USD - First & Last Rows"
                    />
                    <SelectItem value="GBP" text="GBP - All Rows" />
                    <SelectItem
                      value="GBP_FirstLast"
                      text="GBP - First & Last Rows"
                    />
                    <SelectItem value="EUR" text="EUR - All Rows" />
                    <SelectItem
                      value="EUR_FirstLast"
                      text="EUR - First & Last Rows"
                    />
                  </Select>
                </div>
                <div className="mb-3 w-full">
                  <div className="flex items-center justify-between">
                    <div
                      className="h-5 w-5 rounded-full overflow-hidden border appearance-none cursor-pointer relative"
                      style={{
                        backgroundColor: totalRowColor,
                        border: "1px solid #dde1e6",
                      }}
                      onClick={(e) => {
                        const input = e.currentTarget.firstElementChild;
                        if (input instanceof HTMLElement) {
                          if (showPicker) {
                            setShowPicker(false);
                          } else {
                            input.click();
                            setShowPicker(true);
                          }
                        }
                      }}
                    >
                      <input
                        style={{
                          opacity: 0,
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          transform: "scale(0.5)",
                          transformOrigin: "top left",
                        }}
                        type="color"
                        id="totalRowColor"
                        value={totalRowColor}
                        onChange={(e) => onTotalRowColorChange(e.target.value)}
                      />
                    </div>
                    <div className="w-25 border border-solid border-[#dde1e6cc] rounded-[2px] ml-auto">
                      <Select
                        className="w-full border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-5"
                        id="headerFontSize"
                        value={totalRowFontSize}
                        onChange={(e) =>
                          onTotalRowFontSizeChange(e.target.value)
                        }
                        noLabel
                      >
                        <SelectItem value="8px" text="8" />
                        <SelectItem value="9px" text="9" />
                        <SelectItem value="10px" text="10" />
                        <SelectItem value="11px" text="11" />
                        <SelectItem value="12px" text="12" />
                        <SelectItem value="14px" text="14" />
                        <SelectItem value="16px" text="16" />
                        <SelectItem value="18px" text="18" />
                        <SelectItem value="20px" text="20" />
                        <SelectItem value="22px" text="22" />
                        <SelectItem value="24px" text="24" />
                        <SelectItem value="26px" text="26" />
                        <SelectItem value="28px" text="28" />
                        <SelectItem value="36px" text="36" />
                        <SelectItem value="48px" text="48" />
                        <SelectItem value="72px" text="72" />
                      </Select>
                    </div>
                    <div className="flex items-center border rounded h-5">
                      <button
                        className={`px-2 h-full ${totalRowTextStyle === "bold" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                        onClick={() =>
                          onTotalRowTextStyleChange(
                            totalRowTextStyle === "bold" ? "regular" : "bold",
                          )
                        }
                      >
                        <TextBold size={20} />
                      </button>
                      <button
                        className={`px-2 h-full ${totalRowTextStyle === "italic" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                        onClick={() =>
                          onTotalRowTextStyleChange(
                            totalRowTextStyle === "italic"
                              ? "regular"
                              : "italic",
                          )
                        }
                      >
                        <TextItalic size={20} />
                      </button>
                      <button
                        className={`px-2 h-full ${totalRowTextStyle === "underline" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                        onClick={() =>
                          onTotalRowTextStyleChange(
                            totalRowTextStyle === "underline"
                              ? "regular"
                              : "underline",
                          )
                        }
                      >
                        <TextUnderline size={20} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mb-3 flex justify-between items-center">
                  <div className="text-xs font-normal">Total Alignment</div>
                  <div className="flex items-center border rounded h-5">
                    <TextAlignLeft
                      size={20}
                      className={`ml-2 mr-4 ${totalRowTextAlignment === "left" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onTotalRowTextAlignmentChange("left")}
                    />
                    <TextAlignCenter
                      size={20}
                      className={`mr-4 ${totalRowTextAlignment === "center" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onTotalRowTextAlignmentChange("center")}
                    />
                    <TextAlignRight
                      size={20}
                      className={`mr-2 ${totalRowTextAlignment === "right" ? "text-blue-500" : "text-gray-500"}`}
                      onClick={() => onTotalRowTextAlignmentChange("right")}
                    />
                  </div>
                </div>

                <div className="mb-3 flex justify-between items-center">
                  <div className="text-xs font-normal">Total Border</div>
                  <div className="flex items-center border rounded h-5">
                    <BorderFull
                      size={20}
                      className={`ml-2 mr-4`}
                      onClick={() => {}}
                    />
                    <div
                      className="h-5 w-5 rounded-full overflow-hidden border appearance-none cursor-pointer relative"
                      style={{
                        backgroundColor: "black",
                        border: "1px solid #dde1e6",
                      }}
                      onClick={(e) => {
                        const input = e.currentTarget.firstElementChild;
                        if (input instanceof HTMLElement) {
                          if (showPicker) {
                            setShowPicker(false);
                          } else {
                            input.click();
                            setShowPicker(true);
                          }
                        }
                      }}
                    >
                      <input
                        style={{
                          opacity: 0,
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          transform: "scale(0.5)",
                          transformOrigin: "top left",
                        }}
                        type="color"
                        id="totalRowBorderColor"
                        value={"black"}
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 flex justify-between items-center">
                <div className="text-xs font-normal">Sub-Total Background</div>
                <div className="flex items-center justify-between">
                  <div
                    className="h-5 w-5 rounded-full overflow-hidden border appearance-none cursor-pointer relative"
                    style={{
                      backgroundColor: subTotalRowBackgroundColor,
                      border: "1px solid #dde1e6",
                    }}
                    onClick={(e) => {
                      const input = e.currentTarget.firstElementChild;
                      if (input instanceof HTMLElement) {
                        if (showPicker) {
                          setShowPicker(false);
                        } else {
                          input.click();
                          setShowPicker(true);
                        }
                      }
                    }}
                  >
                    <input
                      style={{
                        opacity: 0,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                        transform: "scale(0.5)",
                        transformOrigin: "top left",
                      }}
                      type="color"
                      id="subTotalRowBackgroundColor"
                      value={subTotalRowBackgroundColor}
                      onChange={(e) =>
                        onSubTotalRowBackgroundColorChange(e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="w-25 mr-3 mb-3 border border-solid border-[#dde1e6cc] rounded-[2px] ml-auto">
                <Select
                  className="w-full border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-5"
                  id="cell-format"
                  value={selectedFormat}
                  onChange={(e) => handleSelectedFormatChange(e.target.value)}
                  noLabel
                >
                  <SelectItem value="Default" text="Default" />
                  <SelectItem value="Number" text="Number" />
                  <SelectItem value="USD" text="USD - All Rows" />
                  <SelectItem
                    value="USD_FirstLast"
                    text="USD - First & Last Rows"
                  />
                  <SelectItem value="GBP" text="GBP - All Rows" />
                  <SelectItem
                    value="GBP_FirstLast"
                    text="GBP - First & Last Rows"
                  />
                  <SelectItem value="EUR" text="EUR - All Rows" />
                  <SelectItem
                    value="EUR_FirstLast"
                    text="EUR - First & Last Rows"
                  />
                </Select>
              </div>
              <div className="mb-3 w-full">
                <div className="flex items-center justify-between">
                  <div
                    className="h-5 w-5 rounded-full overflow-hidden border appearance-none cursor-pointer relative"
                    style={{
                      backgroundColor: subTotalRowColor,
                      border: "1px solid #dde1e6",
                    }}
                    onClick={(e) => {
                      const input = e.currentTarget.firstElementChild;
                      if (input instanceof HTMLElement) {
                        if (showPicker) {
                          setShowPicker(false);
                        } else {
                          input.click();
                          setShowPicker(true);
                        }
                      }
                    }}
                  >
                    <input
                      style={{
                        opacity: 0,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                        transform: "scale(0.5)",
                        transformOrigin: "top left",
                      }}
                      type="color"
                      id="subTotalRowColor"
                      value={subTotalRowColor}
                      onChange={(e) => onSubTotalRowColorChange(e.target.value)}
                    />
                  </div>
                  <div className="w-25 mr-3 border border-solid border-[#dde1e6cc] rounded-[2px] ml-auto">
                    <Select
                      className="w-full border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-5"
                      id="subTotalRowFontSize"
                      value={subTotalRowFontSize}
                      onChange={(e) =>
                        onSubTotalRowFontSizeChange(e.target.value)
                      }
                      noLabel
                    >
                      <SelectItem value="8px" text="8" />
                      <SelectItem value="9px" text="9" />
                      <SelectItem value="10px" text="10" />
                      <SelectItem value="11px" text="11" />
                      <SelectItem value="12px" text="12" />
                      <SelectItem value="14px" text="14" />
                      <SelectItem value="16px" text="16" />
                      <SelectItem value="18px" text="18" />
                      <SelectItem value="20px" text="20" />
                      <SelectItem value="22px" text="22" />
                      <SelectItem value="24px" text="24" />
                      <SelectItem value="26px" text="26" />
                      <SelectItem value="28px" text="28" />
                      <SelectItem value="36px" text="36" />
                      <SelectItem value="48px" text="48" />
                      <SelectItem value="72px" text="72" />
                    </Select>
                  </div>
                  <div className="flex items-center border rounded h-5">
                    <button
                      className={`px-2 h-full ${subTotalRowTextStyle === "bold" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() =>
                        onSubTotalRowTextStyleChange(
                          subTotalRowTextStyle === "bold" ? "regular" : "bold",
                        )
                      }
                    >
                      <TextBold size={20} />
                    </button>
                    <button
                      className={`px-2 h-full ${subTotalRowTextStyle === "italic" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() =>
                        onSubTotalRowTextStyleChange(
                          subTotalRowTextStyle === "italic"
                            ? "regular"
                            : "italic",
                        )
                      }
                    >
                      <TextItalic size={20} />
                    </button>
                    <button
                      className={`px-2 h-full ${subTotalRowTextStyle === "underline" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() =>
                        onSubTotalRowTextDecorationChange(
                          subTotalRowTextDecoration === "underline"
                            ? "regular"
                            : "underline",
                        )
                      }
                    >
                      <TextUnderline size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-3 flex justify-between items-center">
                <div className="text-xs font-normal">Sub-Total Alignment</div>
                <div className="flex items-center border rounded h-5">
                  <TextAlignLeft
                    size={20}
                    className={`ml-2 mr-4 ${subTotalRowTextAlignment === "left" ? "text-blue-500" : "text-gray-500"}`}
                    onClick={() => onSubTotalRowTextAlignmentChange("left")}
                  />
                  <TextAlignCenter
                    size={20}
                    className={`mr-4 ${subTotalRowTextAlignment === "center" ? "text-blue-500" : "text-gray-500"}`}
                    onClick={() => onSubTotalRowTextAlignmentChange("center")}
                  />
                  <TextAlignRight
                    size={20}
                    className={`mr-2 ${subTotalRowTextAlignment === "right" ? "text-blue-500" : "text-gray-500"}`}
                    onClick={() => onSubTotalRowTextAlignmentChange("right")}
                  />
                </div>
              </div>

              <div className="mb-4 flex justify-between items-center">
                <div className="text-xs font-normal">Sub-Total Border</div>
                <div className="flex items-center border rounded h-5">
                  <BorderFull
                    size={20}
                    className={`ml-2 mr-4 ${subTotalRowBorder === "solid 1px" ? "text-blue-500" : "text-gray-500"}`}
                    onClick={() =>
                      onSubTotalRowBorderChange(
                        subTotalRowBorder === "solid 1px"
                          ? "none"
                          : "solid 1px",
                      )
                    }
                  />
                  <BorderTop
                    size={20}
                    className={`ml-2 mr-4 ${subTotalRowBorderTop === "solid 1px" ? "text-blue-500" : "text-gray-500"}`}
                    onClick={() =>
                      onSubTotalRowBorderTopChange(
                        subTotalRowBorderTop === "solid 1px"
                          ? "none"
                          : "solid 1px",
                      )
                    }
                  />
                  <BorderBottom
                    size={20}
                    className={`ml-2 mr-4 ${subTotalRowBorderBottom === "solid 1px" ? "text-blue-500" : "text-gray-500"}`}
                    onClick={() =>
                      onSubTotalRowBorderBottomChange(
                        subTotalRowBorderBottom === "solid 1px"
                          ? "none"
                          : "solid 1px",
                      )
                    }
                  />

                  <div
                    className="h-5 w-5 rounded-full overflow-hidden border appearance-none cursor-pointer relative"
                    style={{
                      backgroundColor: subTotalRowBorderColor,
                      border: "1px solid #dde1e6",
                    }}
                    onClick={(e) => {
                      const input = e.currentTarget.firstElementChild;
                      if (input instanceof HTMLElement) {
                        if (showPicker) {
                          setShowPicker(false);
                        } else {
                          input.click();
                          setShowPicker(true);
                        }
                      }
                    }}
                  >
                    <input
                      style={{
                        opacity: 0,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                        transform: "scale(0.5)",
                        transformOrigin: "top left",
                      }}
                      type="color"
                      id="subTotalRowBorderColor"
                      value={subTotalRowBorderColor}
                      onChange={(e) =>
                        onSubTotalRowBorderColorChange(e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </AccordionItem>
          </Accordion>
        )}

        {fetchingAnalysis && (
          <div className="flex flex-col h-full">
            <Tabs
              selectedIndex={selectedTabIndex}
              onChange={(e) => setSelectedTabIndex(e.selectedIndex)}
            >
              <TabList
                aria-label="Run New Analysis Tabs"
                className="text-xs flex flex-col h-full px-3 pt-2"
              >
                <Tab>Category</Tab>
                <Tab disabled={!category}>Sub-Category</Tab>
                <Tab disabled={!(category && subCategory)}>Qualifier</Tab>
              </TabList>
              <div className="px-3 h-full">
                <TabPanels>
                  <TabPanel className="flex flex-col h-full">
                    <ScrollWrapper withFade>
                      <div className="flex flex-col space-y-2">
                        {Object.keys(analyses).map((key) => {
                          return (
                            <SelectableTile
                              key={key}
                              id={`selectable-tile-${key}`}
                              name="category"
                              value={key}
                              onClick={() => setCategory(key)}
                              selected={category === key}
                            >
                              <div>
                                <h3 className="text-xs font-semibold">{key}</h3>
                              </div>
                            </SelectableTile>
                          );
                        })}
                      </div>
                    </ScrollWrapper>
                  </TabPanel>
                  <TabPanel className="flex flex-col h-full">
                    <ScrollWrapper withFade className="overflow-auto">
                      <div className="flex flex-col space-y-2">
                        {category &&
                          Object.keys(analyses[category].children).map(
                            (key) => {
                              return (
                                <SelectableTile
                                  selected={subCategory === key}
                                  key={key}
                                  id={`selectable-tile-${key}`}
                                  name="subCategory"
                                  value={key}
                                  onClick={() => {
                                    setSubCategory(key);
                                  }}
                                >
                                  <div>
                                    <h3 className="text-xs font-semibold">
                                      {key}
                                    </h3>
                                  </div>
                                </SelectableTile>
                              );
                            },
                          )}
                      </div>
                    </ScrollWrapper>
                  </TabPanel>
                  <TabPanel className="flex flex-col h-full">
                    <ScrollWrapper withFade className="overflow-auto">
                      <div className="flex flex-col space-y-2">
                        {category &&
                          subCategory &&
                          Object.keys(
                            analyses[category].children[subCategory].children,
                          ).map((key) => {
                            const subqualifiers =
                              analyses[category].children[subCategory].children[
                                key
                              ].children;
                            return (
                              <ExpandableTile
                                key={key}
                                id={`expandable-tile-${key}`}
                                tileCollapsedIconText="Expand to select subqualifiers"
                                tileExpandedIconText="Collapse subqualifiers"
                                onClick={() => {
                                  setQualifier(
                                    qualifier === key ? undefined : key,
                                  );
                                  onQualifierLabelChange(
                                    qualifierLabel === key ? undefined : key,
                                  );
                                }}
                                expanded={qualifier === key}
                              >
                                <TileAboveTheFoldContent>
                                  <div className="text-xs font-semibold">
                                    {key}
                                  </div>
                                </TileAboveTheFoldContent>
                                <TileBelowTheFoldContent>
                                  <div className="flex flex-col space-y-2">
                                    {Object.keys(subqualifiers).map(
                                      (subkey) => (
                                        <Checkbox
                                          key={subkey}
                                          id={`checkbox-${subkey}`}
                                          labelText={subkey}
                                          checked={subQualifiers.includes(
                                            subkey,
                                          )}
                                          onChange={(checked) => {
                                            if (checked) {
                                              setSubQualifiers([
                                                ...subQualifiers,
                                                subkey,
                                              ]);
                                            } else {
                                              setSubQualifiers(
                                                subQualifiers.filter(
                                                  (q) => q !== subkey,
                                                ),
                                              );
                                            }
                                          }}
                                        />
                                      ),
                                    )}
                                  </div>
                                </TileBelowTheFoldContent>
                              </ExpandableTile>
                            );
                          })}
                      </div>
                    </ScrollWrapper>
                  </TabPanel>
                </TabPanels>
              </div>
            </Tabs>

            <div className="mt-4 flex justify-between w-full">
              <Button
                kind="secondary"
                onClick={() => setFetchingAnalysis(false)}
                className="w-1/2"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (selectedTabIndex === 0 && category) {
                    setSelectedTabIndex(1);
                  } else if (selectedTabIndex === 1 && subCategory) {
                    setSelectedTabIndex(2);
                  } else if (selectedTabIndex === 2 && qualifier) {
                    setFetchingAnalysis(false);

                    if (element === "table") {
                      onTableToggle(
                        category!,
                        subCategory!,
                        qualifier!,
                        subQualifiers!,
                      );
                    } else {
                      onChartToggle(
                        category!,
                        subCategory!,
                        qualifier!,
                        subQualifiers!,
                      );
                    }

                    setCategory(undefined);
                    setSubCategory(undefined);

                    setQualifier(undefined);

                    setSelectedTabIndex(0);
                  }
                }}
                className="w-1/2"
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
