export const vdrBarChartData = [
  {
    group: "Florida",
    key: "Monday",
    value: 65_000,
  },
  {
    group: "Florida",
    key: "Tuesday",
    value: 29_123,
  },
  {
    group: "Florida",
    key: "Wednesday",
    value: 35_213,
  },
  {
    group: "Florida",
    key: "Thursday",
    value: 51_213,
  },
  {
    group: "Florida",
    key: "Friday",
    value: 16_932,
  },
  {
    group: "California",
    key: "Monday",
    value: 32_432,
  },
  {
    group: "California",
    key: "Tuesday",
    value: 21_312,
  },
  {
    group: "California",
    key: "Wednesday",
    value: 56_456,
  },
  {
    group: "California",
    key: "Thursday",
    value: 21_312,
  },
  {
    group: "California",
    key: "Friday",
    value: 34_234,
  },
  {
    group: "Tokyo",
    key: "Monday",
    value: 12_312,
  },
  {
    group: "Tokyo",
    key: "Tuesday",
    value: 23_232,
  },
  {
    group: "Tokyo",
    key: "Wednesday",
    value: 34_232,
  },
  {
    group: "Tokyo",
    key: "Thursday",
    value: 12_312,
  },
  {
    group: "Tokyo",
    key: "Friday",
    value: 34_234,
  },
  {
    group: "Temperature",
    key: "Monday",
    temp: 23,
  },
  {
    group: "Temperature",
    key: "Tuesday",
    temp: 21,
  },
  {
    group: "Temperature",
    key: "Wednesday",
    temp: 32,
  },
  {
    group: "Temperature",
    key: "Thursday",
    temp: 34,
  },
  {
    group: "Temperature",
    key: "Friday",
    temp: 23,
  },
];

export const vdrBarChartOptions = {
  // title:
  axes: {
    left: {
      // title: 'Disney Park Attendance',
      mapsTo: "value",
      stacked: true,
    },
    bottom: {
      // title: '2018 Annual Sales Figures',
      mapsTo: "key",
      scaleType: "labels",
    },
    right: {
      title: "Adj. EBITDA Margin (%)",
      mapsTo: "temp",
      correspondingDatasets: ["Temperature"],
    },
  },
  comboChartTypes: [
    {
      type: "stacked-bar",
      options: {},
      correspondingDatasets: ["Florida", "California", "Tokyo"],
    },
    {
      type: "line",
      options: {},
      correspondingDatasets: ["Temperature"],
    },
  ],
  height: "300px",
};

export const vdrDonutChartOptions = {
  resizable: true,
  legend: {
    alignment: "center",
  },
  donut: {
    center: {
      label: "2024E Revenue by Business Line",
    },
    alignment: "center",
  },
  height: "300px",
};
