export { TransactedPlatform } from "./electrodb";
export type { Deal } from "./electrodb";
export type {
  GetDealsOutput,
  CreateDealInput,
  CreateDealOutput,
  GetPromptsOutput,
  Prompt,
  GetDealOutput,
  GetFilesOutput,
  FileSystemObject,
  GetExportsOutput,
  GetInputsOutput,
  ParsedAnalysisData,
  SlideMaster,
  GetActiveExportOutput,
  SaveActiveExportInput,
} from "./api";
