"use client";
import React from "react";

type Props = {
  children: React.ReactNode;
  title?: React.ReactNode;
  actions?: React.ReactNode;
  breadcrumbs?: { title: string; href: string }[];
  showBreadcrumbs?: boolean; // Add this prop
};

export function PageLayout({ children, title, actions }: Props) {
  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <div className="min-w-0 flex">
          <h2 className="text-xl font-semibold leading-7 sm:truncate sm:text-xl text-DarkBlue">
            {title}
          </h2>
        </div>
        <div className="flex flex-grow items-end gap-2">{actions}</div>
      </div>
      {children}
    </>
  );
}
