import { FC, SVGProps } from "react";

export const Tick: FC<SVGProps<SVGSVGElement>> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={18} fill="none">
    <path
      fill="#fff"
      d="M0 0h16v16H0z"
      style={{
        mixBlendMode: "multiply",
      }}
      transform="translate(.918 1.001)"
    />
    <path
      fill="#00F"
      d="m7.918 11.708-2.5-2.5.706-.707 1.794 1.793 3.792-3.793.708.707-4.5 4.5Z"
      style={{
        fill: "#00f",
        fillOpacity: 1,
      }}
    />
    <path
      fill="#00F"
      d="M8.918 2.001a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm0 13a6 6 0 1 1 0-12 6 6 0 0 1 0 12Z"
      style={{
        fill: "#00f",
        fillOpacity: 1,
      }}
    />
  </svg>

  // <svg
  //   width="20"
  //   height="20"
  //   viewBox="0 0 20 20"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  //   className={`stroke-DarkBlue ${className || ""}`}
  //   {...otherProps}
  // >
  //   <path
  //     d="M5 10.3333L9 13.6667L14.3333 7"
  //     strokeWidth="2"
  //     strokeLinecap="round"
  //   />
  // </svg>
);
