import { FC, SVGProps } from "react";

export const Search: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...otherProps
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`stroke-Grey40 ${className || ""}`}
    {...otherProps}
  >
    <circle cx="11" cy="11" r="6" strokeWidth="2" />
    <path d="M15.5 15.5L19 19" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
