import { FC, SVGProps } from "react";

export const Settings: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...otherProps
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-Grey40 ${className || ""}`}
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.83942 2.80388C9.9329 2.33646 10.3433 2 10.82 2H13.1804C13.6571 2 14.0675 2.33646 14.161 2.80388L14.3637 3.81757C14.4346 4.17194 14.6932 4.45583 15.0276 4.59268C15.7026 4.86883 16.3314 5.23455 16.8993 5.67506C17.1848 5.89656 17.56 5.97847 17.9023 5.86266L18.8838 5.53064C19.3353 5.37789 19.8319 5.56509 20.0703 5.97791L21.2505 8.02207C21.4888 8.43489 21.4026 8.95855 21.0446 9.27322L20.2671 9.95651C19.9959 10.1948 19.8792 10.5601 19.9276 10.9178C19.9755 11.2717 20.0002 11.633 20.0002 12C20.0002 12.367 19.9755 12.7282 19.9276 13.0821C19.8793 13.4398 19.996 13.805 20.2671 14.0433L21.0448 14.7268C21.4028 15.0414 21.489 15.5651 21.2506 15.9779L20.0705 18.0221C19.8321 18.4349 19.3355 18.6221 18.884 18.4693L17.9024 18.1373C17.5601 18.0215 17.1849 18.1034 16.8994 18.3249C16.3315 18.7654 15.7026 19.1312 15.0276 19.4073C14.6932 19.5442 14.4346 19.8281 14.3637 20.1824L14.161 21.1961C14.0675 21.6635 13.6571 22 13.1804 22H10.82C10.3433 22 9.9329 21.6635 9.83942 21.1961L9.63668 20.1824C9.56581 19.8281 9.30724 19.5442 8.97277 19.4073C8.29778 19.1312 7.66894 18.7654 7.10103 18.3249C6.81548 18.1034 6.44033 18.0215 6.098 18.1373L5.11642 18.4693C4.66487 18.6221 4.16828 18.4349 3.92994 18.0221L2.74975 15.9779C2.51141 15.5651 2.59758 15.0414 2.95564 14.7268L3.73331 14.0433C4.00443 13.805 4.12112 13.4398 4.07276 13.0821C4.02491 12.7282 4.0002 12.367 4.0002 12C4.0002 11.633 4.02492 11.2717 4.07278 10.9178C4.12115 10.5601 4.00446 10.1948 3.73334 9.95651L2.95584 9.27322C2.59778 8.95855 2.5116 8.43489 2.74994 8.02207L3.93014 5.97791C4.16848 5.56509 4.66507 5.37789 5.11661 5.53064L6.09808 5.86266C6.4404 5.97847 6.81555 5.89656 7.10109 5.67506C7.66899 5.23455 8.29781 4.86883 8.97277 4.59268C9.30724 4.45583 9.56581 4.17194 9.63668 3.81757L9.83942 2.80388ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
    />
  </svg>
);
