import { Entity } from "electrodb";
import { CreateDealInput, CreateDealOutput } from "../model";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import {
  CreateBucketCommand,
  PutBucketCorsCommand,
  PutObjectCommand,
  S3Client,
} from "@aws-sdk/client-s3";
import { v4 } from "uuid";
import { DealSchemaType } from "../model/electrodb";
import { Deal } from "../model/api";
import { ActiveExportSchemaType } from "../model/electrodb/activeExport";
import { TransactedPlatform } from "../model/electrodb";

type CreateDealParams = {
  s3Client: S3Client;
  dealEntity: Entity<string, string, string, DealSchemaType>;
  activeExportEntity: Entity<string, string, string, ActiveExportSchemaType>;
};

export async function createDealCore(
  params: CreateDealParams,
  request: CreateDealInput,
): Promise<CreateDealOutput> {
  const dealId = v4();
  const bucketName = `${request.dealTitle.replaceAll(" ", ".").toLocaleLowerCase()}--${dealId}`;
  const createBucketCommand = new CreateBucketCommand({ Bucket: bucketName });

  console.log("Creating bucket:", bucketName);

  const createBucketReponse = await params.s3Client.send(createBucketCommand);
  console.log("Create bucket response:", createBucketReponse);
  if (createBucketReponse.$metadata.httpStatusCode !== 200) {
    throw new Error("Failed to create bucket");
  }

  const corsCommand = new PutBucketCorsCommand({
    Bucket: bucketName,
    CORSConfiguration: {
      CORSRules: [
        {
          AllowedHeaders: ["*"],
          AllowedMethods: ["GET", "PUT"],
          AllowedOrigins: ["*"],
          MaxAgeSeconds: 3000,
        },
      ],
    },
  });

  await params.s3Client.send(corsCommand);

  await params.dealEntity
    .create({
      dealTitle: request.dealTitle,
      vdrReady: false,
      s3Bucket: bucketName,
      dealId,
    })
    .go();

  await params.activeExportEntity
    .create({
      dealName: request.dealTitle,
      slides: [],
      dealId,
    })
    .go();

  const putObjectCommand: PutObjectCommand = new PutObjectCommand({
    Bucket: bucketName,
    Key: request.vdrFilename,
  });

  const url = await getSignedUrl(params.s3Client, putObjectCommand, {
    expiresIn: 600,
  });

  console.log("URL:", url);
  console.log("Bucket:", bucketName);

  return {
    bucketName,
    url,
  };
}

type GetDealParams = {
  deal: string;
};

export async function getDealCore(
  params: GetDealParams,
): Promise<Deal | undefined> {
  const splitDeal = params.deal.split("--");
  const dealName = splitDeal[0].replaceAll(".", " ");
  const dealId = splitDeal[1];

  const { data } = await TransactedPlatform.entities.deal
    .get({ dealTitle: dealName, dealId })
    .go();
  if (data === null) {
    return undefined;
  }

  return data as Required<Deal>;
}
