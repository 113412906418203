import { FC, SVGProps } from "react";

export const Fire: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...otherProps
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-Grey40  stroke-Grey40  ${className || ""}`}
    {...otherProps}
  >
    <path
      d="M20 13C20 17.4183 16.4183 21 12 21C7.58172 21 4 17.4183 4 13C4 10.6895 4.97729 8.06078 6.57167 6.06269C6.79507 5.78271 6.91892 5.63018 7.01493 5.53234C7.01693 5.53942 7.01899 5.54689 7.0211 5.55474C7.07578 5.75821 7.12369 6.05107 7.20247 6.54636C7.35414 7.50002 7.63383 8.56535 8.18805 9.41634C8.77148 10.3122 9.68905 11 11 11C11.9072 11 12.6376 10.6185 13.1382 9.9701C13.6019 9.36936 13.8231 8.60149 13.924 7.85265C14.1264 6.34995 13.8968 4.50159 13.557 2.92978C13.4771 2.56055 13.437 2.3672 13.4237 2.22848C13.4439 2.23504 13.4699 2.24406 13.5032 2.25649C13.5762 2.28379 13.6606 2.31845 13.78 2.36766C15.4606 3.05994 17.0251 4.68565 18.1823 6.73518C19.334 8.77511 20 11.092 20 13Z"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);
