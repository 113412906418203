import { Icons } from "@/components";
import { AgChartsReact } from "ag-charts-react";
import { ChartRenderDateOptions } from "./dateSort";
import { useState } from "react";
import { ChartOptions, DateSort } from "./types";
import { ParsedAnalysisData } from "../../../../../core/model";
import { Filter } from "../../../../../core/model/api/activeExport";
import { Chart as ChartType } from "@/stores";

export function filterData(
  analysis: ParsedAnalysisData,
  dateSort: DateSort,
  filter: Filter,
) {
  return analysis[dateSort].columns.filter((column, index) => {
    if (index === 0) {
      return true; // Always include the first column (category column)
    }

    const date = new Date(column.split(" - ")[1].trim());
    return date >= filter.start && date <= filter.end;
  });
}

function analysisToChartData(
  analysisData: ParsedAnalysisData,
  chartDateSort: DateSort,
  filter: Filter,
) {
  const transformedData = [];
  for (const timePeriod of Object.keys(analysisData)) {
    if (timePeriod === chartDateSort) {
      const timePeriodData = analysisData[timePeriod].data;
      for (const d of timePeriodData) {
        const category = d[0];
        const values = d.slice(1);
        for (const [index, value] of values.entries()) {
          const column = analysisData[timePeriod].columns[index + 1];
          const [_, dateString] = column.split(" - ");

          const date = new Date(dateString);
          // Filter based on the selected date range and period
          const year = date.getFullYear();
          const monthName = date.toLocaleString("default", {
            month: "long",
          });
          const quarter = `Q${Math.floor((new Date(date).getMonth() + 3) / 3)}`;

          if (date >= filter.start && date <= filter.end) {
            transformedData.push({
              timePeriod:
                chartDateSort === DateSort.MONTHLY
                  ? `${monthName} ${year}`
                  : chartDateSort === DateSort.QUARTERLY
                    ? `${quarter} ${year}`
                    : year,
              [category]: value,
              column: column,
            });
          }
        }
      }
    }
  }

  return transformedData;
}

interface Series {
  type: any;
  xKey: string;
  yKey: string;
  [key: string]: any;
  innerRadiusRatio: any;
  calloutLabelKey: any;
  angleKey: any;
}

type Props = {
  style: any;
  chartWrapperRef: any;
  active: boolean;
  index: number;
  onClick: (index: number) => void;
  data: ParsedAnalysisData;
  chartOptions: ChartOptions;
  width: number;
  height: number;
  chartType: string;
  stacked: boolean;
  x: number;
  y: number;
  chart: ChartType;
  updateChart: (chart: ChartType) => void;
};

export const Chart = ({
  chartWrapperRef,
  active,
  data,
  chartOptions,
  onClick,
  index,
  x,
  y,
  width,
  height,
  chartType,
  chart,
  stacked,
  updateChart,
}: Props) => {
  const [chartDateSort, setChartDateSort] = useState<DateSort>(DateSort.ANNUAL);

  const chartData = analysisToChartData(data, chartDateSort, chart.filter);
  const uniqueCategories = [
    ...new Set(
      chartData.map((item) =>
        Object.keys(item).find(
          (key) => key !== "timePeriod" && key !== "column",
        ),
      ),
    ),
  ].filter(Boolean);

  const series: Series[] = uniqueCategories
    .filter((category): category is string => !!category)
    .map((category) => ({
      type: chartType,
      xKey: "timePeriod",
      yKey: category,
      calloutLabelKey: category,
      stacked: stacked,
      angleKey: "timePeriod",
      innerRadiusRatio: 0.7,
    }));

  const chartOptionsWithData = { ...chartOptions, series, data: chartData };

  const updateFilter = (filter: Filter) => {
    updateChart({ ...chart, filter });
  };

  return (
    <div
      ref={chartWrapperRef}
      className="chart-wrapper"
      data-x="0"
      data-y="0"
      style={{
        transform: `translate(${x}px, ${y}px)`,
        width: width,
        height: height,
        position: "absolute",
        display: "inline-block",
        ...(active
          ? {
              outline: "auto",
              outlineColor: "#0D99FF",
            }
          : {}),
      }}
      onClick={() => onClick(index)}
    >
      {active && (
        <div
          className="dateOptionsWrapper"
          style={{
            position: "absolute",
            top: "-50px",
            width: "100%",
            display: "flex", // Use flexbox
            flexDirection: "row", // Align items in a row
            justifyContent: "space-between", // Adjust this as needed
            alignItems: "center", // Align items vertically in the center
          }}
        >
          <ChartRenderDateOptions
            years={chart.chartYears}
            filter={chart.filter}
            updateFilter={updateFilter}
            chartDateSort={chartDateSort}
            setChartDateSort={setChartDateSort}
          />
        </div>
      )}
      <div className="w-full h-full chart-parent-div" id={`chart-${index}`}>
        <AgChartsReact
          container={document.querySelector("#chartElement")}
          options={chartOptionsWithData as any}
          data={chartOptionsWithData.data}
          series={series}
        />
        {active && (
          <div
            className="absolute z-10"
            style={{
              top: "-2px",
              left: "-2px",
              right: "-2px",
              bottom: "-2px",
            }}
          >
            <Icons.Square
              className="absolute"
              style={{ top: "-2px", left: "-2px" }}
            />
            <Icons.Square
              className="absolute"
              style={{ bottom: "-2px", left: "-2px" }}
            />
            <Icons.Square
              className="absolute"
              style={{ top: "-2px", right: "-2px" }}
            />
            <Icons.Square
              className="absolute"
              style={{ bottom: "-2px", right: "-2px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
