export enum RunNewAnalysisStages {
  SELECT_CATEGORY = "SELECT_CATEGORY",
  SELECT_SUB_CATEGORY = "SELECT_SUB_CATEGORY",
  SELECT_QUALIFIER = "SEECT_QUALIFIER",
  // SELECT_FILE = "SELECT_FILE",
  // CONFIRM_DATA = "CONFIRM_DATA",
}

export enum StagesKeys {
  CATEGORY = "category",
  SUB_CATEGORY = "subCategory",
  QUALIFIER = "qualifier",
  // FILE = "file",
  // CONFIRM_DATA = "confirm_data",
}
