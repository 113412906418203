import { FC, SVGProps } from "react";

export const GridLayout: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...otherProps
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-Grey100 ${className || ""}`}
    {...otherProps}
  >
    <path d="M9 3H5C4 3 3 4 3 5V9C3 10 4 11 5 11H9C10 11 11 10 11 9V5C11 4 10 3 9 3Z" />
    <path d="M19 3H15C14 3 13 4 13 5V9C13 10 14 11 15 11H19C20 11 21 10 21 9V5C21 4 20 3 19 3Z" />
    <path d="M19 13H15C14 13 13 14 13 15V19C13 20 14 21 15 21H19C20 21 21 20 21 19V15C21 14 20 13 19 13Z" />
    <path d="M9 13H5C4 13 3 14 3 15V19C3 20 4 21 5 21H9C10 21 11 20 11 19V15C11 14 10 13 9 13Z" />
  </svg>
);
