import { FC, SVGProps } from "react";

export const Calendar: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...otherProps
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-black ${className || ""}`}
    {...otherProps}
  >
    <path d="M12.6654 2.66927H11.332V2.0026C11.332 1.82579 11.2618 1.65622 11.1368 1.5312C11.0117 1.40618 10.8422 1.33594 10.6654 1.33594C10.4886 1.33594 10.319 1.40618 10.194 1.5312C10.0689 1.65622 9.9987 1.82579 9.9987 2.0026V2.66927H5.9987V2.0026C5.9987 1.82579 5.92846 1.65622 5.80344 1.5312C5.67841 1.40618 5.50884 1.33594 5.33203 1.33594C5.15522 1.33594 4.98565 1.40618 4.86063 1.5312C4.7356 1.65622 4.66536 1.82579 4.66536 2.0026V2.66927H3.33203C2.8016 2.66927 2.29289 2.87998 1.91782 3.25506C1.54274 3.63013 1.33203 4.13884 1.33203 4.66927V12.6693C1.33203 13.1997 1.54274 13.7084 1.91782 14.0835C2.29289 14.4586 2.8016 14.6693 3.33203 14.6693H12.6654C13.1958 14.6693 13.7045 14.4586 14.0796 14.0835C14.4547 13.7084 14.6654 13.1997 14.6654 12.6693V4.66927C14.6654 4.13884 14.4547 3.63013 14.0796 3.25506C13.7045 2.87998 13.1958 2.66927 12.6654 2.66927ZM13.332 12.6693C13.332 12.8461 13.2618 13.0157 13.1368 13.1407C13.0117 13.2657 12.8422 13.3359 12.6654 13.3359H3.33203C3.15522 13.3359 2.98565 13.2657 2.86063 13.1407C2.7356 13.0157 2.66536 12.8461 2.66536 12.6693V8.0026H13.332V12.6693ZM13.332 6.66927H2.66536V4.66927C2.66536 4.49246 2.7356 4.32289 2.86063 4.19787C2.98565 4.07284 3.15522 4.0026 3.33203 4.0026H4.66536V4.66927C4.66536 4.84608 4.7356 5.01565 4.86063 5.14068C4.98565 5.2657 5.15522 5.33594 5.33203 5.33594C5.50884 5.33594 5.67841 5.2657 5.80344 5.14068C5.92846 5.01565 5.9987 4.84608 5.9987 4.66927V4.0026H9.9987V4.66927C9.9987 4.84608 10.0689 5.01565 10.194 5.14068C10.319 5.2657 10.4886 5.33594 10.6654 5.33594C10.8422 5.33594 11.0117 5.2657 11.1368 5.14068C11.2618 5.01565 11.332 4.84608 11.332 4.66927V4.0026H12.6654C12.8422 4.0026 13.0117 4.07284 13.1368 4.19787C13.2618 4.32289 13.332 4.49246 13.332 4.66927V6.66927Z" />
  </svg>
);
