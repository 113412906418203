import { createStore } from "zustand";
import { immer } from "zustand/middleware/immer";

export type Filter = {
  type: "Annual" | "Monthly" | "Quarterly";
  start: Date;
  end: Date;

  rowCount: number;
};

export type Dimensions = {
  height?: number;
  width?: number;
  xOffset?: number;
  yOffset?: number;
};

export type Table = {
  dimensions: Dimensions;
  tableData: string;
  tableYears: number[];
  filter: Filter;
};

export type Chart = {
  dimensions: Dimensions;
  chartData: string;
  chartYears: number[];
  filter: Filter;
};

export type Slide = {
  thumbnail?: string;
  tables: Table[];
  charts: Chart[];
};

type SlidesState = {
  activeSlide: number;
  slides: Slide[];
};

type SlidesActions = {
  setActiveSlide: (slide: number) => void;
  addTable: (table: Table) => void;
  addChart: (chart: Chart) => void;
  removeTable: (index: number) => void;
  removeChart: (index: number) => void;
  updateThumbnail: (thumbnail: string) => void;
  updateTable: (index: number, table: Table) => void;
  updateChart: (index: number, chart: Chart) => void;
  addSlide: () => void;
  updateDimensions: (
    table: boolean,
    index: number,
    dimensions: Dimensions,
  ) => void;
};

export type SlidesStore = SlidesState & SlidesActions;

export const emptySlide: Slide = {
  tables: [],
  charts: [],
};

export const defaultSlidesStore: SlidesState = {
  activeSlide: 0,
  slides: [emptySlide],
};

export const createSlidesStore = (initState = defaultSlidesStore) => {
  return createStore<SlidesActions & SlidesState>()(
    immer((set) => ({
      ...initState,
      setActiveSlide: (slide: number) => set({ activeSlide: slide }),
      addTable: (table: Table) =>
        set((state) => {
          state.slides[state.activeSlide].tables.push(table);
        }),
      addChart: (chart: Chart) =>
        set((state) => {
          state.slides[state.activeSlide].charts.push(chart);
        }),
      updateThumbnail: (thumbnail: string) =>
        set((state) => {
          state.slides[state.activeSlide].thumbnail = thumbnail;
        }),
      addSlide: () =>
        set((state) => {
          state.slides.push({ ...emptySlide });
          state.activeSlide = state.slides.length - 1;
        }),
      removeTable: (index: number) =>
        set((state) => {
          state.slides[state.activeSlide].tables.splice(index, 1);
        }),
      removeChart: (index: number) =>
        set((state) => {
          state.slides[state.activeSlide].charts.splice(index, 1);
        }),
      updateTable: (index: number, table: Table) =>
        set((state) => {
          state.slides[state.activeSlide].tables[index] = table;
        }),
      updateChart: (index: number, chart: Chart) =>
        set((state) => {
          state.slides[state.activeSlide].charts[index] = chart;
        }),
      updateDimensions: (
        table: boolean,
        index: number,
        dimensions: Dimensions,
      ) =>
        set((state) => {
          if (table) {
            state.slides[state.activeSlide].tables[index].dimensions = {
              ...state.slides[state.activeSlide].tables[index].dimensions,
              ...dimensions,
            };
          } else {
            state.slides[state.activeSlide].charts[index].dimensions = {
              ...state.slides[state.activeSlide].charts[index].dimensions,
              ...dimensions,
            };
          }
        }),
    })),
  );
};
