import { Entity, createSchema } from "electrodb";

const DealSchema = createSchema({
  model: {
    entity: "deal",
    service: "transacted-platform",
    version: "1",
  },
  attributes: {
    dealId: {
      type: "string",
      required: true,
    },
    dealTitle: {
      type: "string",
      required: true,
    },
    s3Bucket: {
      type: "string",
      required: true,
    },
    vdrReady: {
      type: "boolean",
      required: true,
    },
  },
  indexes: {
    primary: {
      pk: {
        field: "pk",
        composite: ["dealTitle"],
      },

      sk: {
        field: "sk",
        composite: ["dealId"],
      },
    },
  },
});

export type DealSchemaType = typeof DealSchema;
export const Deal = new Entity(DealSchema);
