import { Entity, createSchema } from "electrodb";

const ActiveExportSchema = createSchema({
  model: {
    entity: "activeExport",
    service: "transacted-platform",
    version: "1",
  },
  attributes: {
    dealName: {
      type: "string",
      required: true,
    },
    dealId: {
      type: "string",
      required: true,
    },
    slides: {
      type: "list",
      required: true,
      items: {
        type: "map",
        properties: {
          title: { type: "string", required: true },
          chartData: { type: "string" },
          tableHeaders: { type: "list", items: { type: "string" } },
          tableData: { type: "string" },
          slideMaster: { type: "string" },
        },
      },
    },
  },
  indexes: {
    primary: {
      pk: {
        field: "pk",
        composite: ["dealName"],
      },
      sk: {
        field: "sk",
        composite: ["dealId"],
      },
    },
  },
});

export const ActiveExport = new Entity(ActiveExportSchema);
export type ActiveExportSchemaType = typeof ActiveExportSchema;
