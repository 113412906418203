"use client";
import React, { ReactNode, useEffect, useId, useState } from "react";
import {
  COLORS,
  DEFAULT_COLOR,
  DEFAULT_FONT,
  DEFAULT_FONT_SIZE,
  FONTS,
  FONT_SIZES,
} from "./constants";
import { Icons } from "@/components";
import { DropdownType, StyleProperty, StylePropertyName } from "./types";

interface EditMenuProps {
  container: Element;
  onChange?: (props: StyleProperty) => void;
  getEditMenuRef?: (props: HTMLDivElement | null | undefined) => void;
  value: StyleProperty;
}

export const EditMenu = (props: EditMenuProps) => {
  const { container, onChange, getEditMenuRef, value } = props;
  const [editMenuRef, setEditMenuRef] = useState<HTMLDivElement | null>();
  const [selectedDropdown, setSelectedDropdown] = useState<
    { element: ReactNode; dropdownType: DropdownType } | undefined
  >();
  const [, setSize] = useState([0, 0]);
  const dropdownId = useId();

  useEffect(() => {
    function handleResize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    getEditMenuRef?.(editMenuRef);
  }, [editMenuRef, getEditMenuRef]);

  const renderDropdown =
    (dropdownType: DropdownType): React.MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      e.nativeEvent.stopImmediatePropagation();
      if (selectedDropdown?.dropdownType === dropdownType) {
        setSelectedDropdown(undefined);
        return;
      }
      switch (dropdownType) {
        case DropdownType.COLORS: {
          setSelectedDropdown({
            element: (
              <div
                id={dropdownId}
                style={{
                  top: e.currentTarget.getBoundingClientRect().height + 2,
                  left: 0,
                }}
                className="absolute p-3 bg-DarkBlue rounded-md"
              >
                <ul className="flex gap-2 flex-wrap max-w-40">
                  {COLORS.map((color) => (
                    <li
                      onClick={handleOnClick("backgroundColor")}
                      data-value={color}
                      key={color}
                      className="w-4 h-4 rounded-full border border-PurplePale cursor-pointer"
                      style={{ backgroundColor: color }}
                    />
                  ))}
                </ul>
              </div>
            ),
            dropdownType,
          });
          return;
        }
        case DropdownType.FONTS: {
          setSelectedDropdown({
            element: (
              <div
                id={dropdownId}
                style={{
                  top: e.currentTarget.getBoundingClientRect().height + 2,
                  left: 80,
                }}
                className="absolute py-3 bg-DarkBlue rounded-md w-48"
              >
                <ul className="gap-2">
                  {FONTS.map((font) => (
                    <li
                      data-value={font}
                      onClick={handleOnClick("fontFamily")}
                      key={font}
                      className="cursor-pointer text-xs py-1.5 ps-7 pe-2 hover:bg-PurplePale"
                      style={{ fontFamily: font }}
                    >
                      {font}
                    </li>
                  ))}
                </ul>
              </div>
            ),
            dropdownType,
          });
          return;
        }
        case DropdownType.FONT_SIZES: {
          setSelectedDropdown({
            element: (
              <div
                id={dropdownId}
                style={{
                  top: e.currentTarget.getBoundingClientRect().height + 2,
                  left: 278,
                }}
                className="absolute py-3 bg-DarkBlue rounded-md w-40"
              >
                <ul className="gap-2 max-w-40">
                  {FONT_SIZES.map((fontSize) => (
                    <li
                      data-value={`${fontSize}px`}
                      onClick={handleOnClick("fontSize")}
                      key={fontSize}
                      className="cursor-pointer py-1.5 ps-7 pe-2 hover:bg-PurplePale"
                      style={{ fontSize: fontSize }}
                    >
                      {fontSize}px
                    </li>
                  ))}
                </ul>
              </div>
            ),
            dropdownType,
          });
          return;
        }
        default: {
          setSelectedDropdown(undefined);
          return;
        }
      }
    };

  const handleOnClick =
    (
      styleProperty: StylePropertyName,
    ): React.MouseEventHandler<HTMLLIElement | HTMLButtonElement> =>
    (e) => {
      const value = e.currentTarget.dataset.value;
      onChange && onChange({ [styleProperty]: value });
    };

  return (
    <div
      ref={(newRef) => setEditMenuRef(newRef)}
      className="h-9 w-max bg-DarkBlue absolute z-50 rounded flex edit-menu text-Grey0"
      style={{
        top: "-5px",
        left: "0",
        transform: `translate(${container.getBoundingClientRect().width > (editMenuRef?.getBoundingClientRect().width ?? 0) ? (container.getBoundingClientRect().width - (editMenuRef?.getBoundingClientRect().width ?? 0)) / 2 : 0}px, -100%)`,
      }}
    >
      <button
        onClick={renderDropdown(DropdownType.COLORS)}
        className="flex relative py-2 px-4 items-center gap-2 border-r border-PurplePale hover:bg-PurplePale rounded-s"
      >
        <div
          className="w-5 h-5 rounded-full border border-PurplePale"
          style={{ backgroundColor: value?.backgroundColor || DEFAULT_COLOR }}
        />
        <Icons.ChevronDownSmall
          className="!fill-white"
          width={20}
          height={20}
        />
      </button>

      <button
        onClick={renderDropdown(DropdownType.FONTS)}
        className="flex py-2 px-4 items-center gap-2 border-r border-PurplePale hover:bg-PurplePale"
      >
        <Icons.Text />
        <div className="text-Grey10 text-sm w-28 text-start">
          {value?.fontFamily || DEFAULT_FONT}
        </div>
        <Icons.ChevronDownSmall
          className="!fill-white"
          width={20}
          height={20}
        />
      </button>

      <button
        onClick={renderDropdown(DropdownType.FONT_SIZES)}
        className="flex py-2 px-4 items-center gap-2 border-r border-PurplePale hover:bg-PurplePale"
      >
        <Icons.Text />
        <div className="text-Grey10 text-sm">
          {value?.fontSize || DEFAULT_FONT_SIZE}
        </div>
        <Icons.ChevronDownSmall
          className="!fill-white"
          width={20}
          height={20}
        />
      </button>

      <div className="flex items-stretch">
        <button
          onClick={handleOnClick("fontWeight")}
          className="hover:bg-PurplePale py-2 px-4"
          data-value={value.fontWeight === "700" ? 400 : 700}
        >
          <Icons.BoldText />
        </button>
        <button
          onClick={handleOnClick("fontStyle")}
          data-value={value.fontStyle === "italic" ? "normal" : "italic"}
          className="hover:bg-PurplePale py-2 px-4 rounded-e"
        >
          <Icons.ItalicText />
        </button>
      </div>
      {selectedDropdown?.element}
    </div>
  );
};
