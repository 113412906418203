"use client";
import React from "react";
import { Button } from "@carbon/react";
// eslint-disable-next-line import/no-internal-modules
import { Add } from "@carbon/react/icons";

interface NoIItemsPlaceholderProps {
  title: string;
  subtitle: string;
  buttonText?: string;
  onClick?: () => void;
}

export const NoItemsPlaceholder = (props: NoIItemsPlaceholderProps) => {
  const { title, subtitle, buttonText, onClick } = props;
  return (
    <div className="flex justify-center items-center w-full h-96 col-span-4">
      <div className="flex flex-col items-center max-w-72 gap-8">
        <div className="flex flex-col items-center justify-center gap-2">
          <h2 className="text-3xl font-bold leading-7 text-LightGrey sm:truncate sm:text-4xl -tracking-[.02em] text-center sm:text-start">
            {title}
          </h2>
          <span className="text-sm md:text-md text-center sm:text-start text-LightGrey">
            {subtitle}
          </span>
        </div>
        {buttonText && (
          <div className="w-full">
            <Button
              renderIcon={Add}
              size="md"
              className="flex min-w-44 w-full"
              onClick={onClick}
            >
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
