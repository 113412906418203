import { FC, SVGProps } from "react";

export const ChevronDownSmall: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...otherProps
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-DarkBlue ${className || ""}`}
    {...otherProps}
  >
    <g opacity="0.8">
      <path d="M16.3543 10.3533L12.3543 14.3533C12.2563 14.4513 12.1283 14.4993 12.0003 14.4993C11.8723 14.4993 11.7442 14.4503 11.6462 14.3533L7.64625 10.3533C7.45125 10.1583 7.45125 9.84125 7.64625 9.64625C7.84125 9.45125 8.15828 9.45125 8.35328 9.64625L11.9993 13.2922L15.6453 9.64625C15.8403 9.45125 16.1573 9.45125 16.3523 9.64625C16.5473 9.84125 16.5493 10.1573 16.3543 10.3533Z" />
    </g>
  </svg>
);
