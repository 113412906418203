"use client";
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  SortingStrategy,
  arrayMove,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import React, { ReactNode, useState } from "react";

interface RenderItemProps<T extends { id: UniqueIdentifier }> {
  item: T;
}

export interface DndLayoutProps<T extends { id: UniqueIdentifier }> {
  strategy?: SortingStrategy;
  onDragEnd?: (newArray: T[], event: DragEndEvent) => void;
  onDragStart?: (event: DragStartEvent) => void;
  items: T[];
  renderItem: (props: RenderItemProps<T>) => ReactNode;
}

export const DndLayout = <T extends { id: UniqueIdentifier }>(
  props: DndLayoutProps<T>,
) => {
  const { strategy, onDragEnd, onDragStart, items, renderItem } = props;
  const [_, setDraggedItemId] = useState<UniqueIdentifier | undefined>();
  const sensores = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setDraggedItemId(active.id);
    onDragStart && onDragStart(event);
  };

  const handleSlidesDragEnd = (event: DragEndEvent) => {
    setDraggedItemId(undefined);
    const { active, over } = event;
    if (active.id === over?.id) {
      return;
    }

    const getSlidePosition = (id: string) =>
      items.findIndex((item) => item.id === id);
    const originalPosition = getSlidePosition(active.id.toString());
    const newPosition = getSlidePosition(over?.id.toString() || "");

    onDragEnd &&
      onDragEnd(arrayMove(items, originalPosition, newPosition), event);
  };

  return (
    <DndContext
      sensors={sensores}
      onDragEnd={handleSlidesDragEnd}
      onDragStart={handleDragStart}
      collisionDetection={closestCorners}
    >
      <SortableContext
        items={items.map((item) => ({
          id: item.id,
        }))}
        strategy={strategy}
      >
        {items.map((item) => renderItem({ item }))}
      </SortableContext>
    </DndContext>
  );
};
