"use client";
import React, { ReactNode, useState } from "react";
import { TextPill, DndLayout } from "@/components";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Checkbox } from "../Checkbox";
import { UniqueIdentifier } from "@dnd-kit/core";
import { AnalysisRun } from "../../../../../core/model/api/analysisRun";

interface AnalysesCardProps {
  analyse: AnalysisRun;
  controlButtons?: ReactNode;
  className?: string;
  slides?: string[];
}

const Slide = ({ id }: { id: UniqueIdentifier }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const dragStyle = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div
      style={dragStyle}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={`w-36 h-20 p-2 flex justify-end border border-Grey10 shadow-sm rounded touch-none ${isDragging ? "!shadow-lg" : ""}`}
    >
      <Checkbox checked />
    </div>
  );
};

export const AnalysesCard = (props: AnalysesCardProps) => {
  const { analyse, controlButtons, className, slides } = props;

  const [slidesList, setSlidesList] = useState(slides);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: analyse.analysisRunId });

  const handleSlidesDragEnd = (newArray: { id: string }[]) => {
    setSlidesList(newArray.map((item) => item.id));
  };

  const dragStyle = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const renderSlides = ({ item }: { item: { id: string } }) => (
    <Slide key={item.id} id={item.id} />
  );

  return (
    <div
      style={dragStyle}
      className={`items-start lg:flex-row lg:items-center justify-between gap-8 bg-Grey0 rounded-xl shadow-sm p-5 whitespace-nowrap touch-none ${isDragging ? "!shadow-lg" : ""} ${className || ""}`}
    >
      <div className="flex flex-col w-full">
        <div className="flex">
          <div
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            className="flex flex-1 justify-between active:cursor-grab"
          >
            <div className="flex flex-col gap-1 flex-1">
              <span className="text-xl font-medium text-DarkBlue">
                {analyse.category}
              </span>
              <span className="text-sm text-DarkBlue">
                {/* {deal.completed === true
            ? `Completed: ${deal.completedDate}`
            : "Not started"} */}
              </span>
            </div>
            <div className="w-full flex flex-col items-start lg:flex-row lg:items-center gap-2 justify-between flex-[2]">
              <div className="flex flex-wrap md:flex-nowrap items-center md:justify-center justify-start gap-2">
                {[
                  analyse.subcategory,
                  analyse.qualifier,
                  ...(analyse.subQualifiers || []),
                ].map((tag, index) => (
                  <TextPill
                    key={index}
                    text={tag}
                    className={index === 0 ? "bg-Salad" : undefined}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-4">
            {controlButtons}
          </div>
        </div>

        {slidesList && (
          <div className="flex gap-3">
            <DndLayout
              items={slidesList.map((slide) => ({ id: slide }))}
              renderItem={renderSlides}
              onDragEnd={handleSlidesDragEnd}
            />
          </div>
        )}
      </div>
    </div>
  );
};
