import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  SelectItem,
  Accordion,
  AccordionItem,
  Checkbox,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
  TabList,
  SelectableTile,
} from "@carbon/react";
/* eslint-disable import/no-internal-modules */
import {
  BorderFull,
  BorderBottom,
  BorderTop,
  BorderLeft,
  BorderRight,
  ChartScatter,
  ChartLine,
  ChartPie,
  ChartRing,
  ChartArea,
  ChartColumn,
  ChartDualYAxis,
  CaretLeft,
  CaretRight,
  CrossTab,
  ChartStacked,
  ChartBarStacked,
} from "@carbon/react/icons";
/* eslint-enable import/no-internal-modules */
import { ValueFormatterParams } from "ag-grid-community";
import { GetAvailableAnalysesOutput } from "../../../../../../../core/model/api/analysis";
import { ScrollWrapper } from "@/components";

interface ChartEditorProps {
  chartType: string;
  stacked: boolean;
  axisSwitched: boolean;
  chartOptions?: any;
  onChartToggle: (
    category: string,
    subCategory: string,
    qualifier: string,
    subQualifiers: string[],
  ) => void;
  onTableToggle: (
    category: string,
    subCategory: string,
    qualifier: string,
    subQualifiers: string[],
  ) => void;
  onUpdateChartOptions: (options: {
    type?: any;
    [key: string]: any;
    series?: any;
    resizable?: boolean;
    donut?: boolean;
    legend?: boolean;
  }) => void;
  chartBackgroundColor: string;
  onChartBackgroundColorChange: (color: string) => void;
  isChartBackgroundTransparent: boolean;
  onChartBackgroundTransparentChange: (isTransparent: boolean) => void;
  chartBorder: string;
  onChartBorderChange: (border: string) => void;
  chartBorderColor: string;
  onChartBorderColorChange: (color: string) => void;
  isAxisGridLines: boolean;
  onAxisGridLinesChange: (isAxisGridLines: boolean) => void;
  analyses: GetAvailableAnalysesOutput;
  onChartTypeChange: (newType: string, stacked: boolean) => void;
}

export function currencyFormatter(
  params: ValueFormatterParams,
  formatOptions: { selectedFormat: string; decimalPlaces: number },
) {
  const number = Number.parseFloat(params.value);
  if (Number.isNaN(number)) {
    return "-";
  }
  if (number === 0) {
    return "-";
  }

  let currency = "USD"; // Default currency
  if (formatOptions.selectedFormat === "currencyEUR") {
    currency = "EUR";
  } else if (formatOptions.selectedFormat === "currencyGBP") {
    currency = "GBP";
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: formatOptions.decimalPlaces,
    maximumFractionDigits: formatOptions.decimalPlaces,
  });

  return formatter.format(number);
}

export function percentFormatter(
  params: ValueFormatterParams,
  formatOptions: { selectedFormat: string; decimalPlaces: number },
) {
  const number = Number.parseFloat(params.value);
  if (Number.isNaN(number)) {
    return "-";
  }
  if (number === 0) {
    return "-";
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: formatOptions.decimalPlaces,
    maximumFractionDigits: formatOptions.decimalPlaces,
  });

  const formattedNumber = formatter.format(number / 100); // Convert to a fraction for percent formatting
  return number > 0 ? formattedNumber : `(${formattedNumber})`;
}

export function textFormatter(params: ValueFormatterParams) {
  // Simply return the value as a string
  return params.value.toString();
}

export function getFormatterFunction(format: string, decimalPlaces: number) {
  switch (format) {
    case "percent": {
      return (params: ValueFormatterParams) =>
        percentFormatter(params, { selectedFormat: format, decimalPlaces });
    }
    case "text": {
      return (params: ValueFormatterParams) => textFormatter(params);
    }
    default: {
      return (params: ValueFormatterParams) =>
        currencyFormatter(params, { selectedFormat: format, decimalPlaces });
    }
  }
}

export const ChartEditor: React.FC<ChartEditorProps> = ({
  stacked,
  chartType,
  onChartTypeChange,
  axisSwitched: propAxisSwitched,
  onUpdateChartOptions,
  onTableToggle,
  onChartToggle,
  chartBackgroundColor,
  onChartBackgroundColorChange,
  isChartBackgroundTransparent,
  onChartBackgroundTransparentChange,
  chartBorder,
  onChartBorderChange,
  chartBorderColor,
  onChartBorderColorChange,
  isAxisGridLines,
  onAxisGridLinesChange,
  analyses,
}) => {
  // Renamed state variable for clarity and to avoid conflict
  const [isAxisSwitched, setIsAxisSwitched] = useState(propAxisSwitched);
  const [xAxisLabelFormat, setXAxisLabelFormat] = useState("text");
  const [yAxisLabelFormat, setYAxisLabelFormat] = useState("default");
  const [decimalPlaces, setDecimalPlaces] = useState(0);
  const [yAxisMin, setYAxisMin] = useState<number | undefined>();
  const [yAxisMax, setYAxisMax] = useState<number | undefined>();

  useEffect(() => {
    onUpdateChartOptions({
      xAxisLabel: {
        formatter: getFormatterFunction(xAxisLabelFormat, decimalPlaces),
      },
      yAxisLabel: {
        formatter: getFormatterFunction(yAxisLabelFormat, decimalPlaces),
      },
      yAxis: {
        min: yAxisMin,
        max: yAxisMax,
      },
    });
  }, [
    decimalPlaces,
    xAxisLabelFormat,
    yAxisLabelFormat,
    yAxisMin,
    yAxisMax,
    onUpdateChartOptions,
  ]);

  const toggleAxisSwitch = () => {
    const newAxisSwitchedState = !isAxisSwitched;
    setIsAxisSwitched(newAxisSwitchedState);
    onUpdateChartOptions({ axisSwitched: newAxisSwitchedState });
  };

  const onXAxisLabelFormatChange = (format: string) => {
    setXAxisLabelFormat(format);
    onUpdateChartOptions({
      xAxisLabel: {
        formatter: getFormatterFunction(format, decimalPlaces),
      },
    });
  };

  const onYAxisLabelFormatChange = (format: string) => {
    setYAxisLabelFormat(format);
    onUpdateChartOptions({
      yAxisLabel: {
        formatter: getFormatterFunction(format, decimalPlaces),
      },
    });
  };

  const [openSection, setOpenSection] = useState<string>("labels");
  const [showPicker, setShowPicker] = useState(false);

  const handleOpenSection = (sectionName: string) => {
    if (openSection !== sectionName) {
      setOpenSection(sectionName);
    }
  };

  const [fetchingAnalysis, setFetchingAnalysis] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [category, setCategory] = useState<string | undefined>();
  const [subCategory, setSubCategory] = useState<string | undefined>();
  const [qualifier, setQualifier] = useState<string | undefined>();
  // eslint-disable-next-line no-unused-vars
  const [subQualifiers, setSubQualifiers] = useState<string[]>([]);
  const [element, setElement] = useState<"chart" | "table">("table");

  const handleAddingElement = (element: "chart" | "table") => {
    setElement(element);
    setFetchingAnalysis(true);
  };

  return (
    <div className="flex flex-col flex-1 shadow-sm">
      <div className="w-full pt-5 pb-1 pl-3 pr-3 bg-white border-b border-[#8d8d8d]">
        {fetchingAnalysis ? (
          <>
            <div className="mt-1">
              <div
                className="text-sm font-semibold flex items-center pb-5"
                style={{ borderBottom: "1px solid #e8e8e8" }}
              >
                Analysis Selection
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="flex justify-between items-center pb-5"
              style={{ borderBottom: "1px solid #e8e8e8" }}
            >
              <div className="text-sm font-semibold">Add</div>

              <div className="flex justify-between gap-2 pr-1">
                <Button
                  kind="ghost"
                  renderIcon={CrossTab}
                  hasIconOnly
                  size="sm"
                  style={{ border: "1px solid lightgray", borderRadius: "2px" }}
                  onClick={() => {
                    handleAddingElement("table");
                  }}
                ></Button>
                <Button
                  kind="ghost"
                  renderIcon={ChartStacked}
                  hasIconOnly
                  size="sm"
                  style={{ border: "1px solid lightgray", borderRadius: "2px" }}
                  onClick={() => {
                    handleAddingElement("chart");
                  }}
                ></Button>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="flex-grow w-full border bg-Grey0 text-Black100 shadow-sm">
        {!fetchingAnalysis && (
          <Accordion isFlush={true}>
            <AccordionItem
              title="Chart Type"
              open={openSection === "chartType"}
              onClick={() => handleOpenSection("chartType")}
              className="flex-1"
            >
              <div className="mb-1 flex justify-between items-center">
                <div className="text-xs font-normal">Bar</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "bar" && !stacked ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => {
                        onChartTypeChange("bar", false);
                      }}
                    >
                      <ChartColumn size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-1 flex justify-between items-center">
                <div className="text-xs font-normal">Stacked Bar</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "bar" && stacked ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("bar", true)}
                    >
                      <ChartBarStacked size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Line</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "line" && !stacked ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("line", false)}
                    >
                      <ChartLine size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Donut</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "donut" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("donut", false)}
                    >
                      <ChartRing size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Scatter</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "scatter" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("scatter", false)}
                    >
                      <ChartScatter size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Pie</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "pie" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("pie", false)}
                    >
                      <ChartPie size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Area</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "area" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("area", false)}
                    >
                      <ChartArea size={20} />
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Waterfall</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "waterfall" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("waterfall")}
                    >
                      <ChartWaterfall size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Range Bar</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "range-bar" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("range-bar")}
                    >
                      <ChartColumnFloating size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Histogram</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "histogram" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("histogram")}
                    >
                      <ChartHistogram size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Box Plot</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "box-plot" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("box-plot")}
                    >
                      <BoxPlot size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Radar</div>
                <di v className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "radar-line" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("radar-line")}
                    >
                      <ChartRadar size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Bubble</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "bubble" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("bubble")}
                    >
                      <ChartBubble size={20} />
                    </button>
                  </div>
                </div>
              </div> */}
            </AccordionItem>
            <AccordionItem
              title="Axis & Labels"
              open={openSection === "axisAndLabels"}
              onClick={() => handleOpenSection("axisAndLabels")}
              className="flex-1"
            >
              <div className="mt-2 mb-2 flex justify-between items-center">
                <div className="text-xs font-normal">Switch Axes</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "bubble" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={toggleAxisSwitch}
                    >
                      <ChartDualYAxis size={20} />
                    </button>
                  </div>
                </div>
              </div>

              <div className="mb-2 mt-2 flex justify-between items-center">
                <div className="text-xs font-normal">Axis Grid Lines</div>
                <div className="flex items-center justify-between">
                  <Checkbox
                    checked={isAxisGridLines}
                    onChange={(event) =>
                      onAxisGridLinesChange(event.target.checked)
                    }
                    id="axisGridLines"
                    labelText=""
                  />
                </div>
              </div>

              <div className="mt-2 mb-2 flex justify-between items-center">
                <div className="text-xs font-normal">Set Decimals</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      onClick={() =>
                        setDecimalPlaces((prev) => Math.max(0, prev - 1))
                      }
                      className={`px-2 h-full "hover:bg-blue-500 hover:text-white" : "bg-transparent text-gray-500"}`}
                    >
                      <CaretLeft size={20} />
                    </button>
                    <span className="text-xs">{decimalPlaces}</span>
                    <button
                      onClick={() => setDecimalPlaces((prev) => prev + 1)}
                      className={`px-2 h-full "hover:bg-blue-500 hover:text-white" : "bg-transparent text-gray-500"}`}
                    >
                      <CaretRight size={20} />
                    </button>
                  </div>
                </div>
              </div>

              <div className="mt-2 mb-2 flex justify-between items-center">
                <div className="text-xs font-normal">Font</div>
                <div className="flex items-center">
                  <Select
                    className="w-full border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-7"
                    id="label-font"
                    value="Inter"
                    noLabel
                  >
                    <SelectItem value="Inter" text="Inter" />
                  </Select>
                </div>
              </div>
              <div className="mt-2 mb-2 flex justify-between items-center">
                <div className="text-xs font-normal">X-Axis Format</div>
                <div className="flex items-center">
                  <Select
                    className="w-full border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-7"
                    id="x-axis-format"
                    value={xAxisLabelFormat}
                    onChange={(event) =>
                      onXAxisLabelFormatChange(event.target.value)
                    }
                    noLabel
                  >
                    <SelectItem value="default" text="Default" />
                    <SelectItem value="currency" text="Currency ($)" />
                    <SelectItem value="currencyEUR" text="Currency (€)" />
                    <SelectItem value="currencyGBP" text="Currency (£)" />
                    <SelectItem value="percent" text="Percent" />
                    <SelectItem value="text" text="Text" />
                  </Select>
                </div>
              </div>
              <div className="mt-2 mb-2 flex justify-between items-center">
                <div className="text-xs font-normal">Y-Axis Format</div>
                <div className="flex items-center">
                  <Select
                    className="w-full border rounded [&_select]:border-none [&_select]:bg-transparent [&_select]:text-xs [&_select]:h-7"
                    id="y-axis-format"
                    value={yAxisLabelFormat}
                    onChange={(event) =>
                      onYAxisLabelFormatChange(event.target.value)
                    }
                    noLabel
                  >
                    <SelectItem value="default" text="Default" />
                    <SelectItem value="currency" text="Currency ($)" />
                    <SelectItem value="currencyEUR" text="Currency (€)" />
                    <SelectItem value="currencyGBP" text="Currency (£)" />
                    <SelectItem value="percent" text="Percent" />
                    <SelectItem value="text" text="Text" />
                  </Select>
                </div>
              </div>
              <div className="mt-2 mb-2 flex justify-between items-center">
                <div className="text-xs font-normal">Y-Axis Min-Max</div>
                <div className="flex items-center">
                  <input
                    className="max-w-10 text-xs border rounded [&_input]:border-none [&_input]:bg-transparent [&_input]:text-sm [&_input]:h-7 mr-2"
                    type="number"
                    id="y-axis-min"
                    value={yAxisMin === undefined ? "" : yAxisMin}
                    onChange={(e) =>
                      setYAxisMin(
                        e.target.value === ""
                          ? undefined
                          : Number(e.target.value),
                      )
                    }
                  />
                  <input
                    className="max-w-10 text-xs border rounded [&_input]:border-none [&_input]:bg-transparent [&_input]:text-sm [&_input]:h-7"
                    type="number"
                    id="y-axis-max"
                    value={yAxisMax === undefined ? "" : yAxisMax}
                    onChange={(e) =>
                      setYAxisMax(
                        e.target.value === ""
                          ? undefined
                          : Number(e.target.value),
                      )
                    }
                  />
                </div>
              </div>
            </AccordionItem>
            <AccordionItem
              title="Chart Area"
              open={openSection === "chartArea"}
              onClick={() => handleOpenSection("chartArea")}
              className="flex-1"
            >
              <div className="mb-2 mt-2 flex justify-between items-center">
                <div className="text-xs font-normal">Background</div>
                <div className="flex items-center justify-between">
                  <div
                    className="h-5 w-5 rounded-full overflow-hidden cursor-pointer relative"
                    style={{
                      backgroundColor: chartBackgroundColor,
                      border: "1px solid #dde1e6",
                    }}
                    onClick={(e) => {
                      const input = e.currentTarget.firstElementChild;
                      if (input instanceof HTMLElement) {
                        if (showPicker) {
                          setShowPicker(false);
                        } else {
                          input.click();
                          setShowPicker(true);
                        }
                      }
                    }}
                  >
                    <input
                      style={{
                        opacity: 0,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      type="color"
                      id="chartBackgroundColor"
                      value={chartBackgroundColor}
                      onChange={(e) =>
                        onChartBackgroundColorChange(e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="mb-2 mt-2 flex justify-between items-center">
                <div className="text-xs font-normal">
                  Background Transparent
                </div>
                <div className="flex items-center justify-between">
                  <Checkbox
                    checked={isChartBackgroundTransparent}
                    onChange={(event) =>
                      onChartBackgroundTransparentChange(event.target.checked)
                    }
                    id="chartBackgroundTransparent"
                    labelText=""
                    className="pr-0 mr-0"
                  />
                </div>
              </div>

              <div className="mb-4 flex justify-between items-center">
                <div className="text-xs font-normal">Border</div>
                <div className="flex items-center border rounded h-7">
                  <BorderFull
                    size={20}
                    className={`ml-2 mr-4 ${chartBorder === "full" ? "text-blue-500" : "text-gray-500"}`}
                    onClick={() => onChartBorderChange("full")}
                  />
                  <BorderBottom
                    size={20}
                    className={`mr-4 ${chartBorder === "bottom" ? "text-blue-500" : "text-gray-500"}`}
                    onClick={() => onChartBorderChange("bottom")}
                  />
                  <BorderTop
                    size={20}
                    className={`mr-4 ${chartBorder === "top" ? "text-blue-500" : "text-gray-500"}`}
                    onClick={() => onChartBorderChange("top")}
                  />
                  <BorderLeft
                    size={20}
                    className={`mr-4 ${chartBorder === "left" ? "text-blue-500" : "text-gray-500"}`}
                    onClick={() => onChartBorderChange("left")}
                  />
                  <BorderRight
                    size={20}
                    className={`mr-2 ${chartBorder === "right" ? "text-blue-500" : "text-gray-500"}`}
                    onClick={() => onChartBorderChange("right")}
                  />
                </div>
              </div>
              <div className="mb-4 flex justify-between items-center">
                <div className="text-xs font-normal">Border Color</div>
                <div
                  className="h-7 w-7 rounded-full overflow-hidden border appearance-none cursor-pointer relative"
                  style={{ borderColor: chartBorderColor }}
                  onClick={(e) => {
                    const input = e.currentTarget.firstElementChild;
                    if (input instanceof HTMLElement) input.click();
                  }}
                >
                  <input
                    style={{
                      opacity: 0,
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                    }}
                    type="color"
                    id="chartBorderColor"
                    value={chartBorderColor}
                    onChange={(e) => onChartBorderColorChange(e.target.value)}
                  />
                </div>
              </div>
            </AccordionItem>
          </Accordion>
        )}

        {fetchingAnalysis && (
          <div className="flex flex-col h-full">
            <Tabs
              selectedIndex={selectedTabIndex}
              onChange={(e) => setSelectedTabIndex(e.selectedIndex)}
            >
              <TabList
                aria-label="Run New Analysis Tabs"
                className="text-xs flex flex-col h-full px-1 pt-2"
              >
                <Tab>Category</Tab>
                <Tab disabled={!category}>Sub-Category</Tab>
                <Tab disabled={!(category && subCategory)}>Qualifier</Tab>
              </TabList>
              <TabPanels>
                <TabPanel className="flex flex-col h-full">
                  <ScrollWrapper withFade>
                    <div className="flex flex-col space-y-2">
                      {Object.keys(analyses).map((key) => {
                        return (
                          <SelectableTile
                            selected={category === key}
                            key={key}
                            id={`selectable-tile-${key}`}
                            name="category"
                            value={key}
                            onClick={() => setCategory(key)}
                          >
                            <div>
                              <h3 className="text-xs font-semibold">{key}</h3>
                            </div>
                          </SelectableTile>
                        );
                      })}
                    </div>
                  </ScrollWrapper>
                </TabPanel>
                <TabPanel className="flex flex-col h-full">
                  <ScrollWrapper withFade className="overflow-auto">
                    <div className="flex flex-col space-y-2">
                      {category &&
                        Object.keys(analyses[category].children).map((key) => {
                          return (
                            <SelectableTile
                              selected={subCategory === key}
                              key={key}
                              id={`selectable-tile-${key}`}
                              name="subCategory"
                              value={key}
                              onClick={() => setSubCategory(key)}
                            >
                              <div>
                                <h3 className="text-xs font-semibold">{key}</h3>
                              </div>
                            </SelectableTile>
                          );
                        })}
                    </div>
                  </ScrollWrapper>
                </TabPanel>
                <TabPanel className="flex flex-col h-full">
                  <ScrollWrapper withFade className="overflow-auto">
                    <div className="flex flex-col space-y-2">
                      {category &&
                        subCategory &&
                        Object.keys(
                          analyses[category].children[subCategory].children,
                        ).map((key) => {
                          return (
                            <SelectableTile
                              selected={qualifier === key}
                              key={key}
                              id={`selectable-tile-${key}`}
                              name="qualifier"
                              value={key}
                              onClick={() => setQualifier(key)}
                            >
                              <div>
                                <h3 className="text-xs font-semibold">{key}</h3>
                              </div>
                            </SelectableTile>
                          );
                        })}
                    </div>
                  </ScrollWrapper>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <div className="mt-4 flex justify-between w-full">
              <Button
                kind="secondary"
                onClick={() => setFetchingAnalysis(false)}
                className="w-1/2"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (selectedTabIndex === 0 && category) {
                    setSelectedTabIndex(1);
                  } else if (selectedTabIndex === 1 && subCategory) {
                    setSelectedTabIndex(2);
                  } else if (selectedTabIndex === 2 && qualifier) {
                    setFetchingAnalysis(false);

                    if (element === "table") {
                      onTableToggle(
                        category!,
                        subCategory!,
                        qualifier!,
                        subQualifiers,
                      );
                    } else {
                      onChartToggle(
                        category!,
                        subCategory!,
                        qualifier!,
                        subQualifiers,
                      );
                    }

                    setCategory(undefined);
                    setSubCategory(undefined);
                    setQualifier(undefined);

                    setSelectedTabIndex(0);
                  }
                }}
                className="w-1/2"
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
