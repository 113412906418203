"use client";
import { Icons } from "@/components";
import React, { FC, useCallback, useEffect, useState } from "react";
import { EditMenu, StyleProperty } from "./EditMenu";

interface EditWrapperProps {
  isSelected?: boolean;
  className?: string;
  onClick?: (id?: string | number) => void;
  onChange?: (styleProperty: StyleProperty) => void;
  renderContent?: (props: Partial<any> & React.Attributes) => JSX.Element;
  id: string | number;
  value: StyleProperty;
}

export const EditWrapper: FC<EditWrapperProps> = (props) => {
  const { isSelected, onClick, onChange, renderContent, id, value } = props;
  const [childRef, setChildRef] = useState<HTMLElement>();
  const [editMenuRef, setEditMenuRef] = useState<HTMLDivElement | null>();

  const selectedStyle = {
    outline: "auto",
    outlineColor: "#0D99FF",
    position: "relative",
  };

  const getEditMenuRef = (ref: HTMLDivElement | null | undefined) => {
    setEditMenuRef(ref);
  };

  const renderIcons = () => {
    if (childRef)
      return (
        <React.Fragment key="icons">
          <Icons.Square
            className="absolute z-10"
            style={{
              top: "-2px",
              left: "-2px",
            }}
          />
          <Icons.Square
            className="absolute z-10"
            style={{
              bottom: "-2px",
              left: "-2px",
            }}
          />
          <Icons.Square
            className="absolute z-10"
            style={{
              top: "-2px",
              right: "-2px",
            }}
          />
          <Icons.Square
            className="absolute z-10"
            style={{
              bottom: "-2px",
              right: "-2px",
            }}
          />
        </React.Fragment>
      );
  };

  const renderEditMenu = () => {
    if (childRef)
      return (
        <EditMenu
          key="edit-menu"
          container={childRef}
          value={value}
          onChange={onChange}
          getEditMenuRef={getEditMenuRef}
        />
      );
  };

  const onClickHandler = useCallback(
    (ev: MouseEvent) => {
      ev.stopPropagation();

      onClick &&
        !editMenuRef?.contains(ev.target as HTMLElement) &&
        onClick(id);
    },
    [editMenuRef, id, onClick],
  );

  const onMouseDownHandler = useCallback(
    (ev: MouseEvent) => {
      console.log(childRef?.contains(ev.target as HTMLElement));
      !childRef?.contains(ev.target as HTMLElement) && onClick?.();
    },
    [childRef, onClick],
  );

  useEffect(() => {
    if (editMenuRef) {
      document.addEventListener("click", onMouseDownHandler);
    }
    return () => {
      document.removeEventListener("click", onMouseDownHandler);
    };
  }, [
    childRef,
    editMenuRef,
    id,
    isSelected,
    onClick,
    onClickHandler,
    onMouseDownHandler,
  ]);

  return (
    <>
      {renderContent?.({
        ref: (ref: HTMLElement) => setChildRef(ref),
        onClick: onClickHandler,
        style: { ...(isSelected ? selectedStyle : {}) },
        tabIndex: 0,
        children:
          isSelected && childRef ? [renderIcons(), renderEditMenu()] : [],
      })}
    </>
  );
};
