"use client";
import React, { FC, useCallback, useEffect, useId, useState } from "react";
import * as ReactDOM from "react-dom";

interface PopoverProps {
  className?: string;
  content: React.ReactNode;
  position?: "top" | "bottom" | "left" | "right";
  renderContent?: (props: Partial<any> & React.Attributes) => JSX.Element;
}

export const Popover: FC<PopoverProps> = (props) => {
  const { content, position = "top", renderContent } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [menuRef, setMenuRef] = useState<HTMLElement | null>();
  const [, setSize] = useState([0, 0]);
  const [childRef, setChildRef] = useState<HTMLElement>();
  const id = useId();

  useEffect(() => {
    function handleResize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const calculatePosition = () => {
    if (childRef && menuRef) {
      const isAvailableTopSpace =
        childRef.getBoundingClientRect().top -
          menuRef.getBoundingClientRect().height >
        20;

      const isAvailableRightSpace =
        childRef.getBoundingClientRect().right +
          menuRef.getBoundingClientRect().width <
        document.body.getBoundingClientRect().width - 20;

      const isAvailablePartialRightSpace =
        childRef.getBoundingClientRect().left +
          (childRef.getBoundingClientRect().right -
            childRef.getBoundingClientRect().left) /
            2 +
          menuRef.getBoundingClientRect().width <
        document.body.getBoundingClientRect().width - 20;

      switch (position) {
        case "top": {
          const top = isAvailableTopSpace
            ? childRef.getBoundingClientRect().top -
              menuRef.getBoundingClientRect().height
            : childRef.getBoundingClientRect().top;

          const left = isAvailablePartialRightSpace
            ? childRef.getBoundingClientRect().left +
              (childRef.getBoundingClientRect().right -
                childRef.getBoundingClientRect().left) /
                2
            : childRef.getBoundingClientRect().left -
              (childRef.getBoundingClientRect().right -
                childRef.getBoundingClientRect().left) /
                2;
          return {
            top,
            left,
          };
        }

        case "bottom": {
          const left = isAvailablePartialRightSpace
            ? childRef.getBoundingClientRect().left +
              (childRef.getBoundingClientRect().right -
                childRef.getBoundingClientRect().left) /
                2
            : childRef.getBoundingClientRect().left -
              (childRef.getBoundingClientRect().right -
                childRef.getBoundingClientRect().left) /
                2;
          return {
            top: `${childRef.getBoundingClientRect().bottom}px`,
            left,
          };
        }

        case "left": {
          return {
            top: `${childRef.getBoundingClientRect().top}px`,
            left: `${childRef.getBoundingClientRect().left - menuRef.getBoundingClientRect().width}px`,
          };
        }

        case "right": {
          const left = isAvailableRightSpace
            ? childRef.getBoundingClientRect().right +
              menuRef.getBoundingClientRect().width
            : childRef.getBoundingClientRect().left -
              menuRef.getBoundingClientRect().width;
          return {
            top: `${childRef.getBoundingClientRect().top}px`,
            left,
          };
        }
      }
    }
  };

  const renderEditMenu = () => {
    return (
      <div
        ref={(ref) => setMenuRef(ref)}
        className="absolute"
        style={{
          ...calculatePosition(),
        }}
      >
        {content}
      </div>
    );
  };

  const onMouseDownHandler = useCallback(
    (ev: MouseEvent) => {
      !menuRef?.contains(ev.target as HTMLElement) && setIsOpen(false);
    },
    [menuRef],
  );

  const onClickHandler = useCallback((ev: MouseEvent) => {
    ev.stopImmediatePropagation();

    setIsOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    const childComponent = childRef;
    if (childComponent) {
      childComponent.addEventListener("click", onClickHandler);
    }
    document.addEventListener("click", onMouseDownHandler);

    return () => {
      childComponent?.removeEventListener("click", onClickHandler);
      document.removeEventListener("click", onMouseDownHandler);
    };
  }, [childRef, id, onClickHandler, onMouseDownHandler]);

  return (
    <>
      {renderContent?.({
        ref: (ref: HTMLElement) => setChildRef(ref),
        id: id,
      })}
      {/* {React.cloneElement(children as ReactElement, {
        ref: childRef,
        id: id,
      })} */}
      {isOpen && ReactDOM.createPortal(renderEditMenu(), document.body)}
    </>
  );
};
