"use client";
import { DealListItem } from "./dealListItem";
import { NoItemsPlaceholder, CreateDealButton } from "@/components";
import type { Deal } from "../../../../../core/model/api";
// eslint-disable-next-line import/no-internal-modules
import { Filter, SortAscending } from "@carbon/react/icons";
import { useState } from "react";
import { Search, Button } from "@carbon/react";
import { ScrollWrapper } from "@/components";
import { CreateDealModal } from "..";

export function DealList({ deals }: { deals: Deal[] }) {
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className="h-full w-full flex flex-col flex-1">
        <div className="flex flex-col shrink-0">
          <CreateDealModal open={modalOpen} setOpen={setModalOpen} />
          <div className="flex flex-col items-start md:flex-row md:items-center justify-between">
            <SearchField value={search} setValue={setSearch} />
            <div className="md:w-auto">
              <Button
                kind="ghost"
                renderIcon={SortAscending}
                hasIconOnly
                fill="#8d8d8d"
                size="md"
                label="Sort"
                // className="icon-button-white"
                style={{
                  backgroundColor: "#ffffff",
                  borderBottom: "1px solid #8d8d8d",
                }} // Set background color to white and bottom border
                onClick={() => {}}
              />
            </div>
            <div className="md:w-auto">
              <Button
                kind="ghost"
                renderIcon={Filter}
                hasIconOnly
                size="md"
                label="Filter"
                style={{
                  backgroundColor: "#ffffff",
                  borderBottom: "1px solid #8d8d8d",
                  borderRight: "none",
                }}
                onClick={() => {}}
              />
            </div>
            <CreateDealButton
              onClick={() => {
                setModalOpen(true);
              }}
            />
          </div>
        </div>

        <ScrollWrapper withFade className="overflow-y-scroll flex-1">
          <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4 mt-4">
            {deals.length > 0 ? (
              deals
                .filter((deal) => {
                  if (search === "") {
                    return deal;
                  } else if (
                    deal.dealTitle.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return deal;
                  }
                })
                .map((deal, index) => <DealListItem key={index} deal={deal} />)
            ) : (
              <NoItemsPlaceholder
                title="No current deals"
                subtitle="Upload transaction data room"
                buttonText="New Deal"
                onClick={() => setModalOpen(true)}
              />
            )}
          </div>
        </ScrollWrapper>
      </div>
    </>
  );
}

const SearchField = ({
  value,
  setValue,
}: {
  value: string;
  setValue: (value: string) => void;
}) => {
  return (
    <Search
      size="md"
      placeholder="Search"
      labelText="Search"
      closeButtonLabelText="Clear search input"
      id="deal-search"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={() => {}}
      style={{ backgroundColor: "#ffffff" }}
    />
  );
};
