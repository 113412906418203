import { FC } from "react";

export const StatusSuccess: FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_344_3944)">
      <rect width="32" height="32" rx="16" fill="#DFF5BF" />
      <path
        d="M8.5 16.5L14.5 21.5L22.5 11.5"
        stroke="#139B23"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_344_3944">
        <rect width="32" height="32" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
