import { FC, SVGProps } from "react";

export const FileText: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...otherProps
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-Grey50 ${className || ""}`}
    {...otherProps}
  >
    <g opacity="0.8">
      <path d="M20.354 8.646L14.354 2.646C14.26 2.552 14.133 2.5 14 2.5H8C5.71 2.5 4.5 3.71 4.5 6V18C4.5 20.29 5.71 21.5 8 21.5H17C19.29 21.5 20.5 20.29 20.5 18V9C20.5 8.867 20.447 8.74 20.354 8.646ZM14.5 4.207L18.793 8.5H17C15.271 8.5 14.5 7.729 14.5 6V4.207ZM17 20.5H8C6.271 20.5 5.5 19.729 5.5 18V6C5.5 4.271 6.271 3.5 8 3.5H13.5V6C13.5 8.29 14.71 9.5 17 9.5H19.5V18C19.5 19.729 18.729 20.5 17 20.5ZM16.5 12C16.5 12.276 16.276 12.5 16 12.5H9C8.724 12.5 8.5 12.276 8.5 12C8.5 11.724 8.724 11.5 9 11.5H16C16.276 11.5 16.5 11.724 16.5 12ZM13.5 16C13.5 16.276 13.276 16.5 13 16.5H9C8.724 16.5 8.5 16.276 8.5 16C8.5 15.724 8.724 15.5 9 15.5H13C13.276 15.5 13.5 15.724 13.5 16Z" />
    </g>
  </svg>
);
