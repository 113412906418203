"use client";
import React, { FC, useEffect, useRef, useState } from "react";
import { SelectCallbackProps } from "./types";
import { Icons } from "..";

interface SelectProps {
  className?: string;
  options: { label: string; value: string }[];
  selectedOption?: string;
  onSelect: (props: SelectCallbackProps) => void;
}

export const Select: FC<SelectProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { className, options, onSelect, selectedOption } = props;

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        isOpen
      ) {
        toggleDropdown();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, wrapperRef]);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleOnSelect = (option: { label: string; value: string }) => () => {
    const { label, value } = option;
    onSelect({ label, value });
    toggleDropdown();
  };

  return (
    <div
      className={`relative text-sm text-DarkBlue ${className || ""}`}
      ref={wrapperRef}
    >
      <button
        className="relative block w-full text-left ps-3 pe-9 py-3.5 bg-Grey0 rounded shadow-sm border border-Grey30"
        onClick={toggleDropdown}
      >
        {selectedOption &&
          options.find((option) => option.value === selectedOption)?.label}
        <Icons.ChevronDown className="absolute top-0 bottom-0 right-3 my-auto" />
      </button>
      {isOpen && (
        <div className="absolute z-10 w-full bg-Grey0 border border-Grey30 rounded mt-1">
          <ul>
            {options.map((option) => (
              <li
                className="p-3 cursor-pointer hover:bg-Grey10"
                key={`${option.value}_${option.label}`}
                onClick={handleOnSelect(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
