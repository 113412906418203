import { FC, SVGProps } from "react";

export const ChevronDown: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...otherProps
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className || ""}`}
    {...otherProps}
  >
    <path
      d="M5 8L11.5757 14.5757C11.8101 14.8101 12.1899 14.8101 12.4243 14.5757L19 8"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
