"use client";
import { ChangeEvent, FC, useState, useRef } from "react";
import { Icons } from "@/components";
import { useRouter } from "next/navigation";
import { TextInput, Modal } from "@carbon/react";
// eslint-disable-next-line import/no-internal-modules
import { Add } from "@carbon/react/icons";
import { API_URL } from "@/constants";

export const CreateDealModal: FC<{
  open: boolean;
  setOpen: (value: boolean) => void;
}> = ({ open, setOpen }) => {
  const [file, setFile] = useState<FileList | null>();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const router = useRouter();
  const [dealName, setDealName] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the value of the file input
    }
  };

  const onClosePanel = () => {
    setDealName("");
    setFile(undefined);
    resetFileInput();
    setOpen(false);
  };

  const disabled = !dealName || !file;

  const onSubmit = () => {
    setIsloading(true);

    let bucketName: string;

    fetch(`${API_URL}/deal`, {
      method: "POST",
      body: JSON.stringify({
        dealTitle: dealName,
        vdrFilename: file?.[0].name,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        bucketName = res.bucketName;
        return fetch(res.url, {
          method: "PUT",
          body: file?.[0],
        });
      })
      .then(() => {
        setIsloading(false);
        setOpen(false);

        fetch(`${API_URL}/unzip`, {
          method: "POST",
          body: JSON.stringify({
            bucketName,
            fileName: file?.[0].name,
          }),
        });
      })
      .then(() => {
        router.refresh();
      })
      .catch((error) => {
        console.error(error);
        setIsloading(false);
        setOpen(false);
        router.refresh();
      });
  };

  return (
    <Modal
      open={open}
      modalHeading="Add a new deal"
      modalLabel="Project creation"
      primaryButtonText="Add Deal"
      secondaryButtonText="Cancel"
      onSecondarySubmit={onClosePanel}
      onRequestSubmit={onSubmit}
      primaryButtonDisabled={disabled}
      onRequestClose={onClosePanel}
    >
      {isLoading ? (
        <div className="flex items-center justify-center h-full w-full pt-12.5">
          <Icons.Spinner className="motion-safe:animate-spin" />
        </div>
      ) : (
        <>
          <div className="relative flex h-full">
            <form
              className="flex flex-col w-full gap-3"
              action="#"
              method="POST"
            >
              <TextInput
                data-modal-primary-focus
                id="text-input-1"
                labelText="Deal identifier"
                onChange={(e) => setDealName(e.target.value)}
                placeholder="e.g. Project Accelerate"
                style={{
                  marginBottom: "1rem",
                }}
              />

              <div className="pt-3">
                <label
                  htmlFor="documents"
                  className="block text-xs leading-6 text-grey40"
                >
                  Documents
                </label>
                <div className="flex items-center justify-center w-full relative">
                  <label
                    htmlFor="dropzone-file"
                    className={`w-full p-3 text-sm bg-Grey0 text-DarkBlue outline-1 cursor-pointer ${
                      file ? "file-border-dashed" : ""
                    }`}
                  >
                    <div className="flex justify-between items-center">
                      <span className="text-xs text Grey40">
                        {`${file ? file[0].name : "Data room or document upload"}`}
                      </span>
                      <Add />
                    </div>
                    <input
                      ref={fileInputRef}
                      id="dropzone-file"
                      type="file"
                      className="hidden text-sm"
                      onChange={(
                        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                      ) => {
                        const files = (e.target as HTMLInputElement).files;
                        setFile(files?.length ? files : undefined);
                      }}
                    />
                  </label>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </Modal>
  );
};
