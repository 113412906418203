import { FC, SVGProps } from "react";

export const Download: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...otherProps
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className || ""}`}
    {...otherProps}
  >
    <path
      d="M5 15V16.8C5 17.9201 5 18.4802 5.21799 18.908C5.40973 19.2843 5.71569 19.5903 6.09202 19.782C6.51984 20 7.07989 20 8.2 20H15.8C16.9201 20 17.4802 20 17.908 19.782C18.2843 19.5903 18.5903 19.2843 18.782 18.908C19 18.4802 19 17.9201 19 16.8V15"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0001 4.00005L17.0001 9.00005M12.0001 4.00005L7.00012 9.00005M12.0001 4.00005L12.0001 15"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
