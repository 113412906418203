import { FC, SVGProps } from "react";

export const Repeat: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...otherProps
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`stroke-DarkBlue fill-DarkBlue ${className || ""}`}
    {...otherProps}
  >
    <path
      d="M4 14L3.29289 13.2929L3 13.5858V14H4ZM13 5L13.7071 4.29289C13.3166 3.90237 12.6834 3.90237 12.2929 4.29289L13 5ZM18 10L18.7071 10.7071C19.0976 10.3166 19.0976 9.68342 18.7071 9.29289L18 10ZM9 19V20H9.41421L9.70711 19.7071L9 19ZM4 19H3C3 19.5523 3.44772 20 4 20V19ZM4.70711 14.7071L13.7071 5.70711L12.2929 4.29289L3.29289 13.2929L4.70711 14.7071ZM12.2929 5.70711L17.2929 10.7071L18.7071 9.29289L13.7071 4.29289L12.2929 5.70711ZM17.2929 9.29289L8.29289 18.2929L9.70711 19.7071L18.7071 10.7071L17.2929 9.29289ZM9 18H4V20H9V18ZM5 19V14H3V19H5ZM9.29289 8.70711L14.2929 13.7071L15.7071 12.2929L10.7071 7.29289L9.29289 8.70711Z"
      stroke="none"
    />
    <path d="M14 19H20" fill="none" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
