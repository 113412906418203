import { FC } from "react";

export const ItalicText: FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.26615 1.60156H13.8661M2.13281 14.4016H11.7328M9.06615 1.60156L6.93281 14.4016"
      stroke="#BCBCBC"
      strokeWidth="2"
    />
  </svg>
);
