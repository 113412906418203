"use client";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Button } from "@carbon/react";

interface onCardClickProps {
  hiddenContent?: ReactNode | null;
  isOpen: boolean;
  value?: string | Record<string, any>;
}

interface AccordionCardProps {
  title: string;
  description: string | ReactNode;
  className?: string;
  hiddenContent?: ReactNode | null;
  value?: string | Record<string, any>;
  isSelected?: boolean;
  onClick?: (props: onCardClickProps) => void;
}

export const AccordionCard = (props: AccordionCardProps) => {
  const { className, hiddenContent, onClick, value, isSelected } = props;
  const [isOpen, setIsOpen] = useState(isSelected);
  const contentHeight = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSelected !== undefined) {
      setIsOpen(isSelected);
    }
  }, [isSelected]);

  const openedStyle = "border-LCDBlue border-l-4";

  const toggleCard = () => {
    setIsOpen((prevState) => !prevState);
    onClick?.({ hiddenContent, isOpen: !isOpen, value });
  };

  return (
    <div
      className={`flex flex-col gap-2.5 py-3 pe-3 ps-5 text-DarkBlue border shadow-sm bg-Grey0  ${
        (isOpen && openedStyle) || ""
      } ${className}`}
    >
      <Button onClick={toggleCard} className="text-left"></Button>
      {hiddenContent && (
        <div
          ref={contentHeight}
          style={
            isOpen
              ? { height: contentHeight.current?.scrollHeight }
              : { height: "0px" }
          }
          className="transition-all duration-300 overflow-hidden"
        >
          {hiddenContent}
        </div>
      )}
    </div>
  );
};
