import { Entity, createSchema } from "electrodb";

const AnalysisRunSchema = createSchema({
  model: {
    entity: "analysisRun",
    service: "transacted-platform",
    version: "1",
  },
  attributes: {
    dealTitle: {
      type: "string",
      required: true,
    },
    dealId: {
      type: "string",
      required: true,
    },
    analysisRunId: {
      type: "string",
      required: true,
    },
    data: {
      type: "string",
      required: true,
    },
    completedAt: {
      type: "number",
      readOnly: true,
      set: () => Date.now(),
    },
    category: {
      type: "string",
      required: true,
    },
    subcategory: {
      type: "string",
      required: true,
    },
    qualifier: {
      type: "string",
      required: true,
    },
    subQualifiers: {
      type: "string",
    },
  },
  indexes: {
    primary: {
      pk: {
        field: "pk",
        composite: ["dealTitle", "dealId"],
      },

      sk: {
        field: "sk",
        composite: ["category", "subcategory", "qualifier", "subQualifiers"],
      },
    },
  },
});

export const AnalysisRun = new Entity(AnalysisRunSchema);
export type AnalysisRunSchemaType = typeof AnalysisRunSchema;
