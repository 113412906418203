import React from "react";

interface TextPillProps {
  text: string;
  className?: string;
}

export const TextPill = (props: TextPillProps) => {
  const { text, className } = props;
  return (
    <span
      className={`text-black font-normal text-sm rounded-full py-1 px-2 bg-GreyCold ${
        className || ""
      }`}
    >
      {text}
    </span>
  );
};
