"use client";
import { PageLayout } from ".";
import { DealList } from "..";
import { Button } from "@carbon/react";
// eslint-disable-next-line import/no-internal-modules
import { Add } from "@carbon/react/icons";
import { Deal } from "../../../../core/model/api";

export function DealsPageLayout({ deals }: { deals: Deal[] }) {
  return (
    <PageLayout breadcrumbs={[]} title={<div className="mb-2">Deals</div>}>
      <DealList deals={deals} />
    </PageLayout>
  );
}

export const CreateDealButton = ({ onClick }: { onClick: () => void }) => (
  <Button renderIcon={Add} size="md" onClick={onClick}>
    New Deal
  </Button>
);
