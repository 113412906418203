import { FC, SVGProps } from "react";

interface SpinnerProps extends SVGProps<SVGSVGElement> {
  secondaryColor?: string;
}

export const Spinner: FC<SpinnerProps> = ({
  className,
  secondaryColor,
  ...otherProps
}) => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`stroke-DarkBlue ${className || ""}`}
    {...otherProps}
  >
    <path
      d="M36 6V18"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36 54V66"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7891 14.7905L23.2791 23.2805"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.7202 48.72L57.2102 57.21"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 36H18"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54 36H66"
      stroke={secondaryColor ?? "#3E3B4D"}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7891 57.2107L23.2791 48.7207"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.7202 23.28L57.2102 14.79"
      stroke={secondaryColor ?? "#3E3B4D"}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
