"use client";
import React, { createContext, useContext, useRef } from "react";
import { ClerkProvider } from "@clerk/nextjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  theme as ChakraTheme,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import { SlidesStore, createSlidesStore, defaultSlidesStore } from "@/stores";
import { useStore, StoreApi } from "zustand";

const queryClient = new QueryClient();

const theme = extendTheme({
  components: {
    Select: ChakraTheme.components.Select,
  },
  styles: {
    global: {
      body: {
        bg: "Grey10",
      },
    },
  },
});

type Props = {
  children: React.ReactNode;
};

const SlidesStoreContext = createContext<StoreApi<SlidesStore> | undefined>(
  undefined,
);
export const useSlidesStore = <T,>(selector: (store: SlidesStore) => T): T => {
  const store = useContext(SlidesStoreContext);
  if (!store) {
    throw new Error("SlidesStoreContext not found");
  }

  return useStore(store, selector);
};

export const SlidesStoreProvider = ({ children }: Props) => {
  const storeRef = useRef<StoreApi<SlidesStore>>();
  if (!storeRef.current) {
    storeRef.current = createSlidesStore(defaultSlidesStore);
  }

  return (
    <SlidesStoreContext.Provider value={storeRef.current}>
      {children}
    </SlidesStoreContext.Provider>
  );
};

export function Providers({ children }: Props) {
  return (
    <ClerkProvider>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <SlidesStoreProvider>{children}</SlidesStoreProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </ClerkProvider>
  );
}
