import writeXlsxFile from "write-excel-file";
import { Slide } from "@/stores";

export async function exportToCsv(slides: Slide[]) {
  const schemas = [];
  const objectsToWrite = [];

  for (const slide of slides) {
    for (const table of slide.tables) {
      const tableData = JSON.parse(table.tableData);
      const annualData = tableData["Annual"];

      const schema = annualData.columns.map((col: string, index: number) => {
        return {
          column: col,
          type: index > 0 ? Number : String,
          value: (val: any) => val[col],
        };
      });

      schemas.push(schema);

      const objects = annualData.data.map((row: any) => {
        const object: any = {};

        for (let i = 0; i < annualData.columns.length; i++) {
          const col = annualData.columns[i];
          const value = row[i];

          object[col] = value;
        }

        return object;
      });

      const totalObject: any = {};

      for (let i = 0; i < annualData.columns.length; i++) {
        const col = annualData.columns[i];
        const value = annualData.total[i];

        totalObject[col] = value;
      }

      objects.push(totalObject);
      objectsToWrite.push(objects);
    }

    for (const chart of slide.charts) {
      const chartData = JSON.parse(chart.chartData);
      const annualData = chartData["Annual"];

      const schema = annualData.columns.map((col: string, index: number) => {
        return {
          column: col,
          type: index > 0 ? Number : String,
          value: (val: any) => val[col],
        };
      });

      schemas.push(schema);

      const objects = annualData.data.map((row: any) => {
        const object: any = {};

        for (let i = 0; i < annualData.columns.length; i++) {
          const col = annualData.columns[i];
          const value = row[i];

          object[col] = value;
        }

        return object;
      });

      const totalObject: any = {};

      for (let i = 0; i < annualData.columns.length; i++) {
        const col = annualData.columns[i];
        const value = annualData.total[i];

        totalObject[col] = value;
      }

      objects.push(totalObject);
      objectsToWrite.push(objects);
    }
  }

  await writeXlsxFile(objectsToWrite, {
    schema: schemas,
    sheets: schemas.map((_, index) => `Sheet ${index + 1}`),
    fileName: "analysisExport.xlsx",
  });
}
