import React, { FC, useId } from "react";
import { Icons } from "..";
import { TextInputOnChange } from "./types";

interface TextInputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "onChange"
  > {
  onChange?: (props: TextInputOnChange) => void;
  isSearch?: boolean;
  label?: string;
}
export const TextInput: FC<TextInputProps> = (props) => {
  const { className = "", onChange, isSearch, label, ...otherProps } = props;
  const id = useId();
  const searchStyle = "ps-9";

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value, name } = e.currentTarget;
    onChange?.({ value, name });
  };

  return (
    <div className={`relative rounded text-sm ${className}`}>
      {label && (
        <label
          htmlFor={`text-input-${id}`}
          className="mb-2 text-sm font-medium text-DarkBlue sr-only dark:text-Grey0"
        >
          {label}
        </label>
      )}
      <input
        id={`text-input-${id}`}
        type="text"
        className={`w-full p-3.5 text-DarkBlue shadow-sm rounded ${
          (isSearch && searchStyle) || ""
        }`}
        onChange={handleInputChange}
        {...otherProps}
      />
      {isSearch && (
        <Icons.Search className="absolute my-auto top-0 bottom-0 ms-3" />
      )}
    </div>
  );
};
