"use client";
import React, { FC, PropsWithChildren, useLayoutEffect, useRef } from "react";

interface ScrollWrapper {
  className?: string;
  withFade?: boolean;
}

export const ScrollWrapper: FC<PropsWithChildren<ScrollWrapper>> = (props) => {
  const { children, className = "", withFade } = props;
  const wrapper = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (withFade) {
      const element = wrapper.current;
      calculateFade();
      element?.addEventListener("scroll", calculateFade);

      return () => element?.removeEventListener("scroll", calculateFade);
    }
  }, [withFade]);

  const calculateFade = () => {
    const el = wrapper.current;
    if (el) {
      const isScrollable = el.scrollHeight > el.clientHeight;
      if (!isScrollable) {
        return;
      }

      const isScrolledToBottom =
        Math.abs(el.scrollHeight - el.clientHeight - el.scrollTop) <= 1;
      const isScrolledFromBottom = el.scrollTop > 0;

      if (isScrolledFromBottom && !isScrolledToBottom) {
        el.setAttribute(
          "style",
          "mask-image: linear-gradient(180deg, transparent 0%, #F6F6F6 22px, #F6F6F6 calc(100% - 22px), transparent 100%)",
        );
        return;
      }

      if (isScrolledToBottom) {
        el.setAttribute(
          "style",
          "mask-image: linear-gradient(to top, #F6F6F6 calc(100% - 22px), transparent 100%)",
        );
      } else {
        el.setAttribute(
          "style",
          "mask-image: linear-gradient(to bottom, #F6F6F6 calc(100% - 22px), transparent 100%)",
        );
      }
    }
  };

  return (
    <div
      ref={wrapper}
      className={`relative h-full w-full overflow-y-auto overflow-x-auto no-scrollbar flex-grow ${className}`}
    >
      {children}
    </div>
  );
};
