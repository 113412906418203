import { Entity, createSchema } from "electrodb";

const SlideMasterSchema = createSchema({
    model: {
        entity: "slideMaster",
        service: "transacted-platform",
        version: "1",
    },
    attributes: {
        title: {
            type: "string",
            required: true,
        },
        headerStyle: {
            type: "map",
            required: true,
            properties: {
                backgroundColor: { type: "string", required: true },
                fontFamily: { type: "string", required: true },
                fontSize: { type: "string", required: true },
                fontWeight: { type: "string", required: true },
                fontStyle: { type: "string", required: true },
            },
        },
        dataStyle: {
            type: "map",
            required: true,
            properties: {
                backgroundColor: { type: "string", required: true },
                fontFamily: { type: "string", required: true },
                fontSize: { type: "string", required: true },
                fontWeight: { type: "string", required: true },
                fontStyle: { type: "string", required: true },
            },
        },
        subTotalStyle: {
            type: "map",
            required: true,
            properties: {
                backgroundColor: { type: "string", required: true },
                fontFamily: { type: "string", required: true },
                fontSize: { type: "string", required: true },
                fontWeight: { type: "string", required: true },
                fontStyle: { type: "string", required: true },
            },
        },
        totalStyle: {
            type: "map",
            required: true,
            properties: {
                backgroundColor: { type: "string", required: true },
                fontFamily: { type: "string", required: true },
                fontSize: { type: "string", required: true },
                fontWeight: { type: "string", required: true },
                fontStyle: { type: "string", required: true },
            },
        },
    },
    indexes: {
        primary: {
            pk: {
                field: "pk",
                composite: ["title"],
            },
        },
    },
});

export const SlideMaster = new Entity(SlideMasterSchema);
export type SlideMasterSchemaType = typeof SlideMasterSchema;
