import { Entity, createSchema } from "electrodb";

const PromptSchema = createSchema({
  model: {
    entity: "prompt",
    service: "transacted-platform",
    version: "1",
  },
  attributes: {
    promptTitle: {
      type: "string",
    },
    promptText: {
      type: "string",
      required: true,
    },
    promptVersion: {
      type: "number",
    },
    examples: {
      type: "list",
      items: {
        type: "map",
        properties: {
          input: {
            type: "string",
          },
          output: {
            type: "string",
          },
        },
      },
    },
  },
  indexes: {
    primary: {
      pk: {
        field: "pk",
        composite: ["promptTitle"],
      },
      sk: {
        field: "sk",
        composite: ["promptVersion"],
      },
    },
  },
});

export type PromptSchemaType = typeof PromptSchema;
export const Prompt = new Entity(PromptSchema);
