import { TransactedPlatform } from "../../../core/model";
import { getDealCore } from "../../../core/api/deal";
import { getFilesCore } from "../../../core/api/vdr";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { API_URL } from "@/constants";

const s3Client = new S3Client({ region: "us-west-2" });
const ddb = new DynamoDBClient({ region: "us-west-2" });

TransactedPlatform.setClient(ddb);
TransactedPlatform.setTableName(process.env.DDB_TABLE_NAME!);

export async function fetchWithToken(
  url: string,
  token: string | undefined,
  options: RequestInit = {},
) {
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    ...options,
  });
}

async function apiWrapper<T>(mockAPI: string, realAPI: () => T) {
  if (process.env.NEXT_PUBLIC_API_URL === "http://localhost:3001") {
    console.log("Using mocked API:", mockAPI);
    return (await fetch(`${process.env.NEXT_PUBLIC_API_URL}/${mockAPI}`).then(
      (res) => res.json(),
    )) as T;
  }
  return realAPI();
}

export const getDeals = async () =>
  apiWrapper("deals", async () => {
    const { data } = await TransactedPlatform.entities.deal.scan.go();
    return data;
  });

export const getDeal = async (dealId: string) =>
  apiWrapper(`deal/${dealId}`, async () => {
    const response = await getDealCore({
      deal: dealId,
    });

    if (response === undefined) {
      throw new Error("Failed to fetch deal");
    }

    return response;
  });

export const getAvailableAnalyses = async (dealId: string) =>
  apiWrapper(`analysis/${dealId}`, async () => {
    const fetchOptions = new GetObjectCommand({
      Bucket: dealId,
      Key: "preparedOptions.json",
    });

    const { Body } = await s3Client.send(fetchOptions);
    const body = await Body?.transformToString();

    if (body === undefined) {
      throw new Error("Failed to fetch available analyses");
    }

    return JSON.parse(body);
  });

export const getAnalysisRuns = async (dealPathParameter: string) =>
  apiWrapper(`analysisRuns/${dealPathParameter}`, async () => {
    const splitDeal = dealPathParameter.split("--");
    const dealTitle = splitDeal[0].replaceAll(".", " ");
    const dealId = splitDeal[1];

    const { data } = await TransactedPlatform.entities.analysisRun.query
      .primary({
        dealTitle,
        dealId,
      })
      .go();

    const links = await Promise.all(
      data.map((r) =>
        getSignedUrl(
          s3Client,
          new GetObjectCommand({
            Bucket: dealPathParameter,
            Key: `analysis/${r.analysisRunId}.csv`,
          }),
        ),
      ),
    );

    const analysisRuns = data.map((analysisRun, index) => {
      return {
        ...analysisRun,
        downloadLink: links[index],
      };
    });

    return analysisRuns;
  });

export async function getSlideMasters(url: string, token: string | undefined) {
  const response = await fetchWithToken(`${url}/slideMasters`, token, {
    cache: "no-store",
  });
  if (!response.ok) {
    throw new Error("Failed to fetch slide masters.");
  }
  return await response.json();
}

export const getActiveExport = async (
  url: string,
  dealId: string,
  token: string | undefined,
) =>
  apiWrapper(`activeExport/${dealId}`, async () => {
    const response = await fetchWithToken(
      `${url}/activeExport/${dealId}`,
      token,
      {
        cache: "no-store",
      },
    );
    if (!response.ok) {
      throw new Error("Failed to fetch active export.");
    }
    return await response.json();
  });

export const getExports = async (dealPathParameter: string) =>
  apiWrapper(`exports/${dealPathParameter}`, async () => {
    const splitDeal = dealPathParameter.split("--");
    const dealTitle = splitDeal[0];
    const dealId = splitDeal[1];
    const { data } = await TransactedPlatform.entities.export.query
      .primary({
        dealId,
        dealTitle,
      })
      .go();

    return data;
  });

export const getVDRFiles = async (bucketName: string) =>
  apiWrapper(`vdr/files/${bucketName}`, async () => {
    return getFilesCore(s3Client, bucketName);
  });

export const getAnalysisExecution = async (
  dealId: string,
  category: string,
  subcategory: string,
  qualifier: string,
  subQualifiers: string[] | undefined,
) =>
  apiWrapper("analysisRun/deal/category/subcategory/qualifier", async () => {
    return fetch(
      `/api/${dealId}/${category}/${subcategory}/${qualifier}/${JSON.stringify(
        subQualifiers,
      )}`,
    ).then((data) => data.json());
  });

export const exportToPpt = async (
  dealId: string,
  images: {
    image: string;
    dimensions: { x: number; y: number; width: number; height: number };
  }[],
) =>
  apiWrapper(`ppt/${dealId}`, async () => {
    return fetch(`${API_URL}/ppt/${dealId}`, {
      method: "POST",
      body: JSON.stringify({
        images,
      }),
      cache: "no-store",
    }).then((data) => data.json());
  });

export const exportToDatabook = async (dealId: string) =>
  apiWrapper(`databook/${dealId}`, async () => {
    return fetch(`/api/${dealId}/databook`).then((data) => data.json());
  });
