export const COLORS = [
  "#111111",
  "#757575",
  "#E03E1A",
  "#F3C11B",
  "#198F51",
  "#0C8CE9",
  "#FFFFFF",
  "#B3B3B3",
  "#FFC7C2",
];

export const DEFAULT_COLOR = "#FFFFFF";

export const FONTS = [
  "Calibri",
  "Verdana",
  "Palatino",
  "Tahoma",
  "Aptos Narrow",
];

export const DEFAULT_FONT = "Calibri";

export const FONT_SIZES = [8, 10, 12, 14, 16, 18, 20, 22];

export const DEFAULT_FONT_SIZE = "Mixed sizes";
