import { FC, SVGProps } from "react";

export const Sort: FC<SVGProps<SVGSVGElement>> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className || ""}`}
  >
    <rect
      width="16"
      height="16"
      transform="translate(0 0.512695)"
      fill="white"
      fillOpacity="0.01"
      style={{ mixBlendMode: "multiply" }}
    />
    <path
      d="M14 7.51562H13.5V1.51562H12.5V7.51562H12C11.7349 7.51588 11.4807 7.62131 11.2932 7.8088C11.1057 7.99628 11.0003 8.25049 11 8.51562V9.51562C11.0003 9.78075 11.1058 10.0349 11.2932 10.2224C11.4807 10.4099 11.7349 10.5153 12 10.5156H12.5V15.5156H13.5V10.5156H14C14.2651 10.5153 14.5193 10.4098 14.7067 10.2223C14.8942 10.0349 14.9996 9.78073 15 9.51562V8.51562C14.9997 8.2505 14.8942 7.99633 14.7068 7.80885C14.5193 7.62138 14.2651 7.51593 14 7.51562ZM14 9.51562H12V8.51562H14V9.51562Z"
      fill="#161616"
    />
    <path
      d="M9 3.51562H8.5V1.51562H7.5V3.51562H7C6.73486 3.51588 6.48065 3.62131 6.29317 3.8088C6.10569 3.99628 6.00025 4.25049 6 4.51562V5.51562C6.00025 5.78076 6.10569 6.03497 6.29317 6.22245C6.48065 6.40994 6.73486 6.51537 7 6.51562H7.5V15.5156H8.5V6.51562H9C9.26513 6.51536 9.51934 6.40992 9.70681 6.22244C9.89429 6.03496 9.99973 5.78076 10 5.51562V4.51562C9.99973 4.25049 9.89429 3.99629 9.70681 3.80881C9.51934 3.62133 9.26513 3.51589 9 3.51562ZM9 5.51562H7V4.51562H9V5.51562Z"
      fill="#161616"
    />
    <path
      d="M4 10.5156H3.5V1.51562H2.5V10.5156H2C1.73488 10.5159 1.4807 10.6214 1.29323 10.8089C1.10576 10.9963 1.0003 11.2505 1 11.5156V12.5156C1.0003 12.7807 1.10576 13.0349 1.29323 13.2224C1.4807 13.4099 1.73488 13.5153 2 13.5156H2.5V15.5156H3.5V13.5156H4C4.26512 13.5153 4.5193 13.4099 4.70677 13.2224C4.89424 13.0349 4.9997 12.7807 5 12.5156V11.5156C4.9997 11.2505 4.89424 10.9963 4.70677 10.8089C4.5193 10.6214 4.26512 10.5159 4 10.5156ZM4 12.5156H2V11.5156H4V12.5156Z"
      fill="#161616"
    />
  </svg>
);
