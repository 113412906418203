"use client";
import React, { useEffect, useState } from "react";
import { EditableTableData } from "../../types";
import { EditWrapper, StyleProperty } from "../EditWrapper";
import { SlideMaster } from "../../../../../../../core/model";

interface TemplateTableProps {
  selectedSlideMaster: SlideMaster | undefined;
  data: EditableTableData;
  title?: string;
  edited: () => void;
}

const calculateBorderRadius = (
  isFirstRow: boolean,
  isFirstCell: boolean,
  isLastRow: boolean,
  isLastCell: boolean,
) => {
  if (isFirstRow && isFirstCell) {
    return "rounded-tl-xl";
  }

  if (isFirstRow && isLastCell) {
    return "rounded-tr-xl";
  }

  if (isLastRow && isFirstCell) {
    return "rounded-bl-xl";
  }

  if (isLastRow && isLastCell) {
    return "rounded-br-xl";
  }
};

export const TemplateTable = ({ data, title, edited }: TemplateTableProps) => {
  const [editableData, setEditableData] = useState<EditableTableData>(data);
  const [selectedRow, setSelectedRow] = useState<string | number | undefined>();

  useEffect(() => {
    setEditableData(data);
  }, [data]);

  const onEditWrapperClick = (id?: string | number) => {
    setSelectedRow((prevState) => (prevState === id ? undefined : id));
  };

  const handleStyleChange = (styleProperty: StyleProperty) => {
    if (selectedRow === "table") {
      setEditableData((prevState) => ({
        ...prevState,
        tableStyling: {
          ...prevState.tableStyling,
          ...styleProperty,
        },
      }));
    }
    setEditableData((prevState) => ({
      ...prevState,
      rows: prevState.rows.map((row, index) =>
        index === selectedRow
          ? { ...row, rowStyling: { ...row.rowStyling, ...styleProperty } }
          : row,
      ),
    }));

    edited();
  };

  return (
    <EditWrapper
      id="table"
      onClick={onEditWrapperClick}
      onChange={handleStyleChange}
      isSelected={selectedRow === "table"}
      value={editableData.tableStyling}
      renderContent={({ children: tableChildren, ...tableProps }) => (
        <div {...tableProps}>
          {tableChildren}
          {title && (
            <h3 className="text-DarkBlue font-medium text-xl py-4">{title}</h3>
          )}
          <div
            style={{ ...editableData.tableStyling }}
            className="table w-full text-DarkBlue border-separate border-spacing-0 rounded-xl text-sm"
          >
            <div className="table-row-group">
              {editableData.rows.map((rowsData, rowsDataIndex) => (
                <EditWrapper
                  id={rowsDataIndex}
                  key={rowsDataIndex}
                  onClick={onEditWrapperClick}
                  isSelected={selectedRow === rowsDataIndex}
                  onChange={handleStyleChange}
                  value={rowsData.rowStyling}
                  renderContent={({ children, ...props }) => (
                    <div className="border table-row" {...props}>
                      {rowsData.rowData.map((row, rowIndex) => (
                        <div
                          className={`border table-cell px-4 py-3 ${calculateBorderRadius(rowsDataIndex === 0, rowIndex === 0, rowsDataIndex === editableData.rows.length - 1, rowIndex === rowsData.rowData.length - 1)}`}
                          key={rowIndex}
                          style={{ ...rowsData.rowStyling }}
                        >
                          <div className="cell-block">{row}</div>
                        </div>
                      ))}
                      {children}
                    </div>
                  )}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    />
  );
};
