"use client";
import React, { useEffect, useState, useCallback } from "react";
import { ScrollWrapper } from "@/components";
import {
  GetActiveExportOutput,
  ParsedAnalysisData,
} from "../../../../../core/model/api";
// eslint-disable-next-line import/no-internal-modules
import { Add } from "@carbon/react/icons";
// eslint-disable-next-line import/no-internal-modules
import "@carbon/charts-react/styles.css";
import { ChartEditor, TableEditor } from "./components";
import { slideMasterDisplay } from "utils";
import interact from "interactjs";
// eslint-disable-next-line import/no-internal-modules
import "ag-grid-community/styles/ag-grid.css";
// eslint-disable-next-line import/no-internal-modules
import "ag-grid-community/styles/ag-theme-balham.css";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import { domToDataUrl, domToPng } from "modern-screenshot";
import { Target } from "@interactjs/core/types";
import { Grid } from "./grid";
import { useHotkeys } from "react-hotkeys-hook";
import { Chart } from "./chart";
import { ChartOptions } from "./types";
import { GetAvailableAnalysesOutput } from "../../../../../core/model/api/analysis";
import { getAnalysisExecution } from "@/api";
import { useParams } from "next/navigation";
import { useSlidesStore } from "@/providers";
import { Table, Chart as ChartType } from "@/stores";

interface Series {
  type: any;
  xKey: string;
  yKey: string;
  [key: string]: any;
}

interface Axis {
  type: "category" | "number";
  position?: "bottom" | "left" | "top" | "right";
  label?: any; // Define more specifically based on your usage
  min?: number;
  max?: number;
  gridline?: any;
  // Add other properties as needed
}

type Props = {
  activeExport: GetActiveExportOutput;
  triggerScreenshot: boolean;
  setTriggerScreenshot: (value: boolean) => void;
  activeEditor:
    | {
        type: "table" | "chart";
        id: number;
      }
    | undefined;
  setActiveEditor: (value: { type: "table" | "chart"; id: number }) => void;
  analyses: GetAvailableAnalysesOutput;
  getRef: any;
  setRef: any;
};

export const AnalysisRunScreen = ({
  analyses,
  triggerScreenshot,
  setTriggerScreenshot,
  activeEditor,
  setActiveEditor,
  getRef,
  setRef,
}: Props) => {
  const [activeRef, setActiveRef] = useState<string | undefined>();

  const {
    activeSlide: activeSlideStore,
    slides,
    setActiveSlide: updateActiveSlide,
    updateThumbnail,
    addSlide,
    addTable,
    addChart,
    removeChart,
    removeTable,
    updateTable,
    updateChart,
    updateDimensions,
  } = useSlidesStore((state) => state);

  const tables = slides[activeSlideStore].tables;
  const charts = slides[activeSlideStore].charts;

  const [gridApi, setGridApi] = useState<GridApi | undefined>();

  useHotkeys(
    "ctrl+s, meta+s",
    () => {
      const slideParent = document.querySelector("#slide-parent-div")!;
      domToDataUrl(slideParent).then((url) => {
        updateThumbnail(url);
      });
    },
    { preventDefault: true },
  );

  useHotkeys("delete", () => {
    if (activeRef !== undefined) {
      const isTable = activeRef.includes("table");
      const elementIndex = Number.parseInt(activeRef.split("-")[1]);

      if (isTable) {
        removeTable(elementIndex);
      } else {
        removeChart(elementIndex);
      }

      setActiveRef(undefined);
    }
  });

  useEffect(() => {
    updateActiveSlide(slides.length > 0 ? slides.length - 1 : 0);
  }, [slides.length, updateActiveSlide]);

  const handleSlideChange = (index: number) => {
    setActiveRef(undefined);
    updateActiveSlide(index);
  };

  const onAddSlideClick = () => {
    addSlide();
    setActiveRef(undefined);
  };

  const [qualifierLabel, setQualifierLabel] = useState<any>();
  // Table container inputs
  const [borderRadius, setBorderRadius] = useState<string>("0px");

  // Additional table CSS variable items
  const [backgroundColor, setBackgroundColor] = useState<any>();
  const [dataColor, setDataColor] = useState<any>();
  const [rowHoverColor, setRowHoverColor] = useState<any>("none");
  const [columnHoverColor, setColumnHoverColor] = useState<any>("none");
  const [rangeSelectionBorderColor, setRangeSelectionBorderColor] =
    useState<any>();
  const [rangeSelectionBorderStyle, setRangeSelectionBorderStyle] =
    useState<any>();
  const [rangeSelectionBackgroundColor, setRangeSelectionBackgroundColor] =
    useState<any>();

  const [bordersSecondary, setBordersSecondary] = useState<any>();
  const [secondaryBorderColor, setSecondaryBorderColor] = useState<any>();

  const [cellHorizontalBorder, setCellHorizontalBorder] = useState<any>();
  const [gridSize, setGridSize] = useState<any>();
  const [rowHeight, setRowHeight] = useState<any>("20px");
  const [headerHeight, setHeaderHeight] = useState<any>("20px");
  const [headerColumnSeparatorDisplay, setHeaderColumnSeparatorDisplay] =
    useState<any>("none");
  const [fontFamily, setFontFamily] = useState<any>();
  const [fontSize, setFontSize] = useState<any>("12px");

  // Table editor header
  const [tableHeader, setTableHeader] = useState<string>("Table header...");
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState("#03000F");
  const [headerTextColor, setHeaderTextColor] = useState("#ffffff");
  const [headerFontFamily, setHeaderFontFamily] =
    useState<string>("IBM Plex Sans");
  const [headerFontSize, setHeaderFontSize] = useState<string>("12px");
  const [headerTextStyle, setHeaderTextStyle] = useState<string>("bold");
  const [headerTextAlignment, setHeaderTextAlignment] =
    useState<string>("left");
  const [headerBorder, setHeaderBorder] = useState<any>(
    "border-bottom 1px black",
  );
  const [headerBorderColor, setHeaderBorderColor] = useState<any>();

  // Grid global styles
  const [gridGlobalBackgroundColor, setGridGlobalBackgroundColor] =
    useState<any>();
  const [globalFontFamily, setGlobalFontFamily] =
    useState<string>("IBM Plex Sans");
  const [globalFontSize, setGlobalFontSize] = useState<string>("12px");

  const [globalTextStyle, setGlobalTextStyle] = useState<string>("regular");

  const [globalBorder, setGlobalBorder] = useState<string>("none");
  const [globalBorderColor, setGlobalBorderColor] = useState<string>("#03000F");
  const [oddRowBackgroundColor, setOddRowBackgroundColor] =
    useState<string>("transparent");

  // Column labels

  const [columnLabelTextStyle, setColumnLabelTextStyle] =
    useState<string>("bold");
  const [columnLabelTextDecoration, setColumnLabelTextDecoration] =
    useState<string>("underline");
  const [columnLabelTextAlignment, setColumnLabelTextAlignment] =
    useState<string>("center");
  const [columnLabelColor, setColumnLabelColor] = useState<string>("#03000F");
  const [columnLabelFontSize, setColumnLabelFontSize] =
    useState<string>("12px");
  const [columnLabelBackgroundColor, setColumnLabelBackgroundColor] =
    useState<string>("transparent");

  const [totalRowColor, setTotalRowColor] = useState("black");

  // Table editor cell format
  const [cellFormat, setCellFormat] = useState<string>("Default");
  const [cellFormatTextStyle, setCellFormatTextStyle] =
    useState<string>("regular");
  const [cellFormatTextAlignment, setCellFormatTextAlignment] =
    useState<string>("left");
  const [cellFormatBorder, setCellFormatBorder] = useState<any>("none");
  const [cellFormatBorderColor, setCellFormatBorderColor] = useState<any>();
  const [cellFormatBackgroundColor, setCellFormatBackgroundColor] =
    useState<any>();

  const [cellFormatTextColor, setCellFormatTextColor] = useState<any>();

  // Table editor total & sum row format
  const [totalRowTextStyle, setTotalRowTextStyle] = useState<string>("bold");
  const [totalRowTextAlignment, setTotalRowTextAlignment] =
    useState<string>("left");
  const [totalRowBackgroundColor, setTotalRowBackgroundColor] =
    useState<string>("#E5EEFF");
  const [totalRowFontFamily, setTotalRowFontFamily] = useState<any>();
  const [totalRowFontSize, setTotalRowFontSize] = useState<any>();

  const [totalRowTextDecoration, setTotalRowTextDecoration] = useState<any>();
  const [subTotalRowTextStyle, setSubTotalRowTextStyle] = useState<any>("bold");
  const [subTotalRowTextAlignment, setSubTotalRowTextAlignment] =
    useState<any>();
  const [subTotalRowBorder, setSubTotalRowBorder] = useState<any>();
  const [subTotalRowBorderTop, setSubTotalRowBorderTop] =
    useState<any>("solid 1px");
  const [subTotalRowBorderBottom, setSubTotalRowBorderBottom] = useState<any>();
  const [subTotalRowBorderColor, setSubTotalRowBorderColor] = useState<any>();
  const [subTotalRowBackgroundColor, setSubTotalRowBackgroundColor] =
    useState<any>();
  const [subTotalRowTextDecoration, setSubTotalRowTextDecoration] =
    useState<any>();
  const [subTotalRowColor, setSubTotalRowColor] = useState<any>();
  const [subTotalRowFontSize, setSubTotalRowFontSize] = useState<any>();

  // Chart editor chart area
  const [chartBackgroundColor, setChartBackgroundColor] = useState<any>("red");
  const [isChartBackgroundTransparent, setIsChartBackgroundTransparent] =
    useState<any>(true);
  const [chartBorder, setChartBorder] = useState<any>("none");
  const [chartBorderColor, setChartBorderColor] = useState<any>("#03000F");
  const [isAxisGridLines, setIsAxisGridLines] = useState<any>(false);
  const [chartType, setChartType] = useState<any>("bar");
  const [stacked, setStacked] = useState<boolean>(false);

  const handleChartTypeChange = (newType: string, stacked: boolean) => {
    setChartType(newType);
    setStacked(stacked);
    updateChartOptions({ type: newType, stacked });
  };

  const [chartOptions, setChartOptions] = useState<ChartOptions>({
    resizable: true,
    legend: { position: "bottom", alignment: "center" },
    autoSizeColumns: ["firstColumn"],
    type: chartType,
    theme: {
      baseTheme: "ag-default",
      palette: {
        fills: [
          "#03000F",
          "#ADC9FF",
          "#0000FF",
          "#E5EEFF",
          "#03000F",
          "#D30018",
        ],
        strokes: ["black"],
      },
    },
    background: {
      fill: isChartBackgroundTransparent ? "transparent" : chartBackgroundColor,
      border: {
        width: chartBorder === "none" ? 0 : 1,
        color: chartBorderColor,
      },
    },
  });

  const updateChartOptions = useCallback(
    (options: ChartOptions) => {
      setChartOptions((prevOptions) => {
        let updatedOptions: ChartOptions = { ...prevOptions };

        // Handle axis switching
        if (options.axisSwitched) {
          updatedOptions.series = (updatedOptions.series || []).map(
            (series) => ({
              ...series,
              xKey: series.yKey,
              yKey: series.xKey,
              innerRadiusRatio: 0.7,
              calloutLabelKey: series.yKey,
              angleKey: series.xKey,
              stacked: options.stacked ?? series.stacked,
            }),
          );
        }

        // Handle chart type change
        if (options.type) {
          updatedOptions.series = (updatedOptions.series || []).map(
            (series) => ({
              ...series,
              type: (options.type as Series["type"]) || series.type,
              innerRadiusRatio: 0.7,
              calloutLabelKey: series.yKey,
              angleKey: series.xKey,
              stacked: options.stacked ?? series.stacked,
            }),
          );
        }

        // Handle data filter
        if (options.data) {
          updatedOptions.data = options.data;
        }

        if (options.type) {
          updatedOptions.series = (updatedOptions.series || []).map(
            (series) => ({
              ...series,
              type: (options.type as Series["type"]) || series.type,
              stacked: options.stacked ?? series.stacked,
              innerRadiusRatio: 0.7,
              calloutLabelKey: series.yKey,
              angleKey: series.xKey,
            }),
          );
        }

        // Update axes label options
        updatedOptions.axes = updatedOptions.axes
          ? updatedOptions.axes.map((axis: Axis) => {
              if (axis.type === "category") {
                return {
                  ...axis,
                  label: {
                    ...axis.label,
                    ...options.xAxisLabel,
                  },
                  gridLine: isAxisGridLines
                    ? {
                        style: [
                          {
                            stroke: "transparent",
                            lineDash: [],
                          },
                        ],
                      }
                    : {
                        style: [
                          {
                            stroke: "transparent",
                            lineDash: [],
                          },
                        ],
                      },
                };
              } else if (axis.type === "number") {
                return {
                  ...axis,
                  label: {
                    ...axis.label,
                    ...options.yAxisLabel,
                  },
                  min: options.yAxisMin ?? axis.min ?? 0,
                  max: options.yAxisMax ?? axis.max,
                  gridLine: isAxisGridLines
                    ? {
                        style: [
                          {
                            stroke: "rgba(219, 219, 219, 1)",
                            lineDash: [],
                          },
                        ],
                      }
                    : {
                        style: [
                          {
                            stroke: "transparent",
                            lineDash: [],
                          },
                        ],
                      },
                };
              }
              return axis;
            })
          : [
              {
                type: "category",
                position: "bottom",
                label: options.xAxisLabel,
                gridLine: isAxisGridLines
                  ? {
                      style: [
                        {
                          stroke: "transparent",
                          lineDash: [],
                        },
                      ],
                    }
                  : {
                      style: [
                        {
                          stroke: "transparent",
                          lineDash: [],
                        },
                      ],
                    },
              },
              {
                type: "number",
                position: "left",
                label: options.yAxisLabel,
                min: options.yAxisMin ?? 0,
                max: options.yAxisMax,
                gridLine: isAxisGridLines
                  ? {
                      style: [
                        {
                          stroke: "rgba(219, 219, 219, 1)",
                          lineDash: [],
                        },
                      ],
                    }
                  : {
                      style: [
                        {
                          stroke: "transparent",
                          lineDash: [],
                        },
                      ],
                    },
              },
            ];

        // Update chart background options
        updatedOptions.background = {
          ...updatedOptions.background,
          fill: isChartBackgroundTransparent
            ? "transparent"
            : chartBackgroundColor,
          border: {
            width: chartBorder === "none" ? 0 : 1,
            color: chartBorderColor,
          },
        };

        return updatedOptions;
      });
    },
    [
      isAxisGridLines,
      isChartBackgroundTransparent,
      chartBackgroundColor,
      chartBorder,
      chartBorderColor,
    ],
  );

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleChartScreenshotTest = async () => {
    const chartElement = document.querySelector(
      "div[data-ag-charts] .ag-chart-wrapper",
    );
    if (chartElement) {
      try {
        const dataUrlTest = await domToPng(chartElement);

        // Store dataUrlTest in localStorage
        localStorage.setItem("chartScreenshot", dataUrlTest);
      } catch (error) {
        console.error("Error capturing screenshot:", error);
      }
    }
  };

  useEffect(() => {
    if (triggerScreenshot) {
      handleChartScreenshotTest();
      setTriggerScreenshot(false);
    }
  }, [triggerScreenshot, setTriggerScreenshot]);

  const params = useParams<{ dealId: string }>();

  const onTableToggle = (
    category: string,
    subCategory: string,
    qualifier: string,
    subQualifiers: string[],
  ) => {
    getAnalysisExecution(
      params.dealId,
      category,
      subCategory,
      qualifier,
      subQualifiers,
    ).then((data) => {
      const parsedData: ParsedAnalysisData = JSON.parse(data.data);
      const years = parsedData.Annual.columns
        .filter((c: any) => c.startsWith("Annual"))
        .map((c: any) => {
          return new Date(Date.parse(c.split("-")[1].trim())).getFullYear();
        });

      const newTable: Table = {
        dimensions: {
          xOffset: 0,
          yOffset: 0,
          width: 600,
        },
        tableData: data.data,
        tableYears: years,
        filter: {
          type: "Annual",
          start: new Date(years[0]!, 0, 1)!,
          end: new Date(years.at(-1)!, 11, 31)!,
          rowCount: 10,
        },
      };

      addTable(newTable);

      setActiveEditor({
        type: "table",
        id: tables.length,
      });
    });
  };

  useEffect(() => {
    const slideParent = document.querySelector("#slide-parent-div")!;
    domToDataUrl(slideParent).then((url) => {
      updateThumbnail(url);
    });
  }, [slides.length, updateThumbnail]);

  useEffect(() => {
    const slideParent = document.querySelector("#slide-parent-div")!;
    domToDataUrl(slideParent).then((url) => {
      updateThumbnail(url);
    });
  }, [tables, updateThumbnail]);

  useEffect(() => {
    const slideParent = document.querySelector("#slide-parent-div")!;
    domToDataUrl(slideParent).then((url) => {
      updateThumbnail(url);
    });
  }, [charts, updateThumbnail]);

  const onTableClick = (index: number) => {
    setActiveRef((prev) => {
      if (prev !== undefined && prev !== `table-${index}`) {
        const previous = getRef(prev);
        if (previous && "current" in previous) {
          interact(previous.current as unknown as Target).unset();
        }
      }

      return `table-${index}`;
    });
    setActiveEditor({ type: "table", id: index });
  };

  const onChartClick = (index: number) => {
    setActiveRef((prev) => {
      if (prev !== undefined && prev !== `chart-${index}`) {
        const previous = getRef(prev);
        if (previous && "current" in previous) {
          interact(previous.current as unknown as Target).unset();
        }
      }

      return `chart-${index}`;
    });
    setActiveEditor({ type: "chart", id: index });
  };

  const onChartToggle = (
    category: string,
    subCategory: string,
    qualifier: string,
    subQualifiers: string[],
  ) => {
    getAnalysisExecution(
      params.dealId,
      category,
      subCategory,
      qualifier,
      subQualifiers,
    ).then((data) => {
      const parsedData: ParsedAnalysisData = JSON.parse(data.data);
      const years = parsedData.Annual.columns
        .filter((c) => c.startsWith("Annual"))
        .map((c) => {
          return new Date(Date.parse(c.split("-")[1].trim())).getFullYear();
        });

      const newChart: ChartType = {
        dimensions: {
          xOffset: 0,
          yOffset: 0,
          width: 300,
          height: 300,
        },
        chartData: data.data,
        chartYears: years,
        filter: {
          type: "Annual",
          start: new Date(years[0]!, 0, 1)!,
          end: new Date(years.at(-1)!, 11, 31)!,
          rowCount: 10,
        },
      };

      addChart(newChart);

      setActiveEditor({
        type: "chart",
        id: slides[activeSlideStore].charts.length,
      });
    });
  };

  useEffect(() => {
    if (activeRef !== undefined) {
      const refElement = getRef(activeRef);
      const isTable = activeRef.includes("table");
      const elementIndex = Number.parseInt(activeRef.split("-")[1]);
      if (refElement && "current" in refElement) {
        interact(refElement.current as unknown as Target)
          .resizable({
            edges: { left: true, right: true, bottom: !isTable, top: !isTable },
            modifiers: [
              interact.modifiers.restrictEdges({
                outer: "parent",
              }),
              interact.modifiers.restrictSize({
                min: { width: 100, height: 50 },
              }),
            ],
            inertia: true,
          })
          .draggable({
            inertia: true,
            modifiers: [
              interact.modifiers.restrictRect({
                restriction: "parent",
                endOnly: true,
              }),
            ],
            autoScroll: true,
          })
          .on("resizeend", (event) => {
            const width = event.rect.width;
            const height = event.rect.height;

            updateDimensions(isTable, elementIndex, { width, height });

            gridApi?.sizeColumnsToFit();
          })
          .on("dragend", (event) => {
            const x = Number.parseFloat(event.target.dataset.x ?? 0);
            const y = Number.parseFloat(event.target.dataset.y ?? 0);

            updateDimensions(isTable, elementIndex, { xOffset: x, yOffset: y });
          })
          .on("dragmove", (event) => {
            const target = event.target as HTMLElement;
            const x = Number.parseFloat(target.dataset.x || "0") + event.dx;
            const y = Number.parseFloat(target.dataset.y || "0") + event.dy;
            target.style.transform = `translate(${x}px, ${y}px)`;
            target.dataset.x = x.toString();
            target.dataset.y = y.toString();

            gridApi?.sizeColumnsToFit();
          })
          .on("resizemove", (event) => {
            let { x, y } = event.target.dataset;
            x = (Number.parseFloat(x) || 0) + event.deltaRect.left;
            y = (Number.parseFloat(y) || 0) + event.deltaRect.top;

            Object.assign(event.target.style, {
              width: `${event.rect.width}px`,
              height: `${event.rect.height}px`,
              transform: `translate(${x}px, ${y}px)`,
            });
            Object.assign(event.target.dataset, { x, y });
          });
      }
    }
  }, [activeRef, getRef, gridApi, updateDimensions]);

  const dynamicStyles = `
  .ag-theme-balham .ag-header-cell .ag-header {

    font-style: ${columnLabelTextStyle?.includes("italic") ? "italic" : "normal"};
    font-weight: ${columnLabelTextStyle?.includes("bold") ? "bold" : "normal"};
    text-decoration: ${columnLabelTextDecoration?.includes("underline") ? "underline" : "none"};
  }

  
  .ag-theme-balham .ag-header-cell.ag-column-first,
  .ag-theme-balham .ag-header-cell.ag-column-first .ag-header-cell-label {
    justify-content: left !important;
  }

  /* Apply dynamic alignment to all but the first column */
  .ag-theme-balham .ag-header-cell:not(.ag-column-first) .ag-header-cell-label {
    justify-content: ${columnLabelTextAlignment};
  }


 

 
  .ag-theme-balham .ag-row.ag-row-last {
    background-color: ${totalRowBackgroundColor};
    color: ${totalRowColor};
  }

  .ag-theme-balham {
    --ag-font-size: ${globalFontSize};
    --ag-background-color: ${backgroundColor};
    --ag-borders: ${globalBorder};
    --ag-border-color: ${globalBorderColor};
    --ag-header-foreground-color: ${columnLabelColor};
    --ag-header-background-color: ${columnLabelBackgroundColor};
    --ag-odd-row-background-color: ${oddRowBackgroundColor};
    --ag-cell-horizontal-border: ${cellHorizontalBorder};
    --ag-secondary-border-color: ${secondaryBorderColor};
    --ag-row-border-color: ${cellFormatBorderColor};
    --ag-row-border-style: ${cellFormatBorder};
    --ag-grid-size: ${gridSize};
    --ag-row-height: ${rowHeight};
    --ag-header-height: ${headerHeight};
    --ag-font-family: ${fontFamily};
  }
`;

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi]);

  return (
    <>
      <style>{dynamicStyles}</style>
      <div className="flex flex-row flex-1 bg-slideBackground">
        <div className="w-36 bg-white flex flex-col">
          <div className="flex flex-col flex-1 pt-5 mr-2">
            {/* Left content */}
            <ScrollWrapper>
              {/* Slide thumbnails */}
              <div className="space-y-3">
                {slides.map((slide, index) => {
                  return (
                    <div
                      key={`slide-${index}`}
                      className={`w-full ${activeSlideStore === index ? "" : ""}`}
                    >
                      <div className="flex items-center ">
                        <div
                          className={`w-4 h-4 flex items-center justify-center text-gray-600 text-xs ${
                            activeSlideStore === index ? "text-white" : ""
                          }`}
                          style={{
                            borderLeft:
                              activeSlideStore === index
                                ? "2px solid #78a9ff"
                                : "",
                            color:
                              activeSlideStore === index
                                ? "#78a9ff"
                                : "#a2a9b0",
                          }}
                          onMouseEnter={(e) => {
                            if (activeSlideStore !== index) {
                              e.currentTarget.style.borderLeft =
                                "2px solid #dde1e6";
                            }
                          }}
                          onMouseLeave={(e) => {
                            if (activeSlideStore !== index) {
                              e.currentTarget.style.borderLeft = "";
                            }
                          }}
                        >
                          {index + 1}
                        </div>
                        <div
                          className="h-24 bg-white hover:cursor-pointer ml-1"
                          style={{
                            position: "relative",
                            border:
                              activeSlideStore === index
                                ? "2px solid #78a9ff"
                                : "1px solid #dde1e6",
                          }}
                          onMouseEnter={(e) => {
                            if (activeSlideStore !== index) {
                              e.currentTarget.style.border =
                                "2px solid #dde1e6";
                              const indexElement =
                                e.currentTarget.previousSibling;
                              if (indexElement) {
                                (indexElement as HTMLElement).style.borderLeft =
                                  "2px solid #dde1e6";
                              }
                            }
                          }}
                          onMouseLeave={(e) => {
                            if (activeSlideStore !== index) {
                              e.currentTarget.style.border =
                                "1px solid #dde1e6";
                              const indexElement =
                                e.currentTarget.previousSibling;
                              if (indexElement) {
                                (indexElement as HTMLElement).style.borderLeft =
                                  "";
                              }
                            }
                          }}
                          onClick={() => handleSlideChange(index)}
                        >
                          <img
                            alt="Slide Background"
                            src={slide.thumbnail}
                            className="w-full h-full object-cover"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="flex items-center mr-1 w-full">
                  <div className="w-4 h-4 flex items-center justify-center text-gray-600 text-xs"></div>

                  <div
                    className="h-24 w-32 bg-white border-dashed border border-gray-400 hover:border-blue-600 hover:bg-blue-100 hover:cursor-pointer flex items-center justify-center ml-1"
                    style={{ position: "relative" }}
                    onClick={onAddSlideClick}
                  >
                    <span className="text-sm text-gray-400">Add Slide</span>
                    <Add className="ml-2" fill="#9CA3AF" />
                  </div>
                </div>
              </div>
            </ScrollWrapper>
          </div>
        </div>
        <div className="flex flex-row bg-slideBackground">
          {/* Left spacer */}
        </div>
        <div className="flex flex-col flex-1 items-center justify-center">
          <div className="flex flex-col"></div>

          <div
            className="mt-5 mb-5 ml-5 mr-5"
            id={"slide-parent-div"}
            style={{
              position: "relative",
              width: "100%",
              maxWidth: "100vh",
              maxHeight: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className=" aspect-ratio-container"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {slideMasterDisplay}
              </div>
            </div>
            <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
              {tables.map((table: Table, index) => {
                return (
                  <>
                    <style>{dynamicStyles}</style>
                    <Grid
                      className="ag-theme-balham"
                      style={{
                        position: "absolute",
                        zIndex:
                          activeEditor?.type === "table" &&
                          activeEditor?.id === index
                            ? 2
                            : 1,
                      }}
                      width={table.dimensions.width}
                      height={table.dimensions.height}
                      x={table.dimensions.xOffset ?? 0}
                      y={table.dimensions.yOffset ?? 0}
                      onClick={onTableClick}
                      gridWrapperRef={setRef(`table-${index}`)}
                      index={index}
                      key={`table-${index}`}
                      data={JSON.parse(table.tableData)}
                      onGridReady={onGridReady}
                      active={
                        activeEditor !== undefined &&
                        activeEditor.type === "table" &&
                        activeEditor.id === index
                      }
                      // Global grid styles
                      qualifierLabel={qualifierLabel}
                      onQualifierLabelChange={setQualifierLabel}
                      gridGlobalBackgroundColor={gridGlobalBackgroundColor}
                      onGridGlobalBackgroundColorChange={
                        setGridGlobalBackgroundColor
                      }
                      globalFontFamily={globalFontFamily}
                      onGlobalFontFamilyChange={setGlobalFontFamily}
                      globalFontSize={globalFontSize}
                      onGlobalFontSizeChange={setGlobalFontSize}
                      globalTextStyle={globalTextStyle}
                      onGlobalTextStyleChange={setGlobalTextStyle}
                      globalBorder={globalBorder}
                      onGlobalBorderChange={setGlobalBorder}
                      globalBorderColor={globalBorderColor}
                      onGlobalBorderColorChange={setGlobalBorderColor}
                      oddRowBackgroundColor={oddRowBackgroundColor}
                      onOddRowBackgroundColorChange={setOddRowBackgroundColor}
                      // Column labels
                      columnLabelTextDecoration={columnLabelTextDecoration}
                      columnLabelTextStyle={columnLabelTextStyle}
                      columnLabelTextAlignment={columnLabelTextAlignment}
                      columnLabelBackgroundColor={columnLabelBackgroundColor}
                      columnLabelColor={columnLabelColor}
                      // Header
                      tableHeader={tableHeader}
                      onTableHeaderChange={setTableHeader}
                      headerBackgroundColor={headerBackgroundColor}
                      headerTextColor={headerTextColor}
                      headerFontSize={headerFontSize}
                      headerFontFamily={headerFontFamily}
                      headerTextStyle={headerTextStyle}
                      headerTextAlignment={headerTextAlignment}
                      setActiveEditor={setActiveEditor}
                      headerColumnSeparatorDisplay={
                        headerColumnSeparatorDisplay
                      }
                      headerHeight={headerHeight}
                      // Cell format
                      cellFormat={cellFormat}
                      cellFormatTextStyle={cellFormatTextStyle}
                      cellFormatTextAlignment={cellFormatTextAlignment}
                      cellFormatBorder={cellFormatBorder}
                      cellFormatBorderColor={cellFormatBorderColor}
                      cellFormatBackgroundColor={cellFormatBackgroundColor}
                      cellFormatTextColor={cellFormatTextColor}
                      // Row
                      totalRowTextStyle={totalRowTextStyle}
                      totalRowTextAlignment={totalRowTextAlignment}
                      totalRowBackgroundColor={totalRowBackgroundColor}
                      totalRowFontFamily={totalRowFontFamily}
                      totalRowFontSize={totalRowFontSize}
                      totalRowColor={totalRowColor}
                      subTotalRowTextStyle={subTotalRowTextStyle}
                      subTotalRowTextAlignment={subTotalRowTextAlignment}
                      subTotalRowBorder={subTotalRowBorder}
                      subTotalRowBorderTop={subTotalRowBorderTop}
                      subTotalRowBorderBottom={subTotalRowBorderBottom}
                      subTotalRowBorderColor={subTotalRowBorderColor}
                      subTotalRowBackgroundColor={subTotalRowBackgroundColor}
                      subTotalRowColor={subTotalRowColor}
                      rowHeight={rowHeight}
                      table={table}
                      updateTable={(newTable: Table) =>
                        updateTable(index, newTable)
                      }
                    />
                  </>
                );
              })}
              {charts.map((chart: ChartType, index) => {
                return (
                  <Chart
                    style={{
                      position: "absolute",
                      zIndex:
                        activeEditor?.type === "table" &&
                        activeEditor?.id === index
                          ? 2
                          : 1,
                    }}
                    width={chart.dimensions.width ?? 300}
                    height={chart.dimensions.height ?? 300}
                    x={chart.dimensions.xOffset ?? 0}
                    y={chart.dimensions.yOffset ?? 0}
                    key={`chart-${index}`}
                    chartWrapperRef={setRef(`chart-${index}`)}
                    active={
                      activeEditor !== undefined &&
                      activeEditor.type === "chart" &&
                      activeEditor.id === index
                    }
                    onClick={onChartClick}
                    index={index}
                    data={JSON.parse(chart.chartData)}
                    chartOptions={chartOptions}
                    chartType={chartType}
                    stacked={stacked}
                    chart={chart}
                    updateChart={(updatedChart) =>
                      updateChart(index, updatedChart)
                    }
                  />
                );
              })}
            </div>
          </div>
          <div className="flex flex-col"></div>
        </div>
        <div className="flex flex-row bg-slideBackground ">
          {/* Right spacer */}
        </div>
        <div
          className="w-72 flex flex-col bg-[#f4f4f4]"
          style={{ borderLeft: "1px solid #e8e8e8" }}
        >
          {/* Right content */}
          <div className="flex flex-col flex-1">
            {activeEditor !== undefined && activeEditor.type === "chart" && (
              <ChartEditor
                analyses={analyses}
                chartType={chartOptions.series?.[0]?.type || "bar"} // Fallback to 'bar' if undefined
                axisSwitched={false}
                onUpdateChartOptions={updateChartOptions}
                onChartToggle={onChartToggle}
                onTableToggle={onTableToggle}
                chartBackgroundColor={chartBackgroundColor}
                onChartBackgroundColorChange={setChartBackgroundColor}
                isChartBackgroundTransparent={isChartBackgroundTransparent}
                onChartBackgroundTransparentChange={
                  setIsChartBackgroundTransparent
                }
                chartBorder={chartBorder}
                onChartBorderChange={setChartBorder}
                chartBorderColor={chartBorderColor}
                onChartBorderColorChange={setChartBorderColor}
                isAxisGridLines={isAxisGridLines}
                onAxisGridLinesChange={setIsAxisGridLines}
                chartOptions={{ ...chartOptions, stacked }}
                onChartTypeChange={handleChartTypeChange}
                stacked={stacked}
              />
            )}
            {activeEditor !== undefined && activeEditor.type === "table" && (
              <TableEditor
                analyses={analyses}
                qualifierLabel={qualifierLabel}
                onQualifierLabelChange={setQualifierLabel}
                onTableToggle={onTableToggle}
                onChartToggle={onChartToggle}
                columnLabelTextDecoration={columnLabelTextDecoration}
                //Grid global styles
                gridGlobalBackgroundColor={gridGlobalBackgroundColor}
                onGridGlobalBackgroundColorChange={setGridGlobalBackgroundColor}
                globalFontFamily={globalFontFamily}
                onGlobalFontFamilyChange={setGlobalFontFamily}
                globalFontSize={globalFontSize}
                onGlobalFontSizeChange={setGlobalFontSize}
                globalTextStyle={globalTextStyle}
                onGlobalTextStyleChange={setGlobalTextStyle}
                globalBorder={globalBorder}
                onGlobalBorderChange={setGlobalBorder}
                globalBorderColor={globalBorderColor}
                onGlobalBorderColorChange={setGlobalBorderColor}
                oddRowBackgroundColor={oddRowBackgroundColor}
                onOddRowBackgroundColorChange={setOddRowBackgroundColor}
                // Column labels
                onColumnLabelTextDecorationChange={setColumnLabelTextDecoration}
                onColumnLabelTextStyleChange={setColumnLabelTextStyle}
                onColumnLabelTextAlignmentChange={setColumnLabelTextAlignment}
                columnLabelBackgroundColor={columnLabelBackgroundColor}
                onColumnLabelBackgroundColorChange={
                  setColumnLabelBackgroundColor
                }
                onColumnLabelColorChange={setColumnLabelColor}
                columnLabelTextStyle={columnLabelTextStyle}
                columnLabelTextAlignment={columnLabelTextAlignment}
                columnLabelColor={columnLabelColor}
                columnLabelFontSize={columnLabelFontSize}
                onColumnLabelFontSizeChange={setColumnLabelFontSize}
                headerBackgroundColor={headerBackgroundColor}
                onHeaderBackgroundColorChange={setHeaderBackgroundColor}
                headerTextColor={headerTextColor}
                onHeaderTextColorChange={setHeaderTextColor}
                onBorderRadiusChange={setBorderRadius}
                borderRadius={borderRadius}
                backgroundColor={backgroundColor}
                onBackgroundColorChange={setBackgroundColor}
                dataColor={dataColor}
                onDataColorChange={setDataColor}
                rowHoverColor={rowHoverColor}
                onRowHoverColorChange={setRowHoverColor}
                columnHoverColor={columnHoverColor}
                onColumnHoverColorChange={setColumnHoverColor}
                rangeSelectionBorderColor={rangeSelectionBorderColor}
                onRangeSelectionBorderColorChange={setRangeSelectionBorderColor}
                rangeSelectionBorderStyle={rangeSelectionBorderStyle}
                onRangeSelectionBorderStyleChange={setRangeSelectionBorderStyle}
                rangeSelectionBackgroundColor={rangeSelectionBackgroundColor}
                onRangeSelectionBackgroundColorChange={
                  setRangeSelectionBackgroundColor
                }
                //Table Header
                tableHeader={tableHeader}
                onTableHeaderChange={setTableHeader}
                bordersSecondary={bordersSecondary}
                onBordersSecondaryChange={setBordersSecondary}
                secondaryBorderColor={secondaryBorderColor}
                onSecondaryBorderColorChange={setSecondaryBorderColor}
                cellHorizontalBorder={cellHorizontalBorder}
                onCellHorizontalBorderChange={setCellHorizontalBorder}
                gridSize={gridSize}
                onGridSizeChange={setGridSize}
                rowHeight={rowHeight}
                onRowHeightChange={setRowHeight}
                headerHeight={headerHeight}
                onHeaderHeightChange={setHeaderHeight}
                headerColumnSeparatorDisplay={headerColumnSeparatorDisplay}
                onHeaderColumnSeparatorDisplayChange={
                  setHeaderColumnSeparatorDisplay
                }
                fontFamily={fontFamily}
                onFontFamilyChange={setFontFamily}
                fontSize={fontSize}
                onFontSizeChange={setFontSize}
                headerFontFamily={headerFontFamily}
                onHeaderFontFamilyChange={setHeaderFontFamily}
                headerFontSize={headerFontSize}
                onHeaderFontSizeChange={setHeaderFontSize}
                headerTextStyle={headerTextStyle}
                onHeaderTextStyleChange={setHeaderTextStyle}
                headerTextAlignment={headerTextAlignment}
                onHeaderTextAlignmentChange={setHeaderTextAlignment}
                headerBorder={headerBorder}
                onHeaderBorderChange={setHeaderBorder}
                headerBorderColor={headerBorderColor}
                onHeaderBorderColorChange={setHeaderBorderColor}
                cellFormat={cellFormat}
                onCellFormatChange={setCellFormat}
                cellFormatTextStyle={cellFormatTextStyle}
                onCellFormatTextStyleChange={setCellFormatTextStyle}
                cellFormatTextAlignment={cellFormatTextAlignment}
                onCellFormatTextAlignmentChange={setCellFormatTextAlignment}
                cellFormatBorder={cellFormatBorder}
                onCellFormatBorderChange={setCellFormatBorder}
                cellFormatBorderColor={cellFormatBorderColor}
                onCellFormatBorderColorChange={setCellFormatBorderColor}
                cellFormatBackgroundColor={cellFormatBackgroundColor}
                onCellFormatBackgroundColorChange={setCellFormatBackgroundColor}
                cellFormatTextColor={cellFormatTextColor}
                onCellFormatTextColorChange={setCellFormatTextColor}
                // Total & Sum Rows
                totalRowTextStyle={totalRowTextStyle}
                onTotalRowTextStyleChange={setTotalRowTextStyle}
                totalRowTextAlignment={totalRowTextAlignment}
                onTotalRowTextAlignmentChange={setTotalRowTextAlignment}
                totalRowBackgroundColor={totalRowBackgroundColor}
                onTotalRowBackgroundColorChange={setTotalRowBackgroundColor}
                totalRowFontFamily={totalRowFontFamily}
                onTotalRowFontFamilyChange={setTotalRowFontFamily}
                totalRowFontSize={totalRowFontSize}
                onTotalRowFontSizeChange={setTotalRowFontSize}
                totalRowColor={totalRowColor}
                onTotalRowColorChange={setTotalRowColor}
                totalRowTextDecoration={totalRowTextDecoration}
                onTotalRowTextDecorationChange={setTotalRowTextDecoration}
                subTotalRowTextStyle={subTotalRowTextStyle}
                onSubTotalRowTextStyleChange={setSubTotalRowTextStyle}
                subTotalRowTextAlignment={subTotalRowTextAlignment}
                onSubTotalRowTextAlignmentChange={setSubTotalRowTextAlignment}
                subTotalRowBorder={subTotalRowBorder}
                onSubTotalRowBorderChange={setSubTotalRowBorder}
                subTotalRowBorderTop={subTotalRowBorderTop}
                onSubTotalRowBorderTopChange={setSubTotalRowBorderTop}
                subTotalRowBorderBottom={subTotalRowBorderBottom}
                onSubTotalRowBorderBottomChange={setSubTotalRowBorderBottom}
                subTotalRowBorderColor={subTotalRowBorderColor}
                onSubTotalRowBorderColorChange={setSubTotalRowBorderColor}
                subTotalRowBackgroundColor={subTotalRowBackgroundColor}
                onSubTotalRowBackgroundColorChange={
                  setSubTotalRowBackgroundColor
                }
                subTotalRowTextDecoration={subTotalRowTextDecoration}
                onSubTotalRowTextDecorationChange={setSubTotalRowTextDecoration}
                subTotalRowColor={subTotalRowColor}
                onSubTotalRowColorChange={setSubTotalRowColor}
                subTotalRowFontSize={subTotalRowFontSize}
                onSubTotalRowFontSizeChange={setSubTotalRowFontSize}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
