import { Service } from "electrodb";
import { Deal } from "./deal";
import { Analysis } from "./analysis";
import { AnalysisRun } from "./analysisRun";
import { Prompt } from "./prompt";
import { Export } from "./export";
import { Input } from "./input";
import { SlideMaster } from "./slideMaster";
import { ActiveExport } from "./activeExport";

export const TransactedPlatform = new Service({
  deal: Deal,
  analysis: Analysis,
  analysisRun: AnalysisRun,
  prompt: Prompt,
  export: Export,
  input: Input,
  slideMaster: SlideMaster,
  activeExport: ActiveExport
});
