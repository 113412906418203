import {
  S3Client,
  ListObjectsV2Command,
  PutObjectCommand,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import {
  GetFilesOutput,
  FileSystemObject,
  PrepareVdrInput,
} from "../model/api/vdr";
import { Entity } from "electrodb";
import { DealSchemaType } from "../model/electrodb";
import { InvokeCommand, LambdaClient } from "@aws-sdk/client-lambda";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

async function s3ObjectToFile(file: string, id: string, bucket: string, key: string, client: S3Client): Promise<FileSystemObject> {
  const getObjectCommand = new GetObjectCommand({
    Bucket: bucket,
    Key: key
  });

  return {
    name: file,
    isDirectory: false,
    directories: [],
    files: [],
    signedUrl: await getSignedUrl(client, getObjectCommand),
    id,
  };
}

function s3ObjectToDirectory(file: string, id: string): FileSystemObject {
  return {
    name: file,
    isDirectory: true,
    directories: [],
    files: [],
    signedUrl: undefined,
    id,
  };
}

// TODO: Easy optimizations here, just being lazy
export async function getFilesCore(
  client: S3Client,
  bucketName: string,
): Promise<GetFilesOutput> {
  const command = new ListObjectsV2Command({
    Bucket: bucketName,
    Prefix: "vdr/",
  });

  const data = await client.send(command);
  const fileNames =
    data.Contents?.map((file) => file.Key!)
      .sort()
      .reverse() || [];
  const root: FileSystemObject = {
    name: "vdr",
    isDirectory: true,
    files: [],
    directories: [],
    id: `${bucketName}/vdr`,
    signedUrl: undefined,
  };
  const directories = fileNames.filter((fileName) => fileName.endsWith("/"));
  const files = fileNames.filter((fileName) => !fileName.endsWith("/"));

  for (const directory of directories) {
    const parts = directory.split("/");
    let currentDirectory = root;
    // All paths start with "vdr/" and end with "/"
    for (let i = 1; i < parts.length - 1; i++) {
      const directoryIfExists = currentDirectory.directories?.find(
        (f) => f.name === parts[i],
      );
      if (directoryIfExists !== undefined) {
        currentDirectory = directoryIfExists;
        continue;
      }

      const newDirectory = s3ObjectToDirectory(
        parts[i],
        `${bucketName}/${directory}`,
      );
      currentDirectory.directories.push(newDirectory);
      currentDirectory = newDirectory;
    }
  }

  for (const file of files) {
    const parts = file.split("/");
    let currentDirectory = root;
    for (let i = 1; i < parts.length; i++) {
      if (i === parts.length - 1) {
        currentDirectory.files.push(
          await s3ObjectToFile(parts[i], `${bucketName}/${file}`, bucketName, file, client),
        );
      }

      const directoryIfExists = currentDirectory.directories.find(
        (f) => f.name === parts[i],
      )!;

      if (directoryIfExists === undefined) {
        // TODO: Fix this bug where we didn't create a directory for a nested file
        continue;
      }

      currentDirectory = directoryIfExists;
    }
  }

  console.log(root);
  return { root };
}

type PrepareVdrParams = {
  dealEntity: Entity<string, string, string, DealSchemaType>;
  lambdaClient: LambdaClient;
  s3Client: S3Client;
  functionName: string;
};

export async function prepareVdrCore(
  params: PrepareVdrParams,
  request: PrepareVdrInput,
) {
  try {
    const splitDeal = request.dealId.split("--");
    const dealTitle = splitDeal[0].replaceAll(".", " ");
    const dealId = splitDeal[1];

    const invokeCommand = new InvokeCommand({
      FunctionName: params.functionName,
      Payload: new TextEncoder().encode(JSON.stringify(request)),
    });

    const response = await params.lambdaClient.send(invokeCommand);
    const responsePayload = new TextDecoder().decode(response.Payload);

    if (response.StatusCode !== 200) {
      throw new Error("Unable to prepare VDR.");
    }

    const putObjectCommand = new PutObjectCommand({
      Bucket: request.dealId,
      Key: "preparedOptions.json",
      Body: JSON.parse(responsePayload).body,
    });
    await params.s3Client.send(putObjectCommand);

    const { data: existingDeal } = await params.dealEntity
      .get({ dealTitle, dealId })
      .go();

    await params.dealEntity
      .patch({
        dealTitle: existingDeal!.dealTitle,
        dealId: existingDeal!.dealId,
      })
      .set({ vdrReady: true })
      .go();

    return { statusCode: 200 };
  } catch (e) {
    console.error("Error preparing VDR: ", e);
    return { statusCode: 500 };
  }
}
