import React, { FC } from "react";
import { Icons } from "..";

interface CheckboxProps {
  label?: string;
}

export const Checkbox: FC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > &
    CheckboxProps
> = (props) => {
  const { className, checked, onClick, label, ...otherProps } = props;
  const checkedStyle = "border-DarkBlue";
  const onClickHandle = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.stopPropagation();
    onClick?.(e);
  };
  return (
    <label
      className={`flex align-middle cursor-pointer leading-5 ${className}`}
    >
      <div
        className={`w-5 h-5 relative inline-block me-2 rounded border border-Grey20 bg-Grey0 shadow-sm ${
          (checked && checkedStyle) || ""
        }`}
      >
        <input
          type="checkbox"
          className="w-5 h-5 absolute top-0 left-0 outline-1 outline-DarkBlue cursor-pointer invisible"
          defaultChecked={checked}
          onClick={onClickHandle}
          {...otherProps}
        />
        {checked && <Icons.Tick className="absolute z-0 top-0 left-0" />}
      </div>
      {label}
    </label>
  );
};
