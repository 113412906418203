import { FC } from "react";

export const StatusError: FC = () => (
  <svg
    width="36"
    height="32"
    viewBox="0 0 36 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="32" rx="16" fill="#FED7D7" />
    <path
      d="M23.0003 11L12.167 21.8333"
      stroke="#D72626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.167 11L23.0003 21.8333"
      stroke="#D72626"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
