"use client";
import { Icons, NoItemsPlaceholder, ScrollWrapper } from "@/components";
// eslint-disable-next-line import/no-internal-modules
import React, { useState } from "react";
import {
  FileSystemObject,
  GetFilesOutput,
} from "../../../../../../../core/model";
import "@carbon/react";
import {
  Button,
  Search,
  UnorderedList,
  ListItem,
  Layer,
  Tag,
  Tile,
  Link,
  ContentSwitcher,
  Switch,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableExpandRow,
  TableExpandedRow,
  TableExpandHeader,
  TableHead,
  TableHeader,
  TableRow,
} from "@carbon/react";
// eslint-disable-next-line import/no-internal-modules
import { Folder, Document, ArrowRight } from "@carbon/react/icons";
import { SwitcherButtons } from "../../../analysisRun";
import { ComboChart, DonutChart } from "@carbon/charts-react";
// eslint-disable-next-line import/no-internal-modules
import "@carbon/charts-react/styles.css";
import { vdrBarChartData, vdrBarChartOptions } from "./vdrSummaryData";
// eslint-disable-next-line unicorn/prefer-module
const TreeView = require("@carbon/react").TreeView as any;
// eslint-disable-next-line unicorn/prefer-module
const TreeNode = require("@carbon/react").TreeNode as any;

// interface FileRow {
//   id: string;
//   name: string;
// }

class AppCombo extends React.Component {
  state = {
    data: vdrBarChartData,
    options: vdrBarChartOptions,
  };

  render = () => (
    <ComboChart
      data={this.state.data as any}
      options={this.state.options as any}
    ></ComboChart>
  );
}

class AppDonut extends React.Component {
  state = {
    data: vdrBarChartData,
    options: vdrBarChartOptions,
  };

  render = () => (
    <DonutChart
      data={this.state.data as any}
      options={this.state.options as any}
    ></DonutChart>
  );
}

export const DataExport = ({ root }: { root: GetFilesOutput }) => {
  const [selectedView, setSelectedView] = useState<SwitcherButtons>(
    SwitcherButtons.SUMMARY,
  );
  const [selectedFile, setSelectedFile] = useState<string | undefined>();
  const [selectedFolderData, setSelectedFolderData] = useState<
    FileSystemObject | undefined
  >();
  const hasFiles = selectedFolderData?.files?.length;
  const nodes = root.root?.directories ?? [];

  const handleFileClick = (value: string) => () => {
    if (value === "" || value.includes(".pdf") || value.includes(".xlsx")) {
      setSelectedFile(value);
    }
  };

  const handleFolderClick = (folderData: FileSystemObject) => {
    setSelectedFolderData(folderData);
  };

  const renderTree = (options: {
    nodes: FileSystemObject[];
    withIcons: boolean;
  }) => {
    return options.nodes.map((node) => (
      <TreeNode
        key={node.id}
        label={
          <div
            className="flex items-center"
            onClick={() => handleFolderClick(node)}
          >
            {options.withIcons &&
              (node.directories ? (
                <Folder size={16} className="mr-2" />
              ) : (
                <Document size={16} className="mr-2" />
              ))}
            {node.name}
          </div>
        }
      >
        {node.directories?.map((childNode) => (
          <TreeNode
            key={childNode.id}
            label={
              <div
                className="flex items-center"
                onClick={() => handleFolderClick(childNode)}
              >
                {options.withIcons &&
                  (childNode.directories ? (
                    <Folder size={16} className="mr-2" />
                  ) : (
                    <Document size={16} className="mr-2" />
                  ))}
                {childNode.name}
              </div>
            }
          >
            {childNode.directories?.map((grandchildNode) =>
              renderTree({
                nodes: [grandchildNode],
                withIcons: options.withIcons,
              }),
            )}
          </TreeNode>
        ))}
      </TreeNode>
    ));
  };

  const topLevelFolders = nodes.map((node) => ({
    id: node.id,
    name: node.name,
  }));

  return (
    <div className="flex flex-row flex-1">
      <div
        className="w-1/4 flex flex-col flex-grow border-r border-solid border-border-subtle-0"
        style={{ borderRight: "1px solid #e8e8e8" }}
      >
        <div className="mt-5 mx-5">
          <Search
            size="sm"
            placeholder="Search"
            labelText="Search"
            closeButtonLabelText="Clear search input"
            id="search-1"
            onChange={() => {}}
            onKeyDown={() => {}}
          />

          <div className="h-2"></div>

          <ContentSwitcher
            size="sm"
            selectedIndex={selectedView === SwitcherButtons.SUMMARY ? 0 : 1}
            onChange={({ name }) =>
              setSelectedView(
                name === "summary"
                  ? SwitcherButtons.SUMMARY
                  : SwitcherButtons.VDR,
              )
            }
          >
            <Switch name="summary" text="Summary" />
            <Switch name="file-tree" text="File Tree" />
          </ContentSwitcher>
        </div>
        <ScrollWrapper>
          <TreeView>
            {renderTree({
              nodes,
              withIcons: true,
            })}
          </TreeView>
        </ScrollWrapper>
      </div>
      <div
        className={`w-full bg-[#f4f4f4] pl-5 relative flex flex-col flex-grow ${
          hasFiles || selectedFile ? "bg-Grey0 pt-3 px-4 rounded-lg" : ""
        }`}
      >
        {selectedView === SwitcherButtons.SUMMARY && (
          <div className="flex flex-col flex-grow w-full mt-5">
            <div className="grid grid-auto-rows grid-cols-[50%_25%_25%] items-stretch">
              <Layer className="flex flex-col flex-grow mr-1">
                <Tile className="flex flex-col flex-grow text-md">
                  <div className="flex flex-row">
                    <span className="font-bold"> Target:&nbsp; </span>
                    <span>RJR Nabisco</span>
                  </div>
                  <div className="flex flex-row">
                    <span className="font-bold"> Project name:&nbsp; </span>
                    <span>Accelerate</span>
                  </div>
                  <div className="flex flex-row">
                    <span className="font-bold">Sell-side advisor:&nbsp; </span>
                    <span> Perella Weinberg Partners</span>
                  </div>
                  <div className="flex flex-row">
                    <span className="font-bold">Timeline:&nbsp; </span>
                    <span> Second round bids due December 31, 2024</span>
                  </div>
                  <br />
                  <div className="text-xs">
                    <span className="font-bold">Transaction Summary</span>
                    <ul>
                      <li>
                        Leading PACS (picture imaging and communication system)
                        used by hospital systems and physician practices
                        (primarily radiology and cardiology specialties) to
                        improve care coordination, speed clinical decision
                        making and improve hospital/provider finances by
                        allowing images and data to be shared and reviewed
                        seamlessly between members of the health team. Change
                        Healthcare is seeking to divest the business.
                      </li>
                    </ul>
                    <br />
                  </div>
                  <div className="flex flex-row flex-grow mt-3">
                    <Layer className="flex flex-row flex-grow items-stretch">
                      <Tile className="flex flex-row flex-grow mr-1 items-stretch">
                        <div className="flex flex-col">
                          <div className="text-sm">LTM Revenue</div>
                          <div className="text-xl font-bold">$100M</div>
                          <ul className="text-xs mt-2">
                            <li>Prior Year: $100M (25% YoY growth)</li>
                            <li>Run Rate: $125M</li>
                            <li>2024E: $150M</li>
                          </ul>
                        </div>
                      </Tile>
                    </Layer>
                    <Layer className="flex flex-row flex-grow items-stretch">
                      <Tile className="flex flex-row flex-grow items-stretch">
                        <div className="flex flex-col">
                          <div className="text-sm">LTM EBITDA</div>
                          <div className="text-xl font-bold">$100M</div>
                          <ul className="text-xs mt-2">
                            <li>Prior Year: $100M (25% YoY growth)</li>
                            <li>Run Rate: $125M</li>
                            <li>2024E: $150M</li>
                          </ul>
                        </div>
                      </Tile>
                    </Layer>
                  </div>
                </Tile>
              </Layer>
              <Layer className="flex flex-col flex-grow mr-1">
                <Tile className="flex flex-col flex-grow text-sm">
                  <AppCombo />
                </Tile>
              </Layer>
              <Layer className="flex flex-col flex-grow">
                <Tile className="flex flex-col flex-grow text-sm">
                  <AppDonut />
                </Tile>
              </Layer>
            </div>
            <div className="bg-white px-4 py-4 mt-1">
              <Layer>
                <DataTable
                  rows={topLevelFolders}
                  headers={[
                    {
                      key: "name",
                      header: "Contents summary",
                    },
                  ]}
                  render={({
                    rows,
                    headers,
                    getHeaderProps,
                    getExpandHeaderProps,
                    getRowProps,
                    getExpandedRowProps,
                    getTableProps,
                    getTableContainerProps,
                  }) => (
                    <TableContainer {...getTableContainerProps()}>
                      <Table {...getTableProps()} aria-label="files table">
                        <TableHead>
                          <TableRow>
                            <TableExpandHeader
                              enableToggle={true}
                              {...(getExpandHeaderProps() as any)}
                            />
                            {headers.map((header, i) => (
                              <TableHeader
                                key={i}
                                {...(getHeaderProps({ header }) as any)}
                              >
                                {header.header}
                              </TableHeader>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <React.Fragment key={row.id}>
                              <TableExpandRow
                                {...(getRowProps({ row }) as any)}
                              >
                                {row.cells.map((cell) => (
                                  <TableCell key={cell.id}>
                                    {cell.value}
                                  </TableCell>
                                ))}
                              </TableExpandRow>
                              <TableExpandedRow
                                colSpan={headers.length + 1}
                                className="demo-expanded-td"
                                {...getExpandedRowProps({ row })}
                              >
                                <div className="flex flex-row mb-2">
                                  <div>
                                    Leading PACS (picture imaging and
                                    communication system) used by hospital
                                    systems and physician practices (primarily
                                    radiology and cardiology specialties) to
                                    improve care coordination, speed clinical
                                    decision making and improve
                                    hospital/provider fina
                                  </div>

                                  <div>
                                    <Link>
                                      <ArrowRight />
                                    </Link>
                                  </div>
                                </div>
                                <div className="mb-5">
                                  <Tag className="some-class" type="red">
                                    {"Tag content"}
                                  </Tag>
                                  <Tag className="some-class" type="magenta">
                                    {"Revenue (by client, by product)"}
                                  </Tag>
                                  <Tag className="some-class" type="purple">
                                    {"Price (by client, by product)"}
                                  </Tag>
                                  <Tag className="some-class" type="blue">
                                    {"Volume (by client, by product)"}
                                  </Tag>
                                  <Tag className="some-class" type="cyan">
                                    {"Tag content"}
                                  </Tag>
                                  <Tag className="some-class" type="teal">
                                    {"Tag content"}
                                  </Tag>
                                  <Tag className="some-class" type="green">
                                    {"Tag content"}
                                  </Tag>
                                  <Tag className="some-class" type="gray">
                                    {"Tag content"}
                                  </Tag>
                                  <Tag className="some-class" type="cool-gray">
                                    {"Tag content"}
                                  </Tag>
                                  <Tag className="some-class" type="warm-gray">
                                    {"Tag content"}
                                  </Tag>
                                  <Tag
                                    className="some-class"
                                    type="high-contrast"
                                  >
                                    {"Tag content"}
                                  </Tag>
                                  <Tag className="some-class" type="outline">
                                    {"Tag content"}
                                  </Tag>
                                </div>
                                <UnorderedList>
                                  <ListItem>Revenue by client</ListItem>
                                  <ListItem>Volume by client</ListItem>
                                  <ListItem>Price by client</ListItem>
                                </UnorderedList>
                              </TableExpandedRow>
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                />
              </Layer>
            </div>
          </div>
        )}
        {selectedView === SwitcherButtons.VDR && (
          <div className="flex flex-col flex-grow w-full">
            <ul className="mt-6 flex flex-col flex-grow gap-2">
              {selectedFolderData ? (
                selectedFile ? (
                  <>
                    <Icons.Close
                      className="cursor-pointer mb-2 ms-auto"
                      onClick={handleFileClick("")}
                    />
                    {selectedFile.includes(".pdf") ? (
                      <iframe src={selectedFile} width="100%" height="500px" />
                    ) : selectedFile.includes(".xlsx") ? (
                      <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                          selectedFile,
                        )}`}
                        width="100%"
                        height="500px"
                        frameBorder="0"
                      />
                    ) : undefined}
                  </>
                ) : hasFiles ? (
                  selectedFolderData.files.map((file) => (
                    <li className="text-Grey50" key={file.id}>
                      <Button
                        onClick={handleFileClick(file.signedUrl || "")}
                        className="flex w-full items-center justify-between group"
                      >
                        <div className="flex items-center gap-1 group-hover:text-Black100 text-sm">
                          <Icons.FileIcon />
                          {file.name}
                        </div>
                        <Icons.Arrow className="hidden group-hover:block group-focus:block" />
                      </Button>
                    </li>
                  ))
                ) : (
                  <NoItemsPlaceholder
                    title="Empty folder"
                    subtitle="No contents to display"
                  />
                )
              ) : (
                <NoItemsPlaceholder
                  title="No file selected"
                  subtitle="Select a file to view its contents"
                />
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
