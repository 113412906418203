import { Entity, createSchema } from "electrodb";

const ExportSchema = createSchema({
  model: {
    entity: "export",
    service: "transacted-platform",
    version: "1",
  },
  attributes: {
    dealId: {
      type: "string",
      required: true,
    },
    dealTitle: {
      type: "string",
      required: true,
    },
    analysisRunId: {
      type: "string",
      required: true,
    },
    s3Bucket: {
      type: "string",
      required: true,
    },
    exportTitle: {
      type: "string",
      required: true,
    },
  },
  indexes: {
    primary: {
      pk: {
        field: "pk",
        composite: ["dealTitle", "dealId"],
      },
      sk: {
        field: "sk",
        composite: ["analysisRunId"],
      },
    },
  },
});

export type ExportSchemaType = typeof ExportSchema;
export const Export = new Entity(ExportSchema);
