import { Entity, createSchema } from "electrodb";

const InputSchema = createSchema({
  model: {
    entity: "input",
    service: "transacted-platform",
    version: "1",
  },
  attributes: {
    inputName: {
      type: "string",
      required: true,
    },
    value: {
      type: "number",
      required: true,
    },
  },
  indexes: {
    primary: {
      pk: {
        field: "pk",
        composite: ["inputName"],
      },
    },
  },
});

export const Input = new Entity(InputSchema);
export type InputSchemaType = typeof InputSchema;
