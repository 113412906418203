import { FC } from "react";

export const GoogleIcon: FC = () => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1254 6.64784C10.2314 7.29588 8.61763 8.71319 7.71874 10.5068C7.40518 11.1255 7.17941 11.7819 7.04145 12.4634C6.69862 14.1525 6.93692 15.9544 7.71456 17.493C8.22045 18.4964 8.94792 19.3911 9.8259 20.0893C10.6579 20.7499 11.6237 21.2432 12.6522 21.515C13.9482 21.862 15.3279 21.8536 16.6323 21.5568C17.8113 21.285 18.9276 20.7206 19.8181 19.9012C20.7588 19.0357 21.432 17.8985 21.7873 16.6735C22.1761 15.3398 22.2263 13.9142 21.9838 12.5429C19.5464 12.5429 17.1048 12.5429 14.6673 12.5429C14.6673 13.5546 14.6673 14.5664 14.6673 15.5782C16.0805 15.5782 17.4936 15.5782 18.9067 15.5782C18.7437 16.5481 18.1667 17.4345 17.3514 17.9821C16.8372 18.3292 16.2477 18.5507 15.6373 18.6594C15.0269 18.764 14.3914 18.7765 13.781 18.6553C13.158 18.5298 12.5685 18.2706 12.0501 17.9069C11.2223 17.3258 10.591 16.477 10.2691 15.5196C9.93878 14.5455 9.9346 13.4626 10.2691 12.4927C10.5032 11.807 10.8878 11.1757 11.3979 10.6573C12.025 10.0134 12.8403 9.55354 13.7225 9.3654C14.475 9.20653 15.2694 9.23579 16.0052 9.45738C16.6323 9.64552 17.2093 9.99253 17.6817 10.4441C18.1583 9.96744 18.635 9.49083 19.1116 9.01421C19.3624 8.75918 19.6258 8.51251 19.8683 8.24911C19.145 7.58017 18.2921 7.04084 17.364 6.69801C15.6916 6.07925 13.8103 6.0667 12.1254 6.64784Z"
      fill="white"
    />
    <path
      d="M12.1254 6.64833C13.8061 6.06719 15.6916 6.07973 17.364 6.69432C18.2921 7.03715 19.1409 7.5723 19.8683 8.24541C19.6258 8.50881 19.3624 8.75548 19.1116 9.01051C18.635 9.48713 18.1584 9.96375 17.6817 10.4404C17.2093 9.98883 16.6323 9.646 16.0052 9.45368C15.2694 9.2321 14.475 9.19865 13.7225 9.3617C12.8445 9.54984 12.0292 10.0097 11.3979 10.6536C10.8878 11.1678 10.5032 11.8033 10.2691 12.489C9.42036 11.8284 8.57164 11.172 7.71875 10.5114C8.61763 8.71367 10.2314 7.29636 12.1254 6.64833Z"
      fill="#EA4335"
    />
    <path
      d="M7.04513 12.4605C7.1831 11.7832 7.40887 11.1227 7.72243 10.5039C8.57114 11.1645 9.41986 11.8209 10.2728 12.4815C9.93828 13.4556 9.93828 14.5384 10.2728 15.5084C9.42404 16.169 8.57533 16.8295 7.72661 17.4859C6.94061 15.9516 6.7023 14.1496 7.04513 12.4605Z"
      fill="#FBBC05"
    />
    <path
      d="M14.6682 12.5391C17.1057 12.5391 19.5473 12.5391 21.9847 12.5391C22.2272 13.9062 22.1729 15.3319 21.7882 16.6697C21.4329 17.8947 20.7598 19.0319 19.8191 19.8974C18.9954 19.2577 18.1718 18.618 17.3482 17.9784C18.1634 17.4307 18.7404 16.5443 18.9035 15.5744C17.4903 15.5744 16.0772 15.5744 14.6641 15.5744C14.6682 14.5626 14.6682 13.5508 14.6682 12.5391Z"
      fill="#4285F4"
    />
    <path
      d="M7.71875 17.4893C8.56746 16.8329 9.41618 16.1723 10.2649 15.5117C10.591 16.4691 11.2181 17.3178 12.0459 17.899C12.5644 18.2627 13.158 18.5219 13.7768 18.6474C14.3872 18.7728 15.0185 18.7561 15.6331 18.6515C16.2435 18.5428 16.833 18.3213 17.3473 17.9742C18.1709 18.6139 18.9945 19.2536 19.8182 19.8933C18.9276 20.7169 17.8113 21.2771 16.6323 21.5489C15.3279 21.8457 13.9482 21.8541 12.6522 21.5071C11.6237 21.2353 10.6579 20.7462 9.8259 20.0814C8.9521 19.3874 8.22463 18.4927 7.71875 17.4893Z"
      fill="#34A853"
    />
  </svg>
);
