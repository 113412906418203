import { FC } from "react";

export const BoldText: FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.73438 8.00156H9.06771C10.835 8.00156 12.2677 6.56887 12.2677 4.80156C12.2677 3.03425 10.835 1.60156 9.06771 1.60156H3.81886C3.7722 1.60156 3.73438 1.63939 3.73438 1.68605V8.00156ZM3.73438 8.00156H10.1344C11.9017 8.00156 13.3344 9.43425 13.3344 11.2016C13.3344 12.9689 11.9017 14.4016 10.1344 14.4016H3.82942C3.77693 14.4016 3.73438 14.359 3.73438 14.3065V8.00156Z"
      stroke="#BCBCBC"
      strokeWidth="2"
    />
  </svg>
);
