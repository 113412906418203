import { FC } from "react";

export const FacebookIcon: FC = () => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="8.11133" y="7" width="14.7778" height="16.3333" fill="white" />
    <path
      d="M22.6371 4.66797H6.03153C5.4622 4.66797 5.00098 5.12919 5.00098 5.69852V22.3049C5.00098 22.8734 5.4622 23.3346 6.03153 23.3346H14.9721V16.106H12.5392V13.2889H14.9721V11.2114C14.9721 8.8003 16.4444 7.48741 18.5958 7.48741C19.6263 7.48741 20.5114 7.56441 20.7696 7.59864V10.1186L19.2779 10.1194C18.1081 10.1194 17.8818 10.6755 17.8818 11.4906V13.2896H20.6716L20.3084 16.1067H17.8818V23.3346H22.6386C23.2064 23.3346 23.6676 22.8734 23.6676 22.3041V5.69852C23.6676 5.12919 23.2064 4.66797 22.6371 4.66797V4.66797Z"
      fill="#4A67AD"
    />
  </svg>
);
