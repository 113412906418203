import { ValueFormatterParams } from "ag-grid-community";

export enum DateSort {
  QUARTERLY = "Quarterly",
  MONTHLY = "Monthly",
  ANNUAL = "Annual",
}

export enum SwitcherButtons {
  SUMMARY = "SUMMARY",
  VDR = "VDR",
}

export interface ChartOptions {
  type?: string;
  axisSwitched?: boolean;
  xAxisLabel?: {
    formatter?: (params: ValueFormatterParams) => string;
    [key: string]: any;
  };
  yAxisLabel?: {
    formatter?: (params: ValueFormatterParams) => string;
    [key: string]: any;
  };
  yAxisMin?: number;
  yAxisMax?: number;
  data?: any[];
  series?: any[];
  resizable?: boolean;
  donut?: any;
  legend?: any;
  [key: string]: any;
  autoSizeColumns?: any;
  gridLine?: any;
}
