import { AgGridReact } from "ag-grid-react";
import { GridReadyEvent, GridApi, RowStyle } from "ag-grid-community";
import { ParsedAnalysisData } from "../../../../../core/model";
import { useMemo, useEffect, useState } from "react";
import { DateSort } from "./types";
import currency from "currency.js";
import { GridRenderDateOptions } from "./dateSort";
import { TextAlign } from "ag-charts-community";
import { Filter } from "../../../../../core/model/api/activeExport";
import { Table } from "@/stores";
import { Icons } from "@/components";
// eslint-disable-next-line import/no-internal-modules
import { Percentage } from "@carbon/react/icons";
import { Button } from "@carbon/react";

type Props = {
  style: any;

  // className: any;
  data: ParsedAnalysisData;
  onClick: (e: any) => void;
  index: number;
  backgroundColor?: string;
  className: string;

  // Grid global styles
  gridGlobalBackgroundColor: string;
  onGridGlobalBackgroundColorChange: (color: string) => void;
  globalFontFamily: string;
  onGlobalFontFamilyChange: (fontFamily: string) => void;
  globalFontSize: string;
  onGlobalFontSizeChange: (fontSize: string) => void;
  globalTextStyle: string;
  onGlobalTextStyleChange: (textStyle: string) => void;

  globalBorder: string;
  onGlobalBorderChange: (border: string) => void;
  globalBorderColor: string;
  onGlobalBorderColorChange: (color: string) => void;
  oddRowBackgroundColor: string;
  onOddRowBackgroundColorChange: (color: string) => void;
  tableHeader: string;
  onTableHeaderChange: (header: string) => void;

  //Cell format
  cellFormat?: any;
  cellFormatTextStyle?: any;
  cellFormatTextAlignment?: any;

  cellFormatFontFamily?: any;
  cellFormatBorder?: any;
  cellFormatBorderColor?: any;
  cellFormatBackgroundColor?: any;
  cellFormatTextDecoration?: any;
  cellFormatTextColor?: any;

  // Total row
  totalRowTextStyle?: any;
  totalRowTextAlignment?: any;
  totalRowBorder?: any;
  totalRowBorderColor?: any;
  totalRowBackgroundColor?: any;
  totalRowFontFamily?: any;
  totalRowFontSize?: any;
  totalRowColor?: any;
  totalRowTextDecoration?: any;

  // Sub Total Row
  subTotalRowTextStyle?: any;
  subTotalRowTextAlignment?: any;
  subTotalRowBorder?: any;
  subTotalRowBorderTop?: any;

  subTotalRowBorderBottom?: any;

  subTotalRowBorderColor?: any;
  subTotalRowBackgroundColor?: any;
  subTotalRowTextDecoration?: any;
  subTotalRowColor?: any;

  dataColor?: string;
  rowHoverColor?: string;
  columnHoverColor?: string;
  rangeSelectionBorderColor?: string;
  rangeSelectionBorderStyle?: string;
  rangeSelectionBackgroundColor?: string;
  borders?: string;
  borderColor?: string;
  bordersSecondary?: string;
  secondaryBorderColor?: string;

  cellHorizontalBorder?: string;
  gridSize?: string;

  rowHeight?: any;
  headerHeight?: any;

  fontFamily?: string;

  format?: any;
  onGridReady: (params: GridReadyEvent) => void;
  gridWrapperRef: any;
  active: boolean;
  headerBackgroundColor: any;
  headerTextColor: any;
  headerFontFamily: any;
  headerFontSize: any;
  headerTextStyle: any;
  headerTextAlignment: any;
  setActiveEditor: any;

  // Column labels
  columnLabelTextStyle: any;
  columnLabelTextAlignment: any;

  columnLabelBackgroundColor: any;
  columnLabelColor: any;
  columnLabelTextDecoration: any;
  headerColumnSeparatorDisplay: any;

  qualifierLabel: any;
  onQualifierLabelChange: (qualifier: string) => void;

  width?: number;
  height?: number;
  x: number;
  y: number;
  table: Table;
  updateTable: (newTable: Table) => void;
};

type CellStyle = {
  textAlign?: string;
  backgroundColor?: string;
  width?: any;
  borderBottom?: string;
};

function formatCell(
  cell: number | null | undefined,
  isFirstOrLastRow: boolean,
  isSpacerRow: boolean,
  isPercentSpacerColumn: boolean,
  isPercentColumn: boolean,
): string {
  if (isSpacerRow || isPercentSpacerColumn) {
    return ""; // Return an empty string for spacer rows and percent spacer column
  }

  if (isPercentColumn) {
    if (cell === null || cell === undefined) {
      return "-";
    } else if (cell > 0) {
      return `${cell.toFixed(1)}%`;
    } else if (cell < 0) {
      return `(${Math.abs(cell).toFixed(1)}%)`;
    } else {
      return "-";
    }
  }

  if (cell === null || cell === undefined || cell === 0) {
    return "-";
  }

  if (isFirstOrLastRow) {
    return currency(cell, {
      precision: 0,
      symbol: "$",
    }).format();
  } else {
    const formattedValue = Math.abs(cell).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return cell < 0 ? `(${formattedValue})` : formattedValue;
  }
}

export function filterData(
  analysis: ParsedAnalysisData,
  dateSort: DateSort,
  filter: Filter,
) {
  return analysis[dateSort].columns.filter((column, index) => {
    if (index === 0) {
      return true; // Always include the first column (category column)
    }

    const date = new Date(column.split(" - ")[1].trim());
    return date >= filter.start && date <= filter.end;
  });
}

export function analysisToTableData(
  analysis: ParsedAnalysisData,
  gridDateSort: DateSort,
  filter: Filter,
  showPercentOfTotal: boolean,
  qualifierLabel: string,
) {
  if (!analysis[gridDateSort]) {
    return { tableData: [], filteredColumns: [] };
  }

  const filteredColumns = filterData(analysis, gridDateSort, filter);

  // Find the index of the last non-zero or non-blank value in each row
  const lastNonEmptyIndexes = analysis[gridDateSort].data.map((row) => {
    for (let i = row.length - 1; i >= 0; i--) {
      if (row[i] !== 0 && row[i] !== null && row[i] !== undefined) {
        return i;
      }
    }
    return 0;
  });

  // Find the maximum index among all rows
  const maxNonEmptyIndex = Math.max(...lastNonEmptyIndexes);

  // Filter the columns based on the maximum non-empty index
  const filteredColumnsWithData = filteredColumns.slice(
    0,
    Math.max(maxNonEmptyIndex + 1, 1), // Ensure at least the first column is included
  );

  const tableData = analysis[gridDateSort].data.map((row) => {
    const rowObject: Record<string, number | string> = {};
    rowObject["spacer"] = ""; // Add an empty spacer column
    for (const [_, column] of filteredColumnsWithData.entries()) {
      const value = row[analysis[gridDateSort].columns.indexOf(column)];
      rowObject[column] = value as string | number;
    }
    return rowObject;
  });

  const lastColumn = filteredColumnsWithData.at(-1)!;
  let sortedTableData = tableData.sort((a, b) => {
    if (a[lastColumn] > b[lastColumn]) {
      return -1;
    } else if (a[lastColumn] < b[lastColumn]) {
      return 1;
    } else {
      return 0;
    }
  });

  if (sortedTableData.length > filter.rowCount) {
    // Calculate "Other" total row of excluded data from the filter's rowCount
    const otherObject: Record<string, number | string> = {};
    otherObject[filteredColumnsWithData[0]] = "All Other"; // Add "Other" label to the first column
    for (const [_, column] of filteredColumnsWithData.entries()) {
      if (column === filteredColumnsWithData[0]) continue; // Skip the first column
      const value = sortedTableData
        .slice(filter.rowCount)
        .reduce((sum, row) => sum + Number(row[column] || 0), 0);
      otherObject[column] = value;
    }

    // Calculate "Subtotal" row of included data from the filter's rowCount
    const subtotalObject: Record<string, number | string> = {};
    subtotalObject[filteredColumnsWithData[0]] =
      "Top " + filter.rowCount + " " + qualifierLabel + "s";
    for (const [_, column] of filteredColumnsWithData.entries()) {
      if (column === filteredColumnsWithData[0]) continue; // Skip the first column
      const subtotal = sortedTableData
        .slice(0, filter.rowCount)
        .reduce((sum, row) => sum + Number(row[column] || 0), 0);
      subtotalObject[column] = subtotal;
    }

    // Create a blank spacer row to add padding between rows
    const blankSpacerObject: Record<string, number | string> = {};
    blankSpacerObject[filteredColumnsWithData[0]] = ""; // Add an empty string for the first column
    for (const [_, column] of filteredColumnsWithData.entries()) {
      if (column === filteredColumnsWithData[0]) continue; // Skip the first column
      blankSpacerObject[column] = ""; // Add an empty string for other columns
    }

    // Remove the excluded data from the sortedTableData
    sortedTableData = sortedTableData.splice(0, filter.rowCount);
    sortedTableData.push(subtotalObject, blankSpacerObject, otherObject);
  }

  if (analysis[gridDateSort].total) {
    const totalObject: Record<string, number | string> = {};

    for (const [_, column] of filteredColumnsWithData.entries()) {
      const value =
        analysis[gridDateSort].total[
          analysis[gridDateSort].columns.indexOf(column)
        ];
      totalObject[column] = value as string | number;
    }
    sortedTableData.push(totalObject);

    if (showPercentOfTotal) {
      const percentOfTotalColumns = filteredColumnsWithData.slice(1);
      const spacerColumn = { spacer: "" };
      const percentOfTotalData = sortedTableData.map((row) => {
        const percentOfTotalRow: Record<string, number | string> = {};
        for (const column of percentOfTotalColumns) {
          const total = totalObject[column] as number;
          const value = row[column] as number;
          const percentOfTotal = total === 0 ? 0 : (value / total) * 100;
          percentOfTotalRow[`${column} %`] = percentOfTotal;
        }
        return percentOfTotalRow;
      });
      sortedTableData = sortedTableData.map((row, index) => ({
        ...row,
        ...spacerColumn, // Add the additional spacer column
        ...percentOfTotalData[index],
      }));
      filteredColumnsWithData.push(
        "spacer",
        ...percentOfTotalColumns.map((column) => `${column} %`),
      );
    }
  }

  return {
    tableData: sortedTableData,
    filteredColumns: [
      ...filteredColumnsWithData.slice(0, 1),
      "spacer",
      ...filteredColumnsWithData.slice(1),
    ],
  };
}

export const Grid = ({
  qualifierLabel,
  // Grid global styles
  gridGlobalBackgroundColor,
  globalFontFamily,
  globalFontSize,

  globalBorder,
  globalBorderColor,
  oddRowBackgroundColor,

  // Table data
  data,

  dataColor,
  rowHoverColor,
  columnHoverColor,
  rangeSelectionBorderColor,
  rangeSelectionBorderStyle,
  rangeSelectionBackgroundColor,

  bordersSecondary,
  secondaryBorderColor,

  cellHorizontalBorder,
  gridSize,
  rowHeight,
  headerColumnSeparatorDisplay,
  fontFamily,
  tableHeader,

  gridWrapperRef,
  active,
  headerBackgroundColor,
  headerTextColor,
  headerFontFamily,
  headerTextStyle,
  headerTextAlignment,
  headerFontSize,
  headerHeight,

  // Cell Format
  cellFormatBorder,
  cellFormatBorderColor,

  // Sub Total Rows
  subTotalRowTextStyle,
  subTotalRowTextAlignment,
  subTotalRowBorder,
  subTotalRowBorderTop,

  subTotalRowBorderBottom,

  subTotalRowBorderColor,
  subTotalRowBackgroundColor,
  subTotalRowTextDecoration,
  subTotalRowColor,

  // Total Rows
  totalRowTextStyle,
  totalRowTextAlignment,
  totalRowBackgroundColor,
  totalRowFontFamily,
  totalRowFontSize,
  totalRowColor,
  totalRowTextDecoration,

  index,
  onClick,
  x,
  y,
  height,
  width,
  table,
  updateTable,
}: Props) => {
  const [gridDateSort, setGridDateSort] = useState<DateSort>(DateSort.ANNUAL);

  const updateFilter = (filter: Filter) => {
    updateTable({ ...table, filter });
  };

  const [showPercentOfTotal, setShowPercentOfTotal] = useState(false);

  const togglePercentOfTotal = () => {
    setShowPercentOfTotal(!showPercentOfTotal);
  };

  const { tableData, filteredColumns } = useMemo(() => {
    if (!data[gridDateSort]) {
      return { tableData: [], filteredColumns: [] };
    }

    const result = analysisToTableData(
      data,
      gridDateSort,
      table.filter,
      showPercentOfTotal,
      qualifierLabel,
    );
    return result;
  }, [data, gridDateSort, table.filter, showPercentOfTotal, qualifierLabel]);

  const columnDefs = useMemo(() => {
    console.log("filteredColumns.length:", filteredColumns.length);

    const percentToggleStartIndex = showPercentOfTotal
      ? filteredColumns.length * 0.5 + 1
      : filteredColumns.length;

    return filteredColumns.map((column, index) => {
      let headerName = column;
      let cellRenderer;
      let cellStyle: CellStyle = {};
      let colId = `colID=${index + 1}`; // Assigning colId starting from 1
      let suppressSizeToFit = index === 0; // Set suppressSizeToFit to true for the first column (colId=1)

      if (index === 0) {
        // Styling for the first column (column 0)
        cellStyle = {
          ...cellStyle,
          textAlign: "left !important",
        };

        return {
          headerName: headerName,
          field: column,
          colId: colId,
          sortable: false,
          filter: false,
          resizable: false,
          cellRenderer: cellRenderer,
          cellStyle: cellStyle,

          autoSizeStrategy: {
            type: "fitCellContents",
            colIds: [colId], // Specify the colId of the first column
          },
        };
      } else if (index === 1) {
        // Styling for the spacer column (column 1)
        headerName = ""; // Empty header for the spacer column

        cellStyle = {
          ...cellStyle,
          textAlign: "center",
          width: "5px",
          // backgroundColor: "red", // Adjust the width as needed
        };
      } else {
        // Styling for the remaining columns
        cellStyle = {
          ...cellStyle,
          textAlign: "right",
          // backgroundColor: "blue",
        };
      }

      if (index > 1) {
        const dateParts = column.split(" - ");
        const dateString = dateParts.at(-1) || "1970-01-01"; // Fallback to a default date if undefined
        const date = new Date(dateString);

        switch (gridDateSort) {
          case DateSort.ANNUAL: {
            headerName = date.getFullYear().toString();
            break;
          }
          case DateSort.MONTHLY: {
            headerName = date.toLocaleString("default", {
              month: "short",
              year: "2-digit",
            });
            break;
          }
          case DateSort.QUARTERLY: {
            const quarter = Math.floor((date.getMonth() + 3) / 3);
            headerName = `Q${quarter} '${date.getFullYear().toString().slice(-2)}`;
            break;
          }
          // No default
        }

        cellRenderer = (params: any) => {
          const value = params.value;
          const isFirstOrLastRow =
            params.node.rowIndex === 0 ||
            params.node.rowIndex === tableData.length - 1;
          const isSpacerRow =
            tableData[params.node.rowIndex][filteredColumns[0]] === "";
          const isPercentColumn = index >= percentToggleStartIndex; // Check if the column is a percent toggle column based on its index
          const isPercentSpacerColumn =
            column === "spacer" && showPercentOfTotal; // Check if it's the percent spacer column

          return formatCell(
            value,
            isFirstOrLastRow,
            isSpacerRow,
            isPercentSpacerColumn,
            isPercentColumn,
          );
        };
      }

      if (column === "spacer" && showPercentOfTotal) {
        console.log("Percent toggle spacer column detected");
        // Styling for the percent spacer column
        headerName = ""; // Empty header for the percent spacer column
        cellStyle = {
          ...cellStyle,
          textAlign: "center",
        };
        return {
          headerName: headerName,
          field: column,
          colId: colId,
          suppressSizeToFit: true, // Disable auto-sizing for this column
          sortable: false,
          filter: false,
          resizable: false,
          cellRenderer: cellRenderer,
          cellStyle: cellStyle,
          width: 1, // Set the width directly in the column definition
        };
      }

      return {
        headerName: headerName,
        field: column,
        colId: colId,
        suppressSizeToFit: suppressSizeToFit,
        sortable: false,
        filter: false,
        resizable: false,
        cellRenderer: cellRenderer,
        cellStyle: cellStyle,
        flex: index > 1 ? 1 : undefined,
        width: index > 1 ? undefined : cellStyle.width,
      };
    });
  }, [filteredColumns, gridDateSort, tableData, showPercentOfTotal]);

  const defaultColDef = useMemo(
    () => ({
      sortable: false,
    }),
    [],
  );

  const autoSizeColumn = (api: GridApi, colId: string) => {
    api.autoSizeColumns([colId]);
  };

  const getRowHeight = () => {
    return Number.parseInt(rowHeight, 10);
  };

  const [gridApi, setGridApi] = useState<GridApi | any>();

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
    autoSizeColumn(params.api, "colID=1");
    setSpacerColumnWidth(params.api); // Set the width of the spacer column
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption("headerHeight", Number.parseInt(headerHeight, 10));
    }
  }, [gridApi, headerHeight]);

  useEffect(() => {
    const handleResize = () => {
      if (gridApi) {
        gridApi.sizeColumnsToFit();
        autoSizeColumn(gridApi, "colID=1");
        setSpacerColumnWidth(gridApi); // Set the width of the spacer column
      }
    };

    // Apply left alignment only to the first column
    if (gridApi) {
      const firstColumn = gridApi.getColumnDef("colID=1");
      if (firstColumn) {
        firstColumn.cellStyle = { ...firstColumn.cellStyle, textAlign: "left" };
        gridApi.refreshCells({ columns: ["colID=1"], force: true });
      }
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [gridApi]); //

  const setSpacerColumnWidth = (api: GridApi) => {
    const spacerColumn = api.getColumnDef("spacer");
    if (spacerColumn) {
      spacerColumn.width = 5; // Set the desired width for the spacer column
      const columnDefs = api.getColumnDefs();
      if (columnDefs) {
        api.setColumnDefs(columnDefs); // Update the column definitions
      }
    }
  };

  return (
    <div
      ref={gridWrapperRef}
      className="grid-wrapper"
      data-x={x}
      data-y={y}
      style={{
        position: "absolute",
        display: "inline-block",
        transform: `translate(${x}px, ${y}px)`,
        width,
        height,
      }}
      onClick={() => onClick(index)}
    >
      {active && (
        <div
          className="dateOptionsWrapper gap-1"
          style={{
            position: "absolute",
            top: "-50px",
            width: "100%",
            display: "flex", // Use flexbox
            flexDirection: "row", // Align items in a row

            alignItems: "center", // Align items vertically in the center
          }}
        >
          <GridRenderDateOptions
            years={table.tableYears}
            filter={table.filter}
            updateFilter={updateFilter}
            gridDateSort={gridDateSort}
            setGridDateSort={setGridDateSort}
          />
          <Button
            renderIcon={Percentage}
            kind="secondary"
            size="sm"
            hasIconOnly
            onClick={togglePercentOfTotal}
          ></Button>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "auto",
          overflow: "none",
          padding: "10px",
          ...(active
            ? {
                outline: "auto",
                outlineColor: "#0D99FF",
              }
            : {}),
        }}
      >
        {active && (
          <div
            className="absolute z-10"
            style={{
              top: "-2px",
              left: "-2px",
              right: "-2px",
              bottom: "-2px",
            }}
          >
            <Icons.Square
              className="absolute"
              style={{ top: "-2px", left: "-2px" }}
            />
            <Icons.Square
              className="absolute"
              style={{ bottom: "-2px", left: "-2px" }}
            />
            <Icons.Square
              className="absolute"
              style={{ top: "-2px", right: "-2px" }}
            />
            <Icons.Square
              className="absolute"
              style={{ bottom: "-2px", right: "-2px" }}
            />
          </div>
        )}
        <div className="grid-parent-div" id={`grid-${index}`}>
          <div
            style={{
              width: "100%",
              padding: "3px",
              backgroundColor: headerBackgroundColor,
              color: headerTextColor,
              fontFamily: headerFontFamily,
              fontSize: headerFontSize,
              fontStyle: headerTextStyle === "italic" ? "italic" : "normal",
              fontWeight: headerTextStyle === "bold" ? "bold" : "normal",
              textAlign: headerTextAlignment as TextAlign,
              zIndex: 10,
            }}
          >
            {tableHeader}
          </div>
          <style></style>
          <div
            className="ag-theme-balham"
            style={
              {
                // Global grid styles
                "--ag-font-size": globalFontSize,
                "--ag-font-family": globalFontFamily,
                "--ag-background-color": gridGlobalBackgroundColor,
                "--ag-foreground-color": dataColor,
                "--ag-borders": globalBorder,
                "--ag-border-color": globalBorderColor,

                "--ag-odd-row-background-color": oddRowBackgroundColor,

                "--ag-header-column-separator-display":
                  headerColumnSeparatorDisplay,
                "--ag-header-height": headerHeight,

                // Row styles
                "--ag-row-hover-color": rowHoverColor,
                "--ag-row-border-style": cellFormatBorder,

                "--ag-row-border-color": cellFormatBorderColor,
                "--ag-row-height": rowHeight,

                // Cell styles
                "--ag-column-hover-color": columnHoverColor,
                "--ag-cell-horizontal-border": cellHorizontalBorder,

                // Range selection styles
                "--ag-range-selection-border-color": rangeSelectionBorderColor,
                "--ag-range-selection-border-style": rangeSelectionBorderStyle,
                "--ag-range-selection-background-color":
                  rangeSelectionBackgroundColor,

                // Other styles
                "--ag-borders-secondary": bordersSecondary,
                "--ag-secondary-border-color": secondaryBorderColor,
                "--ag-grid-size": gridSize,
                fontFamily: fontFamily,

                // Bottom row style
                ".ag-row.ag-row-last": {
                  backgroundColor: totalRowColor,
                },
              } as React.CSSProperties
            }
          >
            <AgGridReact
              onGridReady={onGridReady}
              rowData={tableData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              domLayout="autoHeight"
              getRowHeight={getRowHeight}
              containerStyle={{
                width: "100%",
                pointerEvents: "none",
                overflow: "hidden",
              }}
              getRowStyle={(params): RowStyle => {
                const isSubtotalRow =
                  tableData[params.node.rowIndex as any]?.[
                    filteredColumns[0]
                  ] ===
                  "Top " + table.filter.rowCount + " " + qualifierLabel + "s";

                if (isSubtotalRow) {
                  return {
                    fontWeight: subTotalRowTextStyle?.includes("bold")
                      ? "bold"
                      : "normal",
                    fontStyle: subTotalRowTextStyle?.includes("italic")
                      ? "italic"
                      : "normal",
                    textDecoration: subTotalRowTextDecoration?.includes(
                      "underline",
                    )
                      ? "underline"
                      : "none",
                    textAlign: subTotalRowTextAlignment,
                    border: subTotalRowBorder,
                    borderTop: subTotalRowBorderTop,
                    borderBottom: subTotalRowBorderBottom,
                    borderColor: subTotalRowBorderColor,
                    backgroundColor: subTotalRowBackgroundColor,
                    color: subTotalRowColor,
                  };
                }

                if (params.node.rowIndex === 0) {
                  return {
                    borderTop: "1px solid black",
                  };
                }

                if (params.node.rowIndex === tableData.length - 1) {
                  return {
                    fontWeight: totalRowTextStyle?.includes("bold")
                      ? "bold"
                      : "normal",
                    fontStyle: totalRowTextStyle?.includes("italic")
                      ? "italic"
                      : "normal",
                    textDecoration: totalRowTextDecoration?.includes(
                      "underline",
                    )
                      ? "underline"
                      : "none",
                    textAlign: totalRowTextAlignment,
                    borderTop: "1px solid black",
                    backgroundColor: totalRowBackgroundColor,
                    color: totalRowColor,
                    fontFamily: totalRowFontFamily,
                    fontSize: totalRowFontSize,
                  };
                }

                return {
                  fontWeight: "italic",
                  borderTop: "none",
                };
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { type ParsedAnalysisData } from "../../../../../core/model";
