import { FC } from "react";

export const Text: FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00156 14.9349V1.60156M1.60156 5.3349V1.60156H14.4016V5.3349M3.7349 14.4016H11.7349"
      stroke="#F2F2F2"
      strokeWidth="2"
    />
  </svg>
);
