import React, { useState, useEffect } from "react";
import { Deal } from "../../../../../core/model/api";
// eslint-disable-next-line import/no-internal-modules
import { ArrowRight } from "@carbon/react/icons";
import { ProgressBar, Tag, ClickableTile } from "@carbon/react";

type DealListItemProps = {
  deal: Deal;
};

export function DealListItem({ deal }: DealListItemProps) {
  const [helperText, setHelperText] = useState(
    "Performing data pattern recognition...",
  );

  useEffect(() => {
    const helperTextOptions = [
      "Performing data pattern recognition...",
      "Standardizing unstructured data...",
      "Applying field mapping engine...",
    ];

    const intervalId = setInterval(() => {
      setHelperText((currentText) => {
        const currentIndex = helperTextOptions.indexOf(currentText);
        const nextIndex = (currentIndex + 1) % helperTextOptions.length;
        return helperTextOptions[nextIndex];
      });
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <ClickableTile
      disabled={!deal.vdrReady}
      className="bg-white"
      id={`deal-${deal.dealId}`}
      href={`/deals/${deal.dealTitle.replaceAll(" ", ".").toLocaleLowerCase()}--${deal.dealId}`}
      style={{
        border: "1px solid #c6c6c6",

        transition: deal.vdrReady
          ? "border-color 0.3s ease, background-color 0.3s ease, fill 0.3s ease"
          : "none",

        background: deal.vdrReady
          ? "rgb(255, 255, 255)"
          : "rgb(38, 38, 38, 0.05)",
      }}
      onMouseEnter={(e) => {
        if (deal.vdrReady) {
          e.currentTarget.style.border = "1px solid #0000ff";
          e.currentTarget.style.backgroundColor = "#FFFFFF";
          e.currentTarget.querySelector("svg")?.style.fill ?? "#0000ff";
        }
      }}
      onMouseLeave={(e) => {
        if (deal.vdrReady) {
          e.currentTarget.style.border = "1px solid #c6c6c6";
          e.currentTarget.style.backgroundColor = "#FFFFFF";
          e.currentTarget.querySelector("svg")?.style.fill ?? "#4589ff";
        }
      }}
      renderIcon={ArrowRight}
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          <Title>{deal.dealTitle}</Title>
          <br />
          <Tag
            className="mt-2 mb-7 w-1/4"
            type="blue"
            style={{ opacity: deal.vdrReady ? 1 : 0.4 }}
          >
            {"Active"}
          </Tag>
          <hr />
          <div className="mt-2 w-full" style={{ display: "table" }}>
            <div style={{ display: "table-row" }}>
              <span className="table-cell text-xs text-left pr-2">Target:</span>
              <span className="table-cell text-xs font-semibold text-right">
                RJR Nabisco
              </span>
            </div>
            <div style={{ display: "table-row" }}>
              <span className="table-cell text-xs text-left pr-2">
                Sell-side advisor:
              </span>
              <span className="table-cell text-xs font-semibold text-right">
                Perella Weinberg
              </span>
            </div>
            <div className="table-row">
              <span className="table-cell text-xs text-left pr-2">
                Next bid date:
              </span>
              <span className="table-cell text-xs font-semibold text-right">
                December 31, 2024
              </span>
            </div>
          </div>
          {!deal.vdrReady && (
            <div className="z-0">
              <ProgressBar
                label={"Data room ingestion in progress"}
                className="py-10"
                helperText={helperText}
              />
            </div>
          )}
        </div>
        <div className="flex justify-between items-center">
          <LastUpdated>Last Updated: July 20, 2023</LastUpdated>
          <ArrowRight />
        </div>
      </div>
    </ClickableTile>
  );
}

const Title = ({ children }: { children: string }) => (
  <span className="text-md font-semibold">{children}</span>
);

const LastUpdated = ({ children }: { children: string }) => (
  <span className="text-xs font-normal">{children}</span>
);
