import { FunctionComponent, PropsWithChildren } from "react";

import { FileText } from "./FileText";
import { Arrow } from "./Arrow";
import { Analytics } from "./Analytics";
import { ChevronDown } from "./ChevronDown";
import { ChevronDownSmall } from "./ChevronDownSmall";
import { Close } from "./Close";
import { Fire } from "./Fire";
import { Folder } from "./Folder";
import { Plus } from "./Plus";
import { Search } from "./Search";
import { Settings } from "./SettingsIcon";
import { Sort } from "./Sort";
import { Spinner } from "./Spinner";
import { Tick } from "./Tick";
import { Download } from "./Download";
import { Repeat } from "./Repeat";
import { Union } from "./Union";
import { AppleIcon } from "./Apple";
import { GoogleIcon } from "./Google";
import { FacebookIcon } from "./Facebook";
import { StatusError } from "./StatusError";
import { StatusSuccess } from "./StatusSuccess";
import { GridLayout } from "./GridLayout";
import { FileIcon } from "./File";
import { Square } from "./Square";
import { Text } from "./Text";
import { BoldText } from "./BoldText";
import { ItalicText } from "./ItalicText";
import { Calendar } from "./Calendar";

type IconsComponent = FunctionComponent<PropsWithChildren> & {
  FileText: typeof FileText;
  Arrow: typeof Arrow;
  Analytics: typeof Analytics;
  ChevronDown: typeof ChevronDown;
  ChevronDownSmall: typeof ChevronDownSmall;
  Close: typeof Close;
  Fire: typeof Fire;
  Folder: typeof Folder;
  Plus: typeof Plus;
  Search: typeof Search;
  Settings: typeof Settings;
  Sort: typeof Sort;
  Spinner: typeof Spinner;
  Tick: typeof Tick;
  Download: typeof Download;
  Repeat: typeof Repeat;
  Union: typeof Union;
  AppleIcon: typeof AppleIcon;
  GoogleIcon: typeof GoogleIcon;
  FacebookIcon: typeof FacebookIcon;
  StatusError: typeof StatusError;
  StatusSuccess: typeof StatusSuccess;
  GridLayout: typeof GridLayout;
  FileIcon: typeof FileIcon;
  Square: typeof Square;
  Text: typeof Text;
  BoldText: typeof BoldText;
  ItalicText: typeof ItalicText;
  Calendar: typeof Calendar;
};

export const Icons: IconsComponent = ({ children }): JSX.Element => {
  return <span>{children}</span>;
};

Icons.FileText = FileText;
Icons.Arrow = Arrow;
Icons.Analytics = Analytics;
Icons.ChevronDown = ChevronDown;
Icons.ChevronDownSmall = ChevronDownSmall;
Icons.Close = Close;
Icons.Fire = Fire;
Icons.Folder = Folder;
Icons.Plus = Plus;
Icons.Search = Search;
Icons.Settings = Settings;
Icons.Sort = Sort;
Icons.Spinner = Spinner;
Icons.Tick = Tick;
Icons.Download = Download;
Icons.Repeat = Repeat;
Icons.Union = Union;
Icons.AppleIcon = AppleIcon;
Icons.GoogleIcon = GoogleIcon;
Icons.FacebookIcon = FacebookIcon;
Icons.StatusError = StatusError;
Icons.StatusSuccess = StatusSuccess;
Icons.GridLayout = GridLayout;
Icons.FileIcon = FileIcon;
Icons.Square = Square;
Icons.Text = Text;
Icons.BoldText = BoldText;
Icons.ItalicText = ItalicText;
Icons.Calendar = Calendar;
