import { Entity, createSchema } from "electrodb";

const AnalysisSchema = createSchema({
  model: {
    entity: "analysis",
    service: "transacted-platform",
    version: "1",
  },
  attributes: {
    category: {
      type: "string",
      required: true,
    },
    subCategory: {
      type: "string",
      required: true,
    },
    qualifier: {
      type: "string",
      required: true,
    },
    code: {
      type: "string",
    },
  },
  indexes: {
    primary: {
      pk: {
        field: "pk",
        composite: ["category", "subCategory", "qualifier"],
      },
    },
  },
});

export const Analysis = new Entity(AnalysisSchema);
export type AnalysisSchemaType = typeof AnalysisSchema;
