export enum DropdownType {
  FONTS = "FONTS",
  FONT_SIZES = "FONT_SIZES",
  COLORS = "COLORS",
}

export type StyleProperty = Pick<React.CSSProperties, StylePropertyName>;

export type StylePropertyName =
  | "fontFamily"
  | "fontSize"
  | "backgroundColor"
  | "fontWeight"
  | "fontStyle";
