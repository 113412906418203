import { FC } from "react";

export const AppleIcon: FC = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1527 8.05245C15.8494 8.05245 14.7979 8.84101 14.1189 8.84101C13.407 8.84101 12.4542 8.09626 11.3261 8.09626C9.17948 8.09626 7 9.88146 7 13.2219C7 15.3137 7.81046 17.5151 8.80711 18.9499C9.65043 20.1436 10.4061 21.1293 11.4794 21.1293C12.5418 21.1293 13.0127 20.4284 14.338 20.4284C15.6741 20.4284 15.9808 21.1074 17.1527 21.1074C18.3245 21.1074 19.1021 20.0451 19.8359 18.9937C20.6464 17.778 20.9969 16.5951 21.0078 16.5404C20.9421 16.5185 18.7079 15.6094 18.7079 13.0466C18.7079 10.8343 20.4602 9.84861 20.5588 9.77194C19.4088 8.09626 17.6455 8.05245 17.1527 8.05245ZM16.5503 6.63962C17.076 5.99344 17.4484 5.11727 17.4484 4.23014C17.4484 4.10967 17.4374 3.98919 17.4155 3.89062C16.5612 3.92348 15.5098 4.46014 14.8856 5.19393C14.4037 5.75249 13.9437 6.63962 13.9437 7.52675C13.9437 7.65817 13.9656 7.80055 13.9765 7.84436C14.0313 7.85531 14.1189 7.86626 14.2065 7.86626C14.9841 7.86626 15.9698 7.34056 16.5503 6.63962Z"
      fill="black"
    />
  </svg>
);
