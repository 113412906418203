import { useState } from "react";
// eslint-disable-next-line import/no-internal-modules
import { SettingsAdjust, ArrowRight } from "@carbon/react/icons";
import { DateSort } from "./types";

import {
  Popover,
  PopoverContent,
  PopoverAlignment,
  NumberInput,
  SelectItem,
  Layer,
  Select,
  Button,
} from "@carbon/react";
import { Filter } from "../../../../../core/model/api/activeExport";

function getMonthFromString(month: string) {
  return new Date(Date.parse(month + " 1, 2024")).getMonth() + 1;
}

function monthToQuarter(month: number) {
  return `Q${Math.ceil(month / 3)}`;
}

function quarterToMonth(quarter: string) {
  return (Number(quarter.replace("Q", "")) - 1) * 3;
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const quarters = ["Q1", "Q2", "Q3", "Q4"];

export const GridRenderDateOptions = ({
  gridDateSort,
  setGridDateSort,
  filter,
  updateFilter,
  years,
}: {
  gridDateSort: DateSort;
  years: number[];
  setGridDateSort: (value: DateSort) => void;
  filter: Filter;
  updateFilter: (value: Filter) => void;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="popover-tabtip-story z-1" style={{ display: "flex" }}>
      <Popover
        align={"left-top"}
        open={open}
        onKeyDown={(evt: React.KeyboardEvent<HTMLElement>) => {
          if ((evt as any).key === "Escape") {
            setOpen(false);
          }
        }}
        isTabTip
        onRequestClose={() => setOpen(false)}
      >
        <Button
          aria-label="SettingsAdjust"
          type="button"
          hasIconOnly
          size="sm"
          kind="secondary"
          aria-expanded={open}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <SettingsAdjust />
        </Button>
        <PopoverContent className="p-3">
          <div style={{ marginBottom: "20px" }}>
            <Layer>
              <NumberInput
                id="table-displayed-rows"
                min={1}
                max={100}
                step={1}
                value={filter.rowCount}
                onChange={(_, state) => {
                  updateFilter({
                    ...filter,
                    rowCount: Number(state.value),
                  });
                }}
                size="sm"
                allowEmpty={true}
                label="Table Rows Displayed"
                helperText="Enter the number of rows to display in the table."
                invalidText="Please enter a valid number between 1 and 100."
              />
            </Layer>
          </div>
          <hr />
          <div style={{ marginBottom: "20px" }}>
            <Layer>
              <Select
                id="setGridDateSort"
                value={gridDateSort}
                onChange={(e) => {
                  const newDateSort = e.target.value as DateSort;
                  setGridDateSort(newDateSort);
                }}
                size="sm"
                defaultValue={DateSort.ANNUAL}
              >
                <SelectItem value={DateSort.ANNUAL} text="Annual" />
                <SelectItem value={DateSort.MONTHLY} text="Monthly" />
                <SelectItem value={DateSort.QUARTERLY} text="Quarterly" />
              </Select>
            </Layer>
          </div>

          <div className="flex flex-row justify-between items-center">
            <Layer>
              <Select
                id="setGridStartYear"
                labelText="Start"
                helperText="First year"
                size="sm"
                value={filter.start.getFullYear()}
                onChange={(event) => {
                  const newStart = filter.start;
                  newStart.setFullYear(Number(event.target.value));

                  const newFilter: Filter = {
                    ...filter,
                    start: newStart,
                  };
                  updateFilter(newFilter);
                }}
              >
                {years.map((year, cid) => (
                  <SelectItem
                    key={`option-${cid}`}
                    value={year}
                    text={year.toString()}
                  />
                ))}
              </Select>
            </Layer>
            <ArrowRight />
            <Layer>
              <Select
                id="setGridEndYear"
                labelText="End"
                helperText="Last year"
                size="sm"
                value={filter.end.getFullYear()}
                onChange={(event) => {
                  const newEnd = filter.end;
                  newEnd.setFullYear(Number(event.target.value));

                  const newFilter: Filter = {
                    ...filter,
                    end: newEnd,
                  };
                  updateFilter(newFilter);
                }}
              >
                {years.map((year, cid) => (
                  <SelectItem
                    key={`option-${cid}`}
                    value={year}
                    text={year.toString()}
                  />
                ))}
              </Select>
            </Layer>
          </div>
          {gridDateSort === DateSort.QUARTERLY && (
            <div className="flex flex-row justify-between">
              <Layer>
                <Select
                  id="setGridStartQuarter"
                  labelText="Start"
                  helperText="First quarter"
                  size="sm"
                  value={filter.start.getMonth()}
                  onChange={(event) => {
                    const newStart = filter.start;
                    newStart.setMonth(quarterToMonth(event.target.value));

                    const newFilter: Filter = {
                      ...filter,
                      start: newStart,
                    };
                    updateFilter(newFilter);
                  }}
                >
                  {quarters.map((quarter, cid) => (
                    <SelectItem
                      key={`option-${cid}`}
                      value={quarter}
                      text={quarter}
                    />
                  ))}
                </Select>
              </Layer>
              <Layer>
                <Select
                  id="setGridEndQuarter"
                  labelText="End"
                  helperText="Last quarter"
                  size="sm"
                  value={monthToQuarter(filter.end.getMonth())}
                  onChange={(event) => {
                    const newEnd = filter.end;
                    newEnd.setMonth(quarterToMonth(event.target.value));

                    const newFilter: Filter = {
                      ...filter,
                      end: newEnd,
                    };
                    updateFilter(newFilter);
                  }}
                >
                  {quarters.map((quarter, cid) => (
                    <SelectItem
                      key={`option-${cid}`}
                      value={quarter}
                      text={quarter}
                    />
                  ))}
                </Select>
              </Layer>
            </div>
          )}
          {gridDateSort === DateSort.MONTHLY && (
            <div className="flex flex-row justify-between">
              <Layer>
                <Select
                  id="setGridStartMonth"
                  labelText="Start"
                  helperText="First month"
                  size="sm"
                  value={filter.start.getMonth()}
                  onChange={(event) => {
                    const newStart = filter.start;
                    newStart.setMonth(getMonthFromString(event.target.value));

                    const newFilter: Filter = {
                      ...filter,
                      start: newStart,
                    };
                    updateFilter(newFilter);
                  }}
                >
                  {months.map((month, cid) => (
                    <SelectItem
                      key={`option-${cid}`}
                      value={month}
                      text={month}
                    />
                  ))}
                </Select>
              </Layer>
              <Layer>
                <Select
                  id="setGridEndMonth"
                  labelText="End"
                  helperText="Last month"
                  size="sm"
                  value={filter.end.getMonth()}
                  onChange={(event) => {
                    const newEnd = filter.end;
                    newEnd.setMonth(getMonthFromString(event.target.value));

                    const newFilter: Filter = {
                      ...filter,
                      end: newEnd,
                    };
                    updateFilter(newFilter);
                  }}
                >
                  {months.map((month, cid) => (
                    <SelectItem
                      key={`option-${cid}`}
                      value={month}
                      text={month}
                    />
                  ))}
                </Select>
              </Layer>
            </div>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
};

export const ChartRenderDateOptions = ({
  chartDateSort,
  setChartDateSort,
  filter,
  updateFilter,
  years,
}: {
  chartDateSort: DateSort;
  setChartDateSort: (value: DateSort) => void;
  filter: Filter;
  updateFilter: (value: Filter) => void;
  years: number[];
}) => {
  const [open, setOpen] = useState(false);
  const align: PopoverAlignment = "bottom-left";

  return (
    <div style={{ display: "flex" }}>
      <Popover
        align={align}
        open={open}
        onKeyDown={(evt: React.KeyboardEvent<HTMLElement>) => {
          if ((evt as any).key === "Escape") {
            setOpen(false);
          }
        }}
        isTabTip
        onRequestClose={() => setOpen(false)}
      >
        <Button
          aria-label="SettingsAdjust"
          type="button"
          hasIconOnly
          size="sm"
          kind="secondary"
          aria-expanded={open}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <SettingsAdjust />
        </Button>
        <PopoverContent className="p-3 z-1">
          <Layer>
            <Select
              id="setChartDateSort"
              value={chartDateSort}
              onChange={(e) => {
                const newDateSort = e.target.value as DateSort;
                setChartDateSort(newDateSort);
              }}
              size="sm"
              defaultValue={DateSort.ANNUAL}
            >
              <SelectItem value={DateSort.ANNUAL} text="Annual" />
              <SelectItem value={DateSort.MONTHLY} text="Monthly" />
              <SelectItem value={DateSort.QUARTERLY} text="Quarterly" />
            </Select>
          </Layer>

          <div className="flex flex-row justify-between items-center mt-6">
            <Layer>
              <Select
                id="setChartStartYear"
                labelText="Start"
                helperText="First year"
                size="sm"
                value={filter.start.getFullYear()}
                onChange={(event) => {
                  const newStart = filter.start;
                  newStart.setFullYear(Number(event.target.value));

                  const newFilter: Filter = {
                    ...filter,
                    start: newStart,
                  };
                  updateFilter(newFilter);
                }}
              >
                {years.map((year, cid) => (
                  <SelectItem
                    key={`option-${cid}`}
                    value={year}
                    text={year.toString()}
                  />
                ))}
              </Select>
            </Layer>
            <div className="w-10 flex items-center justify-center">
              <ArrowRight />
            </div>
            <Layer>
              <Select
                id="setChartEndYear"
                labelText="End"
                helperText="Last year"
                size="sm"
                value={filter.end.getFullYear()}
                onChange={(event) => {
                  const newEnd = filter.end;
                  newEnd.setFullYear(Number(event.target.value));

                  const newFilter: Filter = {
                    ...filter,
                    end: newEnd,
                  };
                  updateFilter(newFilter);
                }}
              >
                {years.map((year, cid) => (
                  <SelectItem
                    key={`option-${cid}`}
                    value={year}
                    text={year.toString()}
                  />
                ))}
              </Select>
            </Layer>
          </div>

          {chartDateSort === DateSort.MONTHLY && (
            <div className="flex flex-row justify-between">
              <Layer>
                <Select
                  id="setChartStartMonth"
                  labelText="Start"
                  helperText="First month"
                  size="sm"
                  value={filter.start.getMonth()}
                  onChange={(event) => {
                    const newStart = filter.start;
                    newStart.setMonth(getMonthFromString(event.target.value));

                    const newFilter: Filter = {
                      ...filter,
                      start: newStart,
                    };
                    updateFilter(newFilter);
                  }}
                >
                  {months.map((month, cid) => (
                    <SelectItem
                      key={`option-${cid}`}
                      value={month}
                      text={month}
                    />
                  ))}
                </Select>
              </Layer>
              <Layer>
                <Select
                  id="setChartEndMonth"
                  labelText="End"
                  helperText="Last month"
                  size="sm"
                  value={filter.end.getMonth()}
                  onChange={(event) => {
                    const newEnd = filter.end;
                    newEnd.setMonth(getMonthFromString(event.target.value));

                    const newFilter: Filter = {
                      ...filter,
                      end: newEnd,
                    };
                    updateFilter(newFilter);
                  }}
                >
                  {months.map((month, cid) => (
                    <SelectItem
                      key={`option-${cid}`}
                      value={month}
                      text={month}
                    />
                  ))}
                </Select>
              </Layer>
            </div>
          )}

          {chartDateSort === DateSort.QUARTERLY && (
            <div className="flex flex-row justify-between">
              <Layer>
                <Select
                  id="setChartStartQuarter"
                  labelText="Start"
                  helperText="First quarter"
                  size="sm"
                  value={monthToQuarter(filter.start.getMonth())}
                  onChange={(event) => {
                    const newStart = filter.start;
                    newStart.setMonth(quarterToMonth(event.target.value));

                    const newFilter: Filter = {
                      ...filter,
                      start: newStart,
                    };
                    updateFilter(newFilter);
                  }}
                >
                  {quarters.map((quarter, cid) => (
                    <SelectItem
                      key={`option-${cid}`}
                      value={quarter}
                      text={quarter}
                    />
                  ))}
                </Select>
              </Layer>
              <Layer>
                <Select
                  id="setChartEndQuarter"
                  labelText="End"
                  helperText="Last quarter"
                  size="sm"
                  value={monthToQuarter(filter.end.getMonth())}
                  onChange={(event) => {
                    const newEnd = filter.end;
                    newEnd.setMonth(quarterToMonth(event.target.value));

                    const newFilter: Filter = {
                      ...filter,
                      end: newEnd,
                    };
                    updateFilter(newFilter);
                  }}
                >
                  {quarters.map((quarter, cid) => (
                    <SelectItem
                      key={`option-${cid}`}
                      value={quarter}
                      text={quarter}
                    />
                  ))}
                </Select>
              </Layer>
            </div>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
};
