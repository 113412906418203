"use client";
import { useEffect, useState } from "react";
import { PageLayout, ScrollWrapper } from "@/components";
import React from "react";
import { Chart, TemplateTable } from "./components";
import { EditableTableData } from "./types";
import {
  GetSlideMastersOutput,
  SlideMaster,
} from "../../../../../core/model/api";

function selectedSlideMasterToStyle(
  selectedSlideMaster: SlideMaster | undefined,
  row: "header" | "data" | "subtotal" | "total",
) {
  if (!selectedSlideMaster) {
    return {};
  }

  switch (row) {
    case "header": {
      return selectedSlideMaster.headerStyle;
    }
    case "data": {
      return selectedSlideMaster.dataStyle;
    }
    case "subtotal": {
      return selectedSlideMaster.subTotalStyle;
    }
    case "total": {
      return selectedSlideMaster.totalStyle;
    }
    default: {
      return {};
    }
  }
}

export function TemplateBuilder({
  slideMasters,
}: {
  slideMasters: GetSlideMastersOutput;
}) {
  const [editMade, setEditMade] = useState<boolean>(false);
  const [selectedSlideMaster, setSelectedSlideMaster] = useState<
    number | undefined
  >();
  const [editableTableData, setEditableTableData] = useState<EditableTableData>(
    {
      rows: [
        {
          rowData: ["", "Header", "Header"],
          rowStyling: {
            ...selectedSlideMasterToStyle(
              slideMasters[selectedSlideMaster ?? 0],
              "header",
            ),
          },
        },
        {
          rowData: ["Data", "$1,684", "$1,684"],
          rowStyling: {
            ...selectedSlideMasterToStyle(
              slideMasters[selectedSlideMaster ?? 0],
              "data",
            ),
          },
        },
        {
          rowData: ["Subtotal", "$1,684", "$1,684"],
          rowStyling: {
            ...selectedSlideMasterToStyle(
              slideMasters[selectedSlideMaster ?? 0],
              "subtotal",
            ),
          },
        },
        {
          rowData: ["Sum", "$1,684", "$1,684"],
          rowStyling: {
            ...selectedSlideMasterToStyle(
              slideMasters[selectedSlideMaster ?? 0],
              "total",
            ),
          },
        },
      ],
      tableStyling: {},
    },
  );

  const handleEdit = () => {
    setEditMade((prev) => (prev === false ? true : prev));
  };

  useEffect(() => {
    if (slideMasters.length > 0) {
      setSelectedSlideMaster(0);
    }
  }, [slideMasters]);

  useEffect(() => {
    setEditableTableData({
      rows: [
        {
          rowData: ["", "Header", "Header"],
          rowStyling: {
            ...selectedSlideMasterToStyle(
              slideMasters[selectedSlideMaster ?? 0],
              "header",
            ),
          },
        },
        {
          rowData: ["Data", "$1,684", "$1,684"],
          rowStyling: {
            ...selectedSlideMasterToStyle(
              slideMasters[selectedSlideMaster ?? 0],
              "data",
            ),
          },
        },
        {
          rowData: ["Subtotal", "$1,684", "$1,684"],
          rowStyling: {
            ...selectedSlideMasterToStyle(
              slideMasters[selectedSlideMaster ?? 0],
              "subtotal",
            ),
          },
        },
        {
          rowData: ["Sum", "$1,684", "$1,684"],
          rowStyling: {
            ...selectedSlideMasterToStyle(
              slideMasters[selectedSlideMaster ?? 0],
              "total",
            ),
          },
        },
      ],
      tableStyling: {},
    });
  }, [slideMasters, selectedSlideMaster]);

  return (
    <PageLayout
      title="Template Editor"
      actions={
        <>
          <button
            type="button"
            className="flex justify-center min-w-44 py-3 px-8 text-sm font-semibold text-DarkBlue shadow-sm border hover:border-LCDBlue border-DarkBlue"
          >
            New Slide Master
          </button>
          <button
            type="button"
            className="flex justify-center min-w-44 bg-LCDBlue py-3 px-8 text-sm font-semibold text-gray-100 shadow-sm hover:bg-DarkBlue hover:border-DarkBlue border border-LCDBlue disabled:bg-DarkBlue disabled:border-DarkBlue"
            onClick={() => setEditMade(false)}
            disabled={!editMade}
          >
            Save
          </button>
        </>
      }
    >
      <div className="flex h-full gap-3 min-h-0">
        <div className="bg-Grey0 rounded-xl h-full w-full min-h-0 p-6 flex flex-col gap-16">
          <TemplateTable
            data={editableTableData}
            title="Title example"
            selectedSlideMaster={slideMasters[selectedSlideMaster ?? 0]}
            edited={handleEdit}
          />
          <Chart />
        </div>
        <div className="bg-Grey0 rounded-xl max-w-44 w-full overflow-hidden">
          <h3 className="text-DarkBlue text-center font-medium text-xl py-3 px-4">
            Templates
          </h3>
          <ScrollWrapper className="pt-2 pb-14 px-3.5">
            {slideMasters.map((_, index) => {
              return (
                <div
                  key={index}
                  className="w-36 h-28 border border-Grey20 mb-3.5 hover:border-LCDBlue"
                  onClick={() => setSelectedSlideMaster(index)}
                ></div>
              );
            })}
          </ScrollWrapper>
        </div>
      </div>
    </PageLayout>
  );
}
