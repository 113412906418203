import { FC, SVGProps } from "react";

export const Analytics: FC<SVGProps<SVGSVGElement>> = ({
  className,
  ...otherProps
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-Grey40 ${className}`}
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 7C9 6.06812 9 5.60218 9.15224 5.23463C9.35523 4.74458 9.74458 4.35523 10.2346 4.15224C10.6022 4 11.0681 4 12 4C12.9319 4 13.3978 4 13.7654 4.15224C14.2554 4.35523 14.6448 4.74458 14.8478 5.23463C15 5.60218 15 6.06812 15 7V17C15 17.9319 15 18.3978 14.8478 18.7654C14.6448 19.2554 14.2554 19.6448 13.7654 19.8478C13.3978 20 12.9319 20 12 20C11.0681 20 10.6022 20 10.2346 19.8478C9.74458 19.6448 9.35523 19.2554 9.15224 18.7654C9 18.3978 9 17.9319 9 17V7ZM2 12C2 11.0681 2 10.6022 2.15224 10.2346C2.35523 9.74458 2.74458 9.35523 3.23463 9.15224C3.60218 9 4.06812 9 5 9C5.93188 9 6.39782 9 6.76537 9.15224C7.25542 9.35523 7.64477 9.74458 7.84776 10.2346C8 10.6022 8 11.0681 8 12V17C8 17.9319 8 18.3978 7.84776 18.7654C7.64477 19.2554 7.25542 19.6448 6.76537 19.8478C6.39782 20 5.93188 20 5 20C4.06812 20 3.60218 20 3.23463 19.8478C2.74458 19.6448 2.35523 19.2554 2.15224 18.7654C2 18.3978 2 17.9319 2 17V12ZM22 16C22 15.0681 22 14.6022 21.8478 14.2346C21.6448 13.7446 21.2554 13.3552 20.7654 13.1522C20.3978 13 19.9319 13 19 13C18.0681 13 17.6022 13 17.2346 13.1522C16.7446 13.3552 16.3552 13.7446 16.1522 14.2346C16 14.6022 16 15.0681 16 16V17C16 17.9319 16 18.3978 16.1522 18.7654C16.3552 19.2554 16.7446 19.6448 17.2346 19.8478C17.6022 20 18.0681 20 19 20C19.9319 20 20.3978 20 20.7654 19.8478C21.2554 19.6448 21.6448 19.2554 21.8478 18.7654C22 18.3978 22 17.9319 22 17V16Z"
    />
  </svg>
);
