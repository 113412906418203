import React from "react";
import { Tab } from "./types";

interface TapBarProps<T> {
  tabs: Array<Tab<T>>;
  onChange: (props: Tab<T>) => void;
  activeTab: T;
  className?: string;
}

export const TabBar = <T extends string>(props: TapBarProps<T>) => {
  const { tabs, activeTab, onChange, className } = props;
  const activeTabStyle =
    "text-DarkBlue before:content-[''] before:absolute before:w-full before:h before:border before:border-LCDBlue before:bottom-[-1px]";

  return (
    <ul
      className={`text-Grey40 flex border-b border-Grey20 ${className || ""}`}
    >
      {tabs.map((tab) => (
        <li
          className={`relative ${activeTab === tab.id ? activeTabStyle : ""}`}
          key={tab.id}
        >
          <button
            className="pb-4 px-2 cursor-pointer"
            onClick={() => {
              onChange(tab);
            }}
          >
            {tab.name}
          </button>
        </li>
      ))}
    </ul>
  );
};
